import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withLightTheme } from '../../theme'
import { useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'
import ImprintDialog from './ImprintDialog'

const Footer = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [imprintDialogVisible, setImprintDialogVisible] = useState(false)

  return (
    <div className={classes.root}>
      <Link className={classes.link} href={'https://www.sfs.ch/de/AGB'} underline="none" variant="subtitle2" target="_blank">
        {t('gtc_business_customers')}
      </Link>
      <Link className={classes.link} href={'https://www.sfs.ch/de/AGB_Privatkunden'} underline="none" variant="subtitle2" target="_blank">
        {t('gtc_private_customers')}
      </Link>
      <Link className={classes.link} href={'https://www.sfs.ch/de/Datenschutz'} underline="none" variant="subtitle2" target="_blank">
        {t('data_privacy')}
      </Link>
      <Link
        className={classes.link}
        href="#"
        underline="none"
        variant="subtitle2"
        onClick={event => {
          event.preventDefault()
          setImprintDialogVisible(true)
        }}>
        {t('imprint')}
      </Link>
      <ImprintDialog open={imprintDialogVisible} onClose={() => setImprintDialogVisible(false)} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
  },
  link: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(3),
  },
}))

export default withLightTheme(Footer)
