import React from 'react'
import { PageContext } from '../../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Toolbar, Typography, IconButton } from '@material-ui/core'
import ReactSVG from 'react-svg'

import MenuIcon from '@material-ui/icons/Menu'

const styles = theme => ({
  logo: {
    height: 36,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& svg': {
      width: 48,
      height: 36,
    },
  },
})

class CustomToolbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { t, classes, title } = this.props

    return (
      <Toolbar>
        <IconButton onClick={() => this.context.openDrawerMenu()} edge="start" color="inherit">
          <MenuIcon />
        </IconButton>
        <ReactSVG className={classes.logo} src="/assets/logo.svg" />
        <Typography variant="subtitle1" color={'inherit'}>
          {title}
        </Typography>
      </Toolbar>
    )
  }
}

CustomToolbar.contextType = PageContext

CustomToolbar.propTypes = {
  title: PropTypes.string.isRequired,
}

export default withTranslation()(withStyles(styles)(CustomToolbar))
