import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core'
import Searchbox from './Searchbox'

class ListSelectViewDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedValue: props.value,
      errorMessage: null,
      searchTerm: '',
    }
  }

  onClickItem(item) {
    this.setState({ selectedValue: item.value })
  }

  handleSubmit() {
    this.props.onSelect(this.state.selectedValue)
  }

  render() {
    const { t, classes, open, onCancel, onSelectLabel, title, items } = this.props
    const { selectedValue, searchTerm } = this.state

    let filteredItems = items && items.length > 0 ? items.filter(item => !searchTerm || searchTerm.trim() === '' || item.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) : []

    return (
      <div>
        <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={onCancel} fullWidth maxWidth={'xs'} scroll={'paper'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          {items && items.length > 0 && <Searchbox className={classes.searchBox} value={searchTerm} onChange={value => this.setState({ searchTerm: value })} />}
          <DialogContent className={classes.dialogContent}>
            <List className={classes.list}>
              {filteredItems &&
                filteredItems.map((item, index) => {
                  return (
                    <ListItem
                      button
                      className={item && item.value === selectedValue ? [classes.listItem, classes.activeListItem].join(' ') : classes.listItem}
                      key={item.value}
                      value={item.value}
                      onClick={() => this.onClickItem(item)}>
                      {item.title}
                    </ListItem>
                  )
                })}
              {filteredItems && filteredItems.length === 0 && (
                <ListItem className={classes.listItem} key={'emptyListItem'}>
                  {items && items.length > 0 ? t('no_match_found') : t('no_entries_available')}
                </ListItem>
              )}
            </List>
          </DialogContent>
          <DialogActions>
            {onCancel && <Button onClick={onCancel}>{t('cancel')}</Button>}
            <Button onClick={this.handleSubmit.bind(this)} disabled={!this.state.selectedValue} type="submit" color="primary">
              {onSelectLabel || t('select')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ListSelectViewDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  items: PropTypes.array.isRequired,
  value: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectLabel: PropTypes.string,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  dialogPaper: {
    height: '80%',
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.main,
  },
  searchBox: {
    paddingLeft: theme.spacing(2),
  },
})

export default withTranslation()(withStyles(styles)(ListSelectViewDialog))
