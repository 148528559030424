import React, { useContext, useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Chip, Typography, IconButton, Menu, MenuItem, ListItemIcon, Table, TableBody, TableHead, TableRow, TableCell, Paper, AppBar, Toolbar } from '@material-ui/core';
import { PARAM_DEVICE_REPORT_DEFINITION, PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES, PARAM_DEACTIVATE_DISABLED } from '../../m2m-cloud-api/Api/OrgService/Models/Org';
import { PageContext } from '../../Context/PageProvider';
import * as moment from 'moment';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveIcon from '@material-ui/icons/OpenWith';
import ParamsIcons from '@material-ui/icons/Reorder';
import FavIconDefault from '@material-ui/icons/FavoriteBorder';
import FavIconSelected from '@material-ui/icons/Favorite';

function OrgView({ org, onEditClick, onEditParamsClick, onMoveClick, onCopyClick, onDeactivateClick }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const context = useContext(PageContext);

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [users, setUsers] = useState([]);
    const [favourites, setFavourites] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const newUsersToLoad = [];

            const creator = org?.getCreator();
            if (creator?.getType() === 'user') {
                const userLoaded = users.find(user => user.getId() === creator.getId());
                if (!userLoaded) {
                    newUsersToLoad.push(creator.getId());
                }
            }
            const updater = org.getLastUpdater();
            if (updater?.getType() === 'user') {
                const userLoaded = users.find(user => user.getId() === updater.getId());
                if (!userLoaded && newUsersToLoad.indexOf(updater.getId()) === -1) {
                    newUsersToLoad.push(updater.getId());
                }
            }
            if (newUsersToLoad.length > 0) {
                const loadedUsers = await context.userService.getPublicUsersWithIds(newUsersToLoad, false)
                const newUsers = [...users, ...loadedUsers];
                setUsers(newUsers);
            }

            const userFavourites = (context.userService.getActiveUser().getParam(PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES)?.split(',') || []).filter(orgId => orgId && orgId.trim() !== '');
            setFavourites(userFavourites);
        }
        loadData()
    }, [org]);

    const fetchUserMeta = updateMeta => {
        if (!!users.length) {
            const user = updateMeta.getType() === 'user' && users.find(user => user.getId() === updateMeta.getId());
            return user ? user.getNickname() : updateMeta.getType() + ' / ' + updateMeta.getId();
        }
    };

    const onFavorite = async () => {
        try {
            const user = context.userService.getActiveUser();
            let userFavourites = user.getParam(PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES)?.split(',') || [];
            if (userFavourites.includes(org.getId())) {
                userFavourites = userFavourites.filter(orgId => orgId !== org.getId());
            } else {
                userFavourites.push(org.getId());
            }
            await context.userService.putParam(user.getUserId(), PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES, userFavourites.length > 0 ? userFavourites.join(',') : null, true);
            setFavourites(userFavourites);
        } catch (error) {
            console.error('api error', error);
        }
    };

    const created = org?.getCreator();
    const updated = org?.getLastUpdater();
    const params = org?.getParams();
    const tags = org?.getTags();
    const visibilities = org?.getVis();

    const isFavouriteEnabled = tags?.includes(PARAM_DEVICE_REPORT_DEFINITION);
    const isFavourited = favourites.includes(org.getId());
    const isDeactivateDisabled = tags?.includes(PARAM_DEACTIVATE_DISABLED);

    if (!org) return <></>
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar} color="transparent">
                <Toolbar>
                    <div className={classes.title}>
                        <Typography variant="h5" noWrap>
                            {org.getName()}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {org.getId()}
                        </Typography>
                    </div>
                    {isFavouriteEnabled && <IconButton onClick={onFavorite}>{isFavourited ? <FavIconSelected /> : <FavIconDefault />}</IconButton>}
                    <IconButton onClick={event => setMenuAnchorEl(event.currentTarget)} edge="end">
                        <MoreVertIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                {!!tags.length && (
                    <div className={classes.chipContainer}>
                        <Typography variant="h6" component="h6">
                            {t('tags')}
                        </Typography>
                        {tags.map((tag, index) => (
                            <Chip size="small" key={index} label={tag} className={classes.chip} />
                        ))}
                    </div>
                )}
                {!!visibilities?.length && (
                    <div className={classes.chipContainer}>
                        <Typography variant="h6" component="h6">
                            {t('visibility')}
                        </Typography>
                        {visibilities.map((tag, index) => (
                            <Chip size="small" key={index} label={tag} className={classes.chip} />
                        ))}
                    </div>
                )}
                {org?.getDescription() && (
                    <Fragment>
                        <Typography variant="h6" component="h6">
                            {t('description')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {org.getDescription()}
                        </Typography>
                    </Fragment>
                )}
                {params && Object.keys(params).length > 0 && (
                    <Fragment>
                        <Typography variant="h6" component="h6">
                            {t('parameters')}
                        </Typography>
                        <Paper className={classes.tablePaper}>
                            <div className={classes.tableWrapper}>
                                <Table stickyHeader className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>{t('key')}</TableCell>
                                            <TableCell align="right" className={[classes.tableCell, classes.tableCellRight]}>
                                                {t('value')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {params && Object.keys(params)
                                            .sort()
                                            .map(key => (
                                                <TableRow key={key} className={classes.tableRow}>
                                                    <TableCell component="th" scope="row" className={classes.tableCell}>
                                                        {key}
                                                    </TableCell>
                                                    <TableCell align="right" className={[classes.tableCell, classes.tableCellRight]}>
                                                        {params[key]}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </Fragment>
                )}
                <div className={classes.footer}>
                    {created && (
                        <Typography variant="caption" display="block">
                            <b>created</b> {moment(created.getDate()).format('YYYY-MM-DD HH:mm:ss') + ` ( ${fetchUserMeta(created)} )`}
                        </Typography>
                    )}
                    {updated && (
                        <Typography variant="caption" display="block">
                            <b>updated</b> {moment(updated.getDate()).format('YYYY-MM-DD HH:mm:ss') + ` ( ${fetchUserMeta(updated)} )`}
                        </Typography>
                    )}
                </div>
            </div>
            {menuAnchorEl && (
                <Menu id="menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)}>
                    <OrgMenuItem
                        title={t('edit')}
                        onClick={event => {
                            setMenuAnchorEl(null);
                            onEditClick();
                        }}
                        Icon={<EditIcon ontSize="small" />}
                    />

                    <OrgMenuItem
                        title={t('edit_parameters')}
                        onClick={event => {
                            setMenuAnchorEl(null);
                            onEditParamsClick();
                        }}
                        Icon={<ParamsIcons ontSize="small" />}
                    />

                    <OrgMenuItem
                        title={t('copy')}
                        onClick={event => {
                            setMenuAnchorEl(null);
                            onCopyClick();
                        }}
                        Icon={<CopyIcon fontSize="small" />}
                    />

                    <OrgMenuItem
                        title={t('move')}
                        onClick={event => {
                            setMenuAnchorEl(null);
                            onMoveClick();
                        }}
                        Icon={<MoveIcon fontSize="small" />}
                    />

                    {!isDeactivateDisabled && (
                        <OrgMenuItem
                            title={t('deactivate')}
                            onClick={event => {
                                setMenuAnchorEl(null);
                                onDeactivateClick();
                            }}
                            Icon={<DeleteIcon fontSize="small" />}
                        />
                    )}
                </Menu>
            )}
        </div>
    );
}

function OrgMenuItem({ title, onClick, Icon: IconComponent }) {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon style={{ minWidth: 35 }}>{IconComponent}</ListItemIcon>
            <Typography variant="inherit"> {title}</Typography>
        </MenuItem>
    );
}

export default OrgView;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
    },
    tablePaper: {
        width: '100%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    tableWrapper: {},
    tableRow: {},
    tableCell: {
        borderColor: 'rgba(255,255,255,0.03)',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: 0,
    },
    tableCellRight: {
        paddingRight: '0 !important',
    },
    chipContainer: {
        marginBottom: theme.spacing(3),
    },
    chip: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.margin.small,
        marginRight: theme.margin.small,
        marginTop: theme.margin.small,
    },
    appBar: {
        boxShadow: 'none',
    },
    title: {
        flex: 1,
    },
    content: {
        flex: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    footer: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
}));
