import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Fab, ListItem, List, Drawer, IconButton, Divider, Typography } from '@material-ui/core'
import { PageContext } from '../../../Context/PageProvider'
import AlertDialog from '../../Common/AlertDialog'
import Searchbox from '../../Common/Searchbox'
import GroupView from './GroupView'
import GroupDialog from './GroupDialog'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const drawerWidth = 400

const styles = theme => ({
  root: {
    display: 'flex',
    height: 'calc(100% - 110px)',
  },
  drawer: {
    width: drawerWidth,
    height: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'transparent',
  },
  list: {
    height: '100%',
    overflow: 'auto',
    paddingBottom: 70,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    position: 'absolute',
    top: 36,
    right: 0,
    left: 0,
  },
  groupHeaderContainer: {
    display: 'flex',
  },
  groupHeaderColumn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  groupHeaderText: {
    flex: 1,
  },
  groupHeaderIcons: {
    justifyContent: 'center',
  },
  searchBox: {
    marginTop: theme.spacing(1),
  },
})

class Group extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      errorMessage: null,
      groups: null,
      selectedGroup: null,
      addGroupDialogVisible: false,
      editGroupDialogVisible: false,
      deactivateGroupDialogVisible: false,
      searchTerm: '',
    }
  }

  componentWillMount() {
    this.loadGroups()
  }

  isPrivateGroup(group) {
    const user = this.context.userService.getActiveUser()
    if (group && user && user.userRoleMeta) {
      return user.userRoleMeta.getPrivateGroup() === group.getId()
    }
    return false
  }

  render() {
    const { classes, t } = this.props
    const { loading, selectedGroup, searchTerm, groups } = this.state

    let filteredGroups = groups && groups.length > 0 ? groups.filter(group => !searchTerm || searchTerm.trim() === '' || group.getName().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) : []
    filteredGroups = filteredGroups.sort((a, b) => {
      var nameA = a.getName().toUpperCase()
      var nameB = b.getName().toUpperCase()

      if (this.isPrivateGroup(a) || nameA < nameB) {
        return -1
      }
      if (this.isPrivateGroup(b) || nameA > nameB) {
        return 1
      }
      return 0
    })

    return (
      <div className={classes.root}>
        <Drawer
          PaperProps={{ style: { position: 'relative' } }}
          BackdropProps={{ style: { position: 'relative' } }}
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left">
          <Searchbox className={classes.searchBox} value={searchTerm} onChange={value => this.setState({ searchTerm: value })} />
          <List className={classes.list}>
            {filteredGroups &&
              filteredGroups.map((group, index) => {
                const name = this.isPrivateGroup(group) ? t('private_group') : group.getName()
                return (
                  <ListItem button className={selectedGroup && selectedGroup.id === group.id ? classes.activeListItem : ''} onClick={() => this.onClickGroupItem(group)} key={'groupListItem' + index}>
                    {name}
                  </ListItem>
                )
              })}
            {!loading && filteredGroups && filteredGroups.length === 0 && <ListItem key={'emptyListItem'}>{groups && groups.length > 0 ? t('no_match_found') : t('no_entries_available')}</ListItem>}
          </List>
          <Fab onClick={() => this.setState({ addGroupDialogVisible: true })} size={'small'} color="primary" aria-label="Add" className={classes.fab}>
            <AddIcon />
          </Fab>
        </Drawer>
        <main className={classes.content}>
          {selectedGroup && (
            <Fragment>
              <div className={classes.groupHeaderColumn}>
                <div className={classes.groupHeaderContainer}>
                  <div className={classes.groupHeaderText}>
                    <h3 style={{ marginBottom: 5 }}>{this.isPrivateGroup(selectedGroup) ? t('private_group') : selectedGroup.getName()}</h3>
                    <Typography variant={'subtitle2'}>{selectedGroup.getId()}</Typography>
                  </div>
                  {!this.isPrivateGroup(selectedGroup) && (
                    <div className={classes.groupHeaderIcons}>
                      <IconButton size="small" onClick={() => this.setState({ editGroupDialogVisible: true })}>
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => this.setState({ deactivateGroupDialogVisible: true })} edge="end">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
                <Divider />
              </div>
              <GroupView group={selectedGroup} onGroupUserAdded={this.onGroupUserAdded.bind(this)} onGroupChange={() => this.reloadSelectedGroup()} />
            </Fragment>
          )}
        </main>
        {(this.state.addGroupDialogVisible || this.state.editGroupDialogVisible) && (
          <GroupDialog
            open={this.state.addGroupDialogVisible || this.state.editGroupDialogVisible}
            group={this.state.editGroupDialogVisible ? this.state.selectedGroup : null}
            onSuccess={group => {
              const edited = this.state.editGroupDialogVisible
              this.setState(
                {
                  addGroupDialogVisible: false,
                  editGroupDialogVisible: false,
                },
                () => {
                  let _groups = this.state.groups
                  const groupIndex = _groups.findIndex(g => g.getId() === group.getId())
                  if (groupIndex >= 0) {
                    _groups[groupIndex] = group
                  } else {
                    _groups.push(group)
                  }
                  this.setState({ groups: _groups, selectedGroup: null }, () => this.setState({ selectedGroup: group }))
                },
              )
            }}
            onCancel={() => this.setState({ addGroupDialogVisible: false, editGroupDialogVisible: false })}
          />
        )}
        {this.state.deactivateGroupDialogVisible && (
          <AlertDialog
            open={this.state.deactivateGroupDialogVisible}
            title={t('group_delete_comfirmation_title')}
            message={t('group_delete_comfirmation_description')}
            onCancel={() => this.setState({ deactivateGroupDialogVisible: false })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.deactivateGroup(this.state.selectedGroup.id)}
          />
        )}
        {this.state.errorMessage && (
          <AlertDialog
            open={this.state.errorMessage ? true : false}
            title={t('error')}
            message={this.state.errorMessage}
            submitButtonTitle={t('ok')}
            onSubmit={() => this.setState({ errorMessage: null })}
          />
        )}
      </div>
    )
  }

  reloadSelectedGroup() {
    const selectedGroup = this.state.selectedGroup
    this.setState({ selectedGroup: null })
    this.context.authzService
      .readGroup(selectedGroup.getId())
      .then(group => {
        let groups = this.state.groups
        if (groups && groups.length > 0) {
          for (let index = 0; index < groups.length; index++) {
            const currentGroup = groups[index]
            if (currentGroup.getId() === selectedGroup.getId()) {
              groups[index] = group
            }
          }
        }
        this.setState({ groups, selectedGroup: group })
      })
      .catch(error => {
        console.warn('readGroup error', error)
        this.setState({ errorMessage: mapErrorMessage(error) })
      })
  }

  onGroupUserAdded(userId) {
    let { groups, selectedGroup } = this.state

    for (let index = 0; index < groups.length; index++) {
      const group = groups[index]
      if (group.getId() === selectedGroup.getId()) {
        if (group.getUsers().indexOf(userId) === -1) {
          group.users.push(userId)
          groups[index] = group
          this.setState({ groups, selectedGroup: groups[index] })
          break
        }
      }
    }
  }

  loadGroups() {
    const { t } = this.props
    this.context.authzService
      .readAccessableGroupsOfCurrentUser()
      .then(groups => {
        let selectedGroup = null
        let groupsWithoutPrivateGroup = []
        if (groups && groups.length > 0) {
          groupsWithoutPrivateGroup = groups.filter(group => !this.isPrivateGroup(group))
          if (!this.state.selectedGroup || !groupsWithoutPrivateGroup.find(group => group.getId() === this.state.selectedGroup.getId())) {
            selectedGroup = groupsWithoutPrivateGroup[0]
          }
        }
        this.setState({ groups: groupsWithoutPrivateGroup, selectedGroup, loading: false })
      })
      .catch(error => {
        console.warn('readAccessableGroupsOfCurrentUser error', error)
        this.setState({ groups: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  deactivateGroup(groupId) {
    const { t } = this.props
    this.context.authzService
      .deactivateGroup(groupId)
      .then(success => {
        const groups = this.state.groups.filter(g => g.getId() !== groupId)
        this.setState({ deactivateGroupDialogVisible: false, groups, selectedGroup: groups.length > 0 ? groups[0] : null })
      })
      .catch(error => {
        console.warn('deactivateGroup error', error)
        this.setState({ loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  onClickGroupItem(group) {
    this.setState({ selectedGroup: null }, () => {
      this.setState({ selectedGroup: group })
    })
  }
}
Group.contextType = PageContext

Group.propTypes = {}

export default withTranslation()(withStyles(styles)(Group))
