import { get, set } from 'local-storage'

const KEY_DEFINITION_DEVICE_ORG = 'def-device-org'
const KEY_DEFINITION_TEMPLATE = 'def-template'
const KEY_DEFINITION_ACTION = 'def-action'
const KEY_DEVICE_ASSIGN_ORG_ID = 'device-assign-org'

export default class SessionStore {
  constructor(userId) {
    this.userId = userId

    this.getValue = key => {
      if (this.userId) {
        return get(`${this.userId}-${key}`)
      } else {
        console.warn("[SessionStore] has no session, can't get session setting.")
      }
    }

    this.setValue = (key, value) => {
      if (this.userId) {
        set(`${this.userId}-${key}`, value)
      } else {
        console.warn("[SessionStore] has no session, can't set session setting.")
      }
    }
  }

  /**
   * set last used definition device org
   * @param {Uid} orgId
   */
  setLastUsedDefinitionDeviceOrg(orgId) {
    this.setValue(KEY_DEFINITION_DEVICE_ORG, orgId)
  }

  /**
   * get last used definition device org
   * @returns {Uid | null}
   */
  getLastUsedDefinitionDeviceOrg() {
    return this.getValue(KEY_DEFINITION_DEVICE_ORG)
  }

  /**
   * set last used definition template
   * @param {Object<orgUid,templateUid>} template
   */
  setLastUsedDefinitionTemplate(template) {
    this.setValue(KEY_DEFINITION_TEMPLATE, template)
  }

  /**
   * get last used definition template
   * @returns {Object<orgUid,templateUid> | null}
   */
  getLastUsedDefinitionTemplate() {
    return this.getValue(KEY_DEFINITION_TEMPLATE)
  }

  /**
   * set last used definition action
   * @param {Object<orgUid,actionUid>} action
   */
  setLastUsedDefinitionAction(action) {
    this.setValue(KEY_DEFINITION_ACTION, action)
  }

  /**
   * get last used definition action
   * @returns {Object<orgUid,actionUid> | null}
   */
  getLastUsedDefinitionAction() {
    return this.getValue(KEY_DEFINITION_ACTION)
  }

  /**
   * set last used device assign org id
   * @param {Uid} orgId
   */
  setLastUsedDeviceAssignOrgId(orgId) {
    this.setValue(KEY_DEVICE_ASSIGN_ORG_ID, orgId)
  }

  /**
   * get last used device assign org id
   * @returns {Uid | null}
   */
  getLastUsedDeviceAssignOrgId() {
    return this.getValue(KEY_DEVICE_ASSIGN_ORG_ID)
  }
}
