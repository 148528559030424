import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../../Context/PageProvider'
import Button from '@material-ui/core/Button'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper'
import { MESSAGE_ACTION_TYPE } from '../../m2m-cloud-api/Api/AppMesageService/Models/MessageAction'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { v4 as uuidv4 } from 'uuid'

const FIELD_ID_NAME = `name`
const FIELD_ID_TYPE = `type`

export const FORM_FIELDS = [
  {
    id: FIELD_ID_NAME,
    title: 'name',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
  {
    id: FIELD_ID_TYPE,
    title: 'action_type',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: MESSAGE_ACTION_TYPE.HTTP, title: 'http' },
      { value: MESSAGE_ACTION_TYPE.MAIL, title: 'mail' },
      { value: MESSAGE_ACTION_TYPE.MESSAGE, title: 'message' },
    ],
    validators: ['required'],
    errorMessages: ['this_field_is_required'],
  },
]

const FIELD_ID_HTTP_METHOD = `method`
const FIELD_ID_HTTP_URL = `url`

export const HTTP_FORM_FIELDS = [
  {
    id: FIELD_ID_HTTP_METHOD,
    title: 'method',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: 'get', title: 'http_method_get' },
      { value: 'put', title: 'http_method_put' },
      { value: 'post', title: 'http_method_post' },
      { value: 'delete', title: 'http_method_delete' },
    ],
    validators: ['required'],
    errorMessages: ['this_field_is_required'],
  },
  {
    id: FIELD_ID_HTTP_URL,
    title: 'url',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
]

const FIELD_ID_MAIL_RECIPIENT = `recipient`
const FIELD_ID_MAIL_DELIVERY_MODE = `deliverymode`
const MAIL_DELIVERY_MODES = {
  INLINE: 'inline',
  ATTACHEMENT: 'attachment',
}

export const MAIL_FORM_FIELDS = [
  {
    id: FIELD_ID_MAIL_RECIPIENT,
    title: 'mail',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: ['required', 'isEmail'],
    errorMessages: ['this_field_is_required', 'email_is_not_valid'],
  },
  {
    id: FIELD_ID_MAIL_DELIVERY_MODE,
    title: 'delivery_mode',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: MAIL_DELIVERY_MODES.INLINE, title: 'inline' },
      { value: MAIL_DELIVERY_MODES.ATTACHEMENT, title: 'attachment' },
    ],
    validators: ['required'],
    errorMessages: ['this_field_is_required'],
  },
]

const FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE = `attachment.mimetype`

export const MAIL_DELIVERY_MODE_FORM_FIELDS = [
  {
    id: FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE,
    title: 'mime_type',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: 'text/plain', title: 'text' },
      { value: 'application/json', title: 'json' },
      { value: 'application/xml', title: 'xml' },
    ],
    validators: [],
    errorMessages: [],
  },
]

const FIELD_ID_MESSAGE_ACKABLE = `ackable`
const FIELD_ID_MESSAGE_ACKCMD = `ackcmd`

const DEFAULT_COMMAND = 'RESET_DEMAND'

export const MESSAGE_FORM_FIELDS = [
  {
    id: FIELD_ID_MESSAGE_ACKABLE,
    title: 'ackable',
    fieldType: FIELD_TYPE.CHECKBOX_FIELD,
    validators: [],
    errorMessages: [],
  },
  {
    id: FIELD_ID_MESSAGE_ACKCMD,
    title: 'ackcmd',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: [],
    errorMessages: [],
  },
]

class ActionDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      values: this.getDefaultValues(),
      loading: false,
      errorMessage: null,
      orgItems: null,
    }
  }

  componentDidMount() {
    const orgItems = this.context.orgService.getOrgListFromCache()
    this.setState({ orgItems })
  }
  isEditMode() {
    const { action } = this.props
    return action ? true : false
  }

  getDefaultValues() {
    const { org, action } = this.props
    let values = {}
    FORM_FIELDS.map(field => {
      if (this.isEditMode()) {
        values[field.id] = action[field.id]
      } else {
        if (field.id === FIELD_ID_TYPE) {
          values[field.id] = org.getId()
        } else {
          values[field.id] = ''
        }
      }
    })

    if (this.isEditMode()) {
      const data = action.getData()
      switch (values[FIELD_ID_TYPE]) {
        case MESSAGE_ACTION_TYPE.HTTP:
          values[FIELD_ID_HTTP_METHOD] = data[FIELD_ID_HTTP_METHOD]
          values[FIELD_ID_HTTP_URL] = data[FIELD_ID_HTTP_URL]
          break
        case MESSAGE_ACTION_TYPE.MAIL:
          values[FIELD_ID_MAIL_RECIPIENT] = data[FIELD_ID_MAIL_RECIPIENT]
          values[FIELD_ID_MAIL_DELIVERY_MODE] = data[FIELD_ID_MAIL_DELIVERY_MODE]
          if (values[FIELD_ID_MAIL_DELIVERY_MODE] === MAIL_DELIVERY_MODES.ATTACHEMENT) {
            values[FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE] = data[FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE]
          }
          break
        case MESSAGE_ACTION_TYPE.MESSAGE:
          values[FIELD_ID_MESSAGE_ACKABLE] = data[FIELD_ID_MESSAGE_ACKABLE] === true || data[FIELD_ID_MESSAGE_ACKABLE] === 'true' ? true : false
          values[FIELD_ID_MESSAGE_ACKCMD] = data[FIELD_ID_MESSAGE_ACKCMD]
          break
      }
    } else {
      values[FIELD_ID_MAIL_DELIVERY_MODE] = MAIL_DELIVERY_MODES.INLINE
      values[FIELD_ID_MESSAGE_ACKCMD] = DEFAULT_COMMAND
    }

    return values
  }

  handleSubmit() {
    const { action, org } = this.props
    const { values } = this.state

    this.setState({ loading: true, errorMessage: null })

    const upsertAction = actionId => {
      const name = values[FIELD_ID_NAME]
      const orgId = org.getId()
      const type = values[FIELD_ID_TYPE]
      let data = {}
      switch (values[FIELD_ID_TYPE]) {
        case MESSAGE_ACTION_TYPE.HTTP:
          data[FIELD_ID_HTTP_METHOD] = values[FIELD_ID_HTTP_METHOD]
          data[FIELD_ID_HTTP_URL] = values[FIELD_ID_HTTP_URL]
          break
        case MESSAGE_ACTION_TYPE.MAIL:
          data[FIELD_ID_MAIL_RECIPIENT] = values[FIELD_ID_MAIL_RECIPIENT]
          data[FIELD_ID_MAIL_DELIVERY_MODE] = values[FIELD_ID_MAIL_DELIVERY_MODE]
          if (values[FIELD_ID_MAIL_DELIVERY_MODE] === MAIL_DELIVERY_MODES.ATTACHEMENT) {
            data[FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE] = values[FIELD_ID_MAIL_DELIVERY_MODE_MIME_TYPE]
          }
          break
        case MESSAGE_ACTION_TYPE.MESSAGE:
          data[FIELD_ID_MESSAGE_ACKABLE] = values[FIELD_ID_MESSAGE_ACKABLE]
          data[FIELD_ID_MESSAGE_ACKCMD] = values[FIELD_ID_MESSAGE_ACKCMD]
          break
      }
      this.context.appMessageService
        .upsertAction(actionId, name, data, orgId, type)
        .then(result => {
          this.props.onSuccess()
        })
        .catch(error => {
          console.warn('add template, error: ', JSON.stringify(error))
          this.setState({
            errorMessage: mapErrorMessage(error),
          })
        })
        .finally(() => this.setState({ loading: false }))
    }

    if (this.isEditMode()) {
      upsertAction(action.getId())
    } else {
      const actionId = uuidv4()
      upsertAction(actionId)
    }
  }

  render() {
    const { values, orgItems, loading, errorMessage } = this.state
    const { t, classes } = this.props

    if (orgItems === null) return null

    const buildLocalizedOptions = fieldOptions => {
      if (fieldOptions) {
        let options = []
        fieldOptions &&
          fieldOptions.map(option => {
            options.push({
              ...option,
              title: t(option.title),
            })
          })
        return options
      }
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.isEditMode() ? t('edit_action') : t('add_action')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              {FORM_FIELDS.map(field => {
                let options = buildLocalizedOptions(field.options)
                return (
                  <FormField
                    {...field}
                    value={values[field.id]}
                    options={options}
                    title={t(field.title)}
                    onChange={event => {
                      let _values = values
                      _values[field.id] = event.target.value
                      this.setState({ values: _values, errorMessage: null })
                    }}
                  />
                )
              })}
              {values[FIELD_ID_TYPE] === MESSAGE_ACTION_TYPE.HTTP &&
                HTTP_FORM_FIELDS.map(field => {
                  return (
                    <FormField
                      {...field}
                      title={t(field.title)}
                      options={buildLocalizedOptions(field.options)}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target.value
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                })}
              {values[FIELD_ID_TYPE] === MESSAGE_ACTION_TYPE.MAIL &&
                MAIL_FORM_FIELDS.map(field => {
                  return (
                    <FormField
                      {...field}
                      title={t(field.title)}
                      options={buildLocalizedOptions(field.options)}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target.value
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                })}
              {values[FIELD_ID_TYPE] === MESSAGE_ACTION_TYPE.MAIL &&
                values[FIELD_ID_MAIL_DELIVERY_MODE] === MAIL_DELIVERY_MODES.ATTACHEMENT &&
                MAIL_DELIVERY_MODE_FORM_FIELDS.map(field => {
                  return (
                    <FormField
                      {...field}
                      title={t(field.title)}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target.value
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                })}
              {values[FIELD_ID_TYPE] === MESSAGE_ACTION_TYPE.MESSAGE &&
                MESSAGE_FORM_FIELDS.map(field => {
                  const _field = (
                    <FormField
                      {...field}
                      title={t(field.title)}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target.value
                        this.setState({ values: _values, errorMessage: null }, () => {
                          console.log('values', values)
                        })
                      }}
                    />
                  )
                  return _field
                })}

              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel}>
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {this.isEditMode() ? t('edit_action') : t('add_action')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ActionDialog.contextType = PageContext

ActionDialog.propTypes = {
  open: PropTypes.bool,
  action: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

export default withTranslation()(withStyles(styles)(ActionDialog))
