import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Paper } from '@material-ui/core'
import i18n from '../../i18n'

const ImprintDE = () => {
  return (
    <div className="impressum">
      <h3>SFS unimarket AG</h3>
      <p>
        Rosenbergsaustrasse 4<br />
        CH-9435 Heerbrugg
      </p>

      <p>info@sfs.ch</p>

      <p>
        T +41 71 727 51 51
        <br />F +41 71 727 54 99
      </p>

      <p>
        UID-Nr. Schweiz CHE-105.885.275
        <br />
        Firmennummer CH-550.0.050.272-5
        <br />
        MwSt-Nr. CHE-116.276.910
      </p>
    </div>
  )
}

const ImprintEN = () => {
  return (
    <div className="impressum">
      <h3>SFS unimarket AG</h3>
      <p>
        Rosenbergsaustrasse 4<br />
        CH-9435 Heerbrugg
      </p>

      <p>info@sfs.ch</p>

      <p>
        T +41 71 727 51 51
        <br />F +41 71 727 54 99
      </p>

      <p>
        UID No. Schweiz CHE-105.885.275
        <br />
        Company number CH-550.0.050.272-5
        <br />
        VAT No. CHE-116.276.910
      </p>
    </div>
  )
}

const ImprintDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={'sm'} aria-labelledby="imprint-dialog-title">
        <div className={classes.dialogTitleContainer}>
          <DialogTitle id="imprint-dialog-title">
            <div>{t('imprint')}</div>
          </DialogTitle>
        </div>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.listPaper}>
            <DialogContentText>{i18n.language === 'de' ? <ImprintDE /> : <ImprintEN />}</DialogContentText>
          </Paper>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="submit" color="primary" onClick={onClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
  },
  dialogContent: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: 440,
  },
  listPaper: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    boxShadow: 'none',
    overflow: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
}))

export default ImprintDialog
