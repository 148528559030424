import React, { useContext, useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { List, ListItem, IconButton, LinearProgress, Typography } from '@material-ui/core'
import OrgListView from '../Org/OrgListView'
import Searchbox from '../Common/Searchbox'

import UnAssignIcon from '@material-ui/icons/ArrowForward'
import AssignIcon from '@material-ui/icons/ArrowBack'

const deviceListItemWidth = 200

function AssignDevices({ org }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const context = useContext(PageContext)
  const [orgItems, setOrgItems] = useState(null)
  const [loading, setLoading] = useState(false)
  const [devices, setDevices] = useState(null)
  const [activeOrgId, setActiveOrgId] = useState(null)
  const [selectedAssignedDevices, setSelectedAssignedDevices] = useState([])
  const [selectedUnAssignedDevices, setSelectedUnAssignedDevices] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    loadOrgs()
    loadDevices()
  }, [org.getId()])

  const loadOrgs = callback => {
    context.orgService
      .searchOrg({ from: 0, size: 10000, searchRootOrg: [org.getId()] })
      .then(orgs => {
        setOrgItems(orgs.filter(_org => _org.getId() !== org.getId()))
        callback && callback(orgs)
      })
      .catch(error => {
        console.log('searchOrg, error', error)
      })
  }

  const loadDevices = callback => {
    context.deviceService
      .searchDevice(org.getId())
      .then(async _devices => {
        setDevices(_devices.sort((a, b) => a.getPhysicalId().localeCompare(b.getPhysicalId())))
        callback && callback(_devices)
      })
      .catch(error => {
        console.log('searchDevice, error', error)
      })
  }

  const assign = async () => {
    setLoading(true)
    for (let index = 0; index < devices.length; index++) {
      const device = devices[index]
      if (selectedUnAssignedDevices.indexOf(device.getId()) >= 0) {
        try {
          await context.deviceService.assignDevice(device.getId(), activeOrgId)
          await context.deviceService.activateDevice(device.getId())
          device.assignedOrg = activeOrgId
          device.activated = true
        } catch (error) {
          console.warn('device assign / activate error', device, error)
        }
      }
    }
    setSelectedUnAssignedDevices([])
    setLoading(false)
  }

  const unassign = async () => {
    setLoading(true)
    for (let index = 0; index < devices.length; index++) {
      const device = devices[index]
      if (selectedAssignedDevices.indexOf(device.getId()) >= 0) {
        try {
          await context.deviceService.unassignDevice(device.getId())
          device.assignedOrg = null
        } catch (error) {
          console.warn('device unassign error', device, error)
        }
      }
    }
    setSelectedAssignedDevices([])
    setLoading(false)
  }

  const renderDevice = (device, onClick, assigned = true) => {
    const selectedItems = assigned ? selectedAssignedDevices : selectedUnAssignedDevices
    const isActive = selectedItems.indexOf(device.getId()) >= 0
    return (
      <ListItem key={device.getId()} className={isActive ? [classes.listItem, classes.activeListItem].join(' ') : classes.listItem} onClick={() => onClick(device)}>
        {device.getPhysicalId()}
      </ListItem>
    )
  }

  if (orgItems === null || devices === null)
    return (
      <div>
        <LinearProgress className={classes.progress} />
      </div>
    )

  if (orgItems.length === 0)
    return (
      <div>
        <Typography className={classes.noItemLabel}>{t('no_company_available')}</Typography>
      </div>
    )

  const filteredDevices =
    searchTerm && searchTerm.trim() !== ''
      ? devices.filter(device => {
          let settingsString = ''
          if (device.settings && Object.keys(device.settings).length > 0) {
            Object.keys(device.settings).map(key => {
              settingsString += ` ${key} ${device.settings[key]}`
            })
          }
          const searchValue = `${device.getPhysicalId()} ${device.getId()}${device.getType() || ''} ${device.getSubType() || ''} ${settingsString}`.toLowerCase()
          return searchValue.indexOf(searchTerm.toLowerCase()) !== -1
        })
      : devices

  return (
    <Fragment>
      <div className={classes.searchContainer}>
        <Searchbox value={searchTerm} onChange={value => setSearchTerm(value)} />
      </div>
      <div className={classes.root} style={loading ? { pointerEvents: 'none', opacity: 0.5 } : null}>
        <div className={classes.col} style={{ flex: 1 }}>
          <div className={classes.colHeader}>{t('org')}</div>
          <OrgListView
            orgItems={orgItems}
            activeOrgId={activeOrgId}
            listButtonEnabled={false}
            disabledOrgIds={[]}
            className={classes.orgListView}
            renderRootNode={false}
            onClickItem={activeOrg => {
              setSelectedAssignedDevices([])
              setSelectedUnAssignedDevices([])
              setActiveOrgId(activeOrg.getId())
            }}
            onChangeRootItems={rootItems => {
              if (!activeOrgId && rootItems.length > 0) {
                setActiveOrgId(rootItems[0].getId())
              }
            }}
          />
        </div>
        {activeOrgId && (
          <>
            <div className={classes.divider} />
            <div className={classes.col} style={{ width: deviceListItemWidth }}>
              <div className={classes.colHeader}>{t('assigned')}</div>
              <List component="div" className={classes.deviceList} disablePadding>
                {filteredDevices &&
                  filteredDevices.map(
                    device =>
                      device.getAssignedOrg() === activeOrgId &&
                      renderDevice(device, device => setSelectedAssignedDevices(ids => (ids.indexOf(device.getId()) >= 0 ? ids.filter(id => id !== device.getId()) : [...ids, device.getId()]))),
                  )}
              </List>
              {devices && devices.filter(device => device.getAssignedOrg() === activeOrgId).length > 0 && filteredDevices.filter(device => device.getAssignedOrg() === activeOrgId).length === 0 && (
                <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                  {t('no_match_found')}
                </Typography>
              )}
            </div>
            <div className={classes.divider} />
            <div className={classes.buttonContainer}>
              <IconButton className={classes.button} disabled={selectedAssignedDevices.length === 0} onClick={() => unassign()}>
                <UnAssignIcon />
              </IconButton>
              <IconButton className={classes.button} disabled={selectedUnAssignedDevices.length === 0} onClick={() => assign()}>
                <AssignIcon />
              </IconButton>
            </div>
            <div className={classes.divider} />
            <div className={classes.col} style={{ width: deviceListItemWidth }}>
              <div className={classes.colHeader}>{t('available')}</div>
              <List component="div" className={classes.deviceList} disablePadding>
                {filteredDevices &&
                  filteredDevices.map(
                    device =>
                      !device.getAssignedOrg() &&
                      renderDevice(
                        device,
                        device => setSelectedUnAssignedDevices(ids => (ids.indexOf(device.getId()) >= 0 ? ids.filter(id => id !== device.getId()) : [...ids, device.getId()])),
                        false,
                      ),
                  )}
              </List>
              {devices && devices.filter(device => !device.getAssignedOrg()).length > 0 && filteredDevices.filter(device => !device.getAssignedOrg()).length === 0 && (
                <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                  {t('no_match_found')}
                </Typography>
              )}
            </div>
            <div className={classes.divider} style={{ marginRight: 8 }} />
          </>
        )}
      </div>
    </Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
  },
  progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  noItemLabel: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  colHeader: {
    textAlign: 'center',
    padding: theme.spacing(2),
    fontWeight: 'bold',
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
  },
  deviceList: {
    overflowX: 'hidden',
    overflowY: 'auto',
    minWidth: deviceListItemWidth,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    cursor: 'pointer',
    justifyContent: 'center',
    userSelect: 'none',
    minWidth: deviceListItemWidth,
    wordBreak: 'break-all',
  },
  activeListItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  divider: {
    width: 1,
    backgroundColor: 'rgba(81, 81, 81, 1)',
  },
}))

export default AssignDevices
