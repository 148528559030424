import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { AppBar, Button, Tooltip, IconButton, Grid, Table, TableBody, TableHead, TableCell, TableRow, Fab, ButtonGroup } from '@material-ui/core'
import { get, set } from 'local-storage'
import TemplateDialog from './TemplateDialog'
import ActionDialog from './ActionDialog'
import DefinitionDialog from './DefinitionDialog'
import AlertDialog from '../Common/AlertDialog'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { csvExportDefinitions } from '../../Utilities/CSVExport'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DownloadIcon from '@material-ui/icons/GetApp'

const SETTING_LAST_SELECTED_TAB_INDEX = 'setting-last-selected-message-tab-index'

const styles = theme => ({
  root: {
    maxHeight: '100%',
    overflow: 'auto',
    paddingBottom: 80,
  },
  table: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabDownload: {
    right: theme.spacing(8),
  },
  activeGroupButton: {
    //backgroundColor: theme.palette.primary.main,
    //color: theme.palette.primary.contrastText
  },
  button: {
    marginRight: '10px',
  },
  deviceType: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: 2,
    minWidth: 60,
    textAlign: 'center',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
})

const TAB_TYPE = {
  DEFINITION: 0,
  ACTION: 1,
  TEMPLATE: 2,
}

class AppMessaging extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      errorMessage: null,
      tabIndex: get(SETTING_LAST_SELECTED_TAB_INDEX) || TAB_TYPE.DEFINITION,
      dialogOpen: false,
      templates: null,
      templateToEdit: null,
      templateToDelete: false,
      actions: null,
      actionToEdit: null,
      actionToDelete: false,
      definitions: null,
      definitionDevices: null,
      definitionDeviceAssignedOrgs: null,
      definitionToEdit: null,
      definitionToDelete: null,
    }
  }

  componentWillMount() {
    this.loadContent()
  }

  /**
   * load content of current tab index type
   */
  loadContent() {
    switch (this.state.tabIndex) {
      case TAB_TYPE.TEMPLATE:
        this.loadTemplateList()
        break
      case TAB_TYPE.ACTION:
        this.loadActionList()
        break
      case TAB_TYPE.DEFINITION:
        this.loadDefinitionList()
        break
    }
  }

  /**
   * load template list from api
   */
  loadTemplateList() {
    const { org } = this.props
    this.context.appMessageService
      .getTemplates(org.getId())
      .then(items => {
        items = items.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.setState({ templates: items })
      })
      .catch(error => {
        this.setState({ templates: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  /**
   * load action list from api
   */
  loadActionList() {
    const { org } = this.props
    this.context.appMessageService
      .getActions(org.getId())
      .then(items => {
        items = items.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.setState({ actions: items })
      })
      .catch(error => {
        this.setState({ actions: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  /**
   * load definition list from api
   */
  loadDefinitionList() {
    const { org } = this.props
    this.context.appMessageService
      .getDefinitions(org.getId())
      .then(definitions => {
        let deviceIds = []
        let actionIds = []
        let templateIds = []

        definitions.map(definition => {
          if (deviceIds.indexOf(definition.getDeviceId()) === -1) {
            deviceIds.push(definition.getDeviceId())
          }
          if (actionIds.indexOf(definition.getActionId()) === -1) {
            actionIds.push(definition.getActionId())
          }
          if (templateIds.indexOf(definition.getTemplateId()) === -1) {
            templateIds.push(definition.getTemplateId())
          }
        })

        return Promise.all([
          this.context.deviceService.getDeviceMultiple(deviceIds),
          this.context.appMessageService.getMultipleActions(actionIds),
          this.context.appMessageService.getMultipleTemplates(templateIds),
        ]).then(results => {
          const definitionDevices = results[0]

          const deviceAssignedOrgIds = []
          definitionDevices.map(device => {
            if (device && device.getAssignedOrg() && deviceAssignedOrgIds.indexOf(device.getAssignedOrg()) === -1) {
              deviceAssignedOrgIds.push(device.getAssignedOrg())
            }
          })
          this.context.orgService.readMultiple(deviceAssignedOrgIds).then(definitionDeviceAssignedOrgs => {
            definitions = definitions.sort((a, b) => {
              const aDevice = definitionDevices && definitionDevices.find(device => device.getId() === a.getDeviceId())
              const bDevice = definitionDevices && definitionDevices.find(device => device.getId() === b.getDeviceId())

              const aDeviceAssignedOrg = aDevice && definitionDeviceAssignedOrgs && definitionDeviceAssignedOrgs.find(org => org.getId() === aDevice.getAssignedOrg())
              const bDeviceAssignedOrg = bDevice && definitionDeviceAssignedOrgs && definitionDeviceAssignedOrgs.find(org => org.getId() === bDevice.getAssignedOrg())

              if (aDeviceAssignedOrg && bDeviceAssignedOrg) {
                return aDeviceAssignedOrg.getName().localeCompare(bDeviceAssignedOrg.getName()) || aDevice.getId().localeCompare(bDevice.getId())
              }
              return 0
            })
            this.setState({ definitions, definitionDevices: definitionDevices, definitionDeviceAssignedOrgs, definitionActions: results[1], definitionTemplates: results[2] })
          })
        })
      })
      .catch(error => {
        console.log('loadDefinitionList, error: ', error)
        this.setState({ definitions: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  /**
   * delete active marked action
   */
  deleteAction() {
    const { actionToDelete } = this.state

    this.setState({ loading: true, errorMessage: null })

    this.context.appMessageService
      .deleteAction(actionToDelete)

      .then(result => {
        this.loadContent()
      })
      .catch(error => {
        this.setState({
          errorMessage: mapErrorMessage(error),
        })
      })
      .finally(() => this.setState({ loading: false, actionToDelete: null }))
  }

  /**
   * delete active marked template
   */
  deleteTemplate() {
    const { templateToDelete } = this.state
    console.warn('deleteTemplate', templateToDelete)

    this.setState({ loading: true, errorMessage: null })
    this.context.appMessageService
      .deleteTemplate(templateToDelete)
      .then(result => {
        this.loadContent()
      })
      .catch(error => {
        console.log('deleteTemplate, error', error)
        this.setState({
          errorMessage: mapErrorMessage(error),
        })
      })
      .finally(() => this.setState({ loading: false, templateToDelete: null }))
  }

  /**
   * delete active marked definition
   */
  deleteDefinition() {
    const { definitionToDelete } = this.state

    this.setState({ loading: true, errorMessage: null })
    this.context.appMessageService
      .deleteDefinition(definitionToDelete)
      .then(result => {
        this.loadContent()
      })
      .catch(error => {
        this.setState({
          errorMessage: mapErrorMessage(error),
        })
      })
      .finally(() => this.setState({ loading: false, definitionToDelete: null }))
  }

  /**
   * render template list
   */
  renderTemplates() {
    const { templates } = this.state
    const { classes, t } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('name')}</TableCell>
            <TableCell align="left">{t('subject')}</TableCell>
            <TableCell align="left">{t('row_actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates &&
            templates.map(template => {
              return (
                <TableRow key={template.getId()}>
                  <TableCell align="left">{template.getName()}</TableCell>
                  <TableCell align="left">{template.getHeader()}</TableCell>
                  <TableCell>
                    <Tooltip title={t('edit_template')}>
                      <IconButton onClick={() => this.setState({ dialogOpen: true, templateToEdit: template })} className={classes.button}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete_template')}>
                      <IconButton onClick={() => this.setState({ templateToDelete: template })} className={classes.button}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    )
  }

  /**
   * render action list
   */
  renderActions() {
    const { actions } = this.state
    const { classes, t } = this.props

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('name')}</TableCell>
            <TableCell align="left">{t('actions_type')}</TableCell>
            <TableCell align="left">{t('row_actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions &&
            actions.map(action => {
              return (
                <TableRow key={action.getId()}>
                  <TableCell align="left">{action.getName()}</TableCell>
                  <TableCell align="left">{t(action.getType())}</TableCell>
                  <TableCell>
                    <Tooltip title={t('edit_action')}>
                      <IconButton onClick={() => this.setState({ dialogOpen: true, actionToEdit: action })} className={classes.button}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete_action')}>
                      <IconButton onClick={() => this.setState({ actionToDelete: action })} className={classes.button}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    )
  }

  /**
   * render definition list
   */
  renderDefinitions() {
    const { definitions, definitionDevices, definitionActions, definitionTemplates } = this.state
    const { classes, t } = this.props

    return (
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t('device')}</TableCell>
            <TableCell align="left">{t('action')}</TableCell>
            <TableCell align="left">{t('template')}</TableCell>
            <TableCell align="left">{t('events')}</TableCell>
            <TableCell align="left">{t('parameters')}</TableCell>
            <TableCell align="left">{t('row_actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {definitions &&
            definitions.map(definition => {
              const device = definitionDevices.find(device => device.getId() === definition.getDeviceId())
              let code = '-'
              let driver = '?'
              if (device) {
                const physicalId = device.getPhysicalId()
                code = physicalId.substring(physicalId.indexOf(':') + 1)
                driver = device.getDriver()
              }
              const action = definitionActions.find(action => action.getId() === definition.getActionId())
              const template = definitionTemplates.find(template => template.getId() === definition.getTemplateId())
              const events = definition.getEvents()
              const params = definition.getParams() || {}
              const paramKeys = Object.keys(params)
              const allParamValues = paramKeys.map(key => key + ':' + params[key])

              return (
                <TableRow key={definition.getId()}>
                  <TableCell align="center">
                    <div>
                      <span className={classes.deviceType}>{driver}</span>
                    </div>
                    <div>{code}</div>
                  </TableCell>
                  <TableCell align="left">{action ? action.getName() : '-'}</TableCell>
                  <TableCell align="left">{template ? template.getName() : '-'}</TableCell>
                  <Tooltip title={events.join(', ')}>
                    <TableCell align="left">{events.length > 1 ? events[0] + '*' : events[0]}</TableCell>
                  </Tooltip>
                  <Tooltip title={allParamValues.join(', ')}>
                    <TableCell align="left">{allParamValues.length > 3 ? allParamValues.slice(0, 3).join(', ') + '*' : allParamValues.slice(0, 3).join(', ')}</TableCell>
                  </Tooltip>
                  <TableCell>
                    <Tooltip title={t('edit_definition')}>
                      <IconButton onClick={() => this.setState({ dialogOpen: true, definitionToEdit: definition })} className={classes.button}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete_definition')}>
                      <IconButton onClick={() => this.setState({ definitionToDelete: definition })} className={classes.button}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    )
  }

  onTabChange(newValue) {
    set(SETTING_LAST_SELECTED_TAB_INDEX, newValue)
    this.setState(
      {
        tabIndex: newValue,
      },
      () => this.loadContent(),
    )
  }

  render() {
    const {
      templateToEdit,
      templateToDelete,
      actionToDelete,
      definitionToEdit,
      definitionToDelete,
      dialogOpen,
      tabIndex,
      errorMessage,
      definitions,
      definitionActions,
      definitionTemplates,
      definitionDevices,
    } = this.state
    const { classes, t, org } = this.props

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Fragment></Fragment>
        </AppBar>
        <main className={classes.content} height="100%">
          <Fragment>
            <Grid container justify="center">
              <Grid item>
                <ButtonGroup>
                  <Button color={'primary'} variant={tabIndex === TAB_TYPE.DEFINITION ? 'contained' : null} onClick={() => this.onTabChange(TAB_TYPE.DEFINITION)}>
                    {t('definitions')}
                  </Button>
                  <Button color={'primary'} variant={tabIndex === TAB_TYPE.ACTION ? 'contained' : null} onClick={() => this.onTabChange(TAB_TYPE.ACTION)}>
                    {t('actions')}
                  </Button>
                  <Button color={'primary'} variant={tabIndex === TAB_TYPE.TEMPLATE ? 'contained' : null} onClick={() => this.onTabChange(TAB_TYPE.TEMPLATE)}>
                    {t('templates')}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {tabIndex === TAB_TYPE.TEMPLATE && this.renderTemplates()}
              {tabIndex === TAB_TYPE.ACTION && this.renderActions()}
              {tabIndex === TAB_TYPE.DEFINITION && this.renderDefinitions()}
            </Grid>
            {definitions && tabIndex === TAB_TYPE.DEFINITION && (
              <Fab
                disabled={!definitions || definitions.length === 0}
                size={'small'}
                color="primary"
                className={[classes.fab, classes.fabDownload].join(' ')}
                onClick={() => {
                  csvExportDefinitions(definitions, definitionTemplates, definitionActions, definitionDevices)
                }}>
                <DownloadIcon />
              </Fab>
            )}
            <Fab onClick={() => this.setState({ dialogOpen: true, templateToEdit: false, actionToEdit: false })} size={'small'} color="primary" className={classes.fab}>
              <AddIcon />
            </Fab>
          </Fragment>
        </main>
        {dialogOpen && tabIndex === TAB_TYPE.TEMPLATE && (
          <TemplateDialog
            open={dialogOpen}
            org={org}
            template={templateToEdit}
            onSuccess={() => {
              this.setState({ dialogOpen: false, templateToEdit: null }, () => {
                this.loadContent()
              })
            }}
            onCancel={() => {
              this.setState({ dialogOpen: false })
            }}
          />
        )}
        {dialogOpen && tabIndex === TAB_TYPE.ACTION && (
          <ActionDialog
            open={dialogOpen}
            org={org}
            action={this.state.actionToEdit}
            onSuccess={() => {
              this.setState({ dialogOpen: false }, () => {
                this.loadContent()
              })
            }}
            onCancel={() => {
              this.setState({ dialogOpen: false })
            }}
          />
        )}
        {dialogOpen && tabIndex === TAB_TYPE.DEFINITION && (
          <DefinitionDialog
            open={dialogOpen}
            org={org}
            definition={definitionToEdit}
            onSuccess={() => {
              this.setState({ dialogOpen: false, definitionToEdit: null }, () => {
                this.loadContent()
              })
            }}
            onCancel={() => {
              this.setState({ dialogOpen: false, definitionToEdit: null })
            }}
          />
        )}

        {actionToDelete && (
          <AlertDialog
            open={actionToDelete ? true : false}
            title={t('action_delete_comfirmation_title')}
            message={t('action_delete_comfirmation_description')}
            onCancel={() => this.setState({ actionToDelete: null })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.deleteAction()}
          />
        )}
        {templateToDelete && (
          <AlertDialog
            open={templateToDelete ? true : false}
            title={t('template_delete_confirmation_title')}
            message={t('template_delete_comfirmation_description')}
            onCancel={() => this.setState({ templateToDelete: null })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.deleteTemplate()}
          />
        )}
        {definitionToDelete && (
          <AlertDialog
            open={definitionToDelete ? true : false}
            title={t('definition_delete_confirmation_title')}
            message={t('definition_delete_confirmation_description')}
            onCancel={() => this.setState({ definitionToDelete: null })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.deleteDefinition()}
          />
        )}
        {errorMessage && (
          <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
        )}
      </div>
    )
  }
}

AppMessaging.contextType = PageContext

AppMessaging.propTypes = {
  org: PropTypes.any.isRequired,
  action: PropTypes.any,
}

export default withTranslation()(withStyles(styles)(AppMessaging))
