import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import { TextField, Button, Typography, Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { DRIVER_TYPE } from '../../m2m-cloud-api/Api/DeviceService/Models/Device';
import { FormField, FIELD_TYPE } from '../../Components/Form/FormFieldHelper';
import { ValidatorForm } from 'react-material-ui-form-validator';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const DEVICE_TYPES = Object.values(DRIVER_TYPE);

const SearchDevicesForm = ({ values, onValueChange, onSubmit }) => {
    const context = useContext(PageContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const [orgs, setOrgs] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const form = useRef();

    useEffect(() => {
        const loadExpanded = async () => {
            if (expanded) {
                const orgItems = await context.orgService.searchOrg({ from: 0, size: 10000 });
                setOrgs(orgItems);
            }
        }
        loadExpanded()
    }, [expanded]);

    const handleSubmit = () => {
        onSubmit(values);
    };

    return (
        <div className={classes.form}>
            <ValidatorForm ref={form} onSubmit={handleSubmit} onError={errors => console.log('form error:', errors)}>
                <TextField
                    multiline={true}
                    className={clsx(classes.formItem, classes.formItemText)}
                    minRows={12}
                    maxRows={12}
                    variant={'standard'}
                    fullWidth
                    label={t('device_physical_ids')}
                    placeholder={t('device_physical_ids_placeholder')}
                    value={values?.physicalIds}
                    onChange={event => onValueChange('physicalIds', event.target.value)}
                />

                <Accordion className={classes.formAccordion} onChange={(value, expanded) => setExpanded(expanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>{t('advanced')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormField
                                    id={'driver'}
                                    disabled={false}
                                    fieldType={FIELD_TYPE.SELECT}
                                    value={values?.driver}
                                    renderRootNode={false}
                                    options={[{ value: 'all', title: t('all') }, ...DEVICE_TYPES.map(deviceType => ({ value: deviceType, title: deviceType.toUpperCase() }))]}
                                    title={t('driver')}
                                    validators={[]}
                                    errorMessages={[]}
                                    onChange={event => onValueChange('driver', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField id={'type'} fieldType={FIELD_TYPE.TEXT_FIELD} value={values?.type} title={t('device_management_type')} onChange={event => onValueChange('type', event.target.value)} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormField
                                    id={'ownerOrg'}
                                    disabled={false}
                                    fieldType={FIELD_TYPE.ORG_SELECT}
                                    value={values.ownerOrg || ''}
                                    renderRootNode={false}
                                    options={orgs || []}
                                    defaultValue={t('please_select')}
                                    resetEnabled={true}
                                    title={t('owner')}
                                    validators={[]}
                                    errorMessages={[]}
                                    onChange={value => onValueChange('ownerOrg', value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField
                                    id={'assignedOrg'}
                                    disabled={false}
                                    fieldType={FIELD_TYPE.ORG_SELECT}
                                    value={values.assignedOrg || ''}
                                    renderRootNode={false}
                                    options={orgs || []}
                                    defaultValue={t('please_select')}
                                    resetEnabled={true}
                                    title={t('assigned')}
                                    validators={[]}
                                    errorMessages={[]}
                                    onChange={value => onValueChange('assignedOrg', value)}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </ValidatorForm>

            <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={() => form.current.submit()}>
                    {t('search')}
                </Button>
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    formItem: {
        marginBottom: theme.spacing(2),
    },
    formItemText: {
        marginTop: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        marginTop: theme.spacing(4),
        justifyContent: 'flex-end',
    },
    formAccordion: {
        marginTop: theme.spacing(4),
        backgroundColor: 'rgba(255,255,255,0.05)',
    },
}));

export default SearchDevicesForm;
