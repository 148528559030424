import React from 'react'
import PropTypes from 'prop-types'
import { PageContext } from '../../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core'
import OrgListView from './OrgListView'

export const ROOT_NODE_ID = 'root-node'

class OrgListViewDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeOrgId: props.activeOrgId,
      errorMessage: null,
    }
  }

  onClickOrg(org) {
    this.setState({ activeOrgId: org === ROOT_NODE_ID ? ROOT_NODE_ID : org.getId() })
  }

  handleSubmit() {
    const org = this.state.activeOrgId === ROOT_NODE_ID ? ROOT_NODE_ID : this.props.orgItems.find(org => org.getId() === this.state.activeOrgId)
    this.props.onSelect(org)
  }

  handleReset() {
    this.props.onReset()
  }

  render() {
    const { t, classes, open, onCancel, onSelectLabel, onResetLabel, title, orgItems, renderRootNode, deactivatedOrgs, loading } = this.props

    return (
      <div>
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'xs'} scroll={'paper'} aria-labelledby="form-dialog-title">
          {loading && <LinearProgress className={classes.progress} />}
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent dividers={true} className={classes.dialogContent}>
            <OrgListView
              orgItems={orgItems}
              activeOrgId={this.state.activeOrgId}
              disabledOrgIds={deactivatedOrgs || []}
              className={classes.orgListView}
              renderRootNode={renderRootNode ? true : false}
              onClickItem={this.onClickOrg.bind(this)}
            />
          </DialogContent>
          <DialogActions>
            {onCancel && <Button onClick={onCancel}>{t('cancel')}</Button>}
            {this.props.onReset && (
              <Button onClick={this.handleReset.bind(this)} type="submit">
                {onResetLabel || t('reset')}
              </Button>
            )}
            <Button onClick={this.handleSubmit.bind(this)} disabled={!this.state.activeOrgId} type="submit" color="primary">
              {onSelectLabel || t('select')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

OrgListViewDialog.contextType = PageContext

OrgListViewDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  orgItems: PropTypes.array.isRequired,
  activeOrgId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectLabel: PropTypes.string,
  renderRootNode: PropTypes.bool,
  deactivatedOrgs: PropTypes.array,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  dialogContent: {
    padding: 0,
    margin: 0,
    '& .MuiAppBar-root': {
      backgroundColor: '#333',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  orgListView: {
    paddingBottom: 10,
  },
  progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 99999,
    left: 0,
  },
})

export default withTranslation()(withStyles(styles)(OrgListViewDialog))
