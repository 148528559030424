import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import AlertDialog from '../Common/AlertDialog'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, CircularProgress, List, ListItem } from '@material-ui/core'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import Searchbox from '../Common/Searchbox'

const styles = theme => ({
    progress: {
        margin: theme.spacing(5),
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20,
    },
    content: {
        padding: 0,
    },
    dialogPaper: {
        height: '80%',
    },
    listItem: {
        paddingLeft: theme.spacing(3),
    },
    activeListItem: {
        backgroundColor: theme.palette.primary.main,
    },
    searchBox: {
        paddingLeft: theme.spacing(2),
    },
})

export class AddUserDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            errorMessage: null,
            users: null,
            userId: null,
            searchTerm: '',
        }
    }

    componentDidMount() {
        this.loadUsers()
    }

    handleSubmit() {
        const { api, groupId, t } = this.props
        const { userId } = this.state

        this.setState({ loading: true }, () => {
            api.authzService
                .addUser(groupId, userId)
                .then(success => {
                    this.setState({ userId: null, loading: false })
                    this.props.onSuccess()
                })
                .catch(error => {
                    console.warn('addUser error', error)
                    this.setState({ loading: false, errorMessage: mapErrorMessage(error) })
                })
        })
    }

    async loadUsers() {
        const { api } = this.props
        try {
            const user = await api.userService.getActiveUser()
            const mangeableUserIds = await api.authzService.listManageableUsers(user.getUserId())
            let users = await api.userService.getPublicUsersWithIds(mangeableUserIds)
            users = users.filter(_user => _user.getId() !== user.getUserId()).sort((a, b) => a.getNickname().localeCompare(b.getNickname()))
            this.setState({ users, loading: false })
        } catch (error) {
            let message = mapErrorMessage(error)
            this.setState({ errorMessage: message, loading: false })
        }
    }

    render() {
        const { loading, errorMessage, users, userId, searchTerm } = this.state
        const { classes, t } = this.props

        let filteredUsers = users && users.length > 0 ? users.filter(user => !searchTerm || searchTerm.trim() === '' || user.getNickname().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) : []

        return (
            <div>
                <Dialog classes={{ paper: classes.dialogPaper }} open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('add_user')}</DialogTitle>
                    {users && users.length > 0 && <Searchbox className={classes.searchBox} value={searchTerm} onChange={value => this.setState({ searchTerm: value })} />}
                    <DialogContent className={classes.content}>
                        {loading && (
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress className={classes.progress} />
                            </div>
                        )}
                        {!loading && (
                            <List className={classes.list}>
                                {filteredUsers &&
                                    filteredUsers.map((user, index) => {
                                        return (
                                            <ListItem
                                                button
                                                disabled={this.props.addedUserIds.indexOf(user.getId()) >= 0}
                                                className={user && user.getId() === userId ? [classes.listItem, classes.activeListItem].join(' ') : classes.listItem}
                                                key={user.getId()}
                                                value={user.getId()}
                                                onClick={() => this.setState({ userId: user.getId() })}>
                                                {user.getNickname()}
                                            </ListItem>
                                        )
                                    })}
                                {!loading && filteredUsers && filteredUsers.length === 0 && (
                                    <ListItem className={classes.listItem} key={'emptyListItem'}>
                                        {users && users.length > 0 ? t('no_match_found') : t('no_entries_available')}
                                    </ListItem>
                                )}
                            </List>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel} color="primary">
                            {t('cancel')}
                        </Button>
                        <Button disabled={loading || userId === null} onClick={() => this.handleSubmit()} color="primary">
                            {t('add_user')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {errorMessage && (
                    <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
                )}
            </div>
        )
    }
}

AddUserDialog.propTypes = {
    api: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    groupId: PropTypes.string.isRequired,
    addedUserIds: PropTypes.array.isRequired,
}

export default withTranslation()(withStyles(styles)(AddUserDialog))
