import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography, Checkbox, Button } from '@material-ui/core'
import clsx from 'clsx'
import DeviceFunctionDialog from './DeviceFunctionDialog'
import { useHistory } from 'react-router-dom'

import CheckIcon from '@material-ui/icons/Check'
import { set } from 'local-storage'
import { SETTING_LAST_SELECTED_ORG } from '../../Pages/Org/Org'
import LinkIcon from '@material-ui/icons/Link'

export const ACTION_TYPE = {
    ACTIVATE: 'activate',
    DEACTIVATE: 'deactivate',
    UNASSIGN: 'unassign',
    CHANGE_ASSIGN: 'change_assign',
    CHANGE_OWNER: 'change_owner',
    SET_PARAMS: 'set_params',
    DELETE_PARAMS: 'delete_params',
    SET_STATUS: 'set_status',
    SEND_COMMAND: 'send_command',
}

const DEFAULT_PAGE_SIZE = 1000
const PAGE_SIZES = [100, 500, 1000]

export const hasDeviceAccess = device => {
    return device && device.getDriver() ? true : false
}

function DeviceListView({ devices, orgs, loading, loadPage, children }) {
    const classes = useStyles()
    const [sortedDevices, setSortedDevices] = useState({ items: [] })
    const [checkedList, setCheckedList] = useState([])
    const [allCheckboxChecked, setAllCheckboxChecked] = useState(false)
    const [dialog, setDialog] = useState(null)
    const { t } = useTranslation()
    const history = useHistory()
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE)
    const [page, setPage] = useState(0)

    useEffect(() => {
        setCheckedList([])
        const _sortedDevices = { ...devices }
        if (_sortedDevices?.items?.length > 0) {
            _sortedDevices.items = _sortedDevices.items.sort((device1, device2) => hasDeviceAccess(device2) - hasDeviceAccess(device1))
        }
        setSortedDevices(_sortedDevices)
    }, [devices])

    useEffect(() => {
        let newState = false
        if (sortedDevices?.items?.length > 0 && checkedList?.length > 0) {
            newState = true
            for (let i = 0; i < sortedDevices.items.length; i++) {
                const device = sortedDevices.items[i]
                if (hasDeviceAccess(device) && checkedList.indexOf(device.getId()) === -1) {
                    newState = false
                    break
                }
            }
        }
        setAllCheckboxChecked(newState)
    }, [checkedList])

    const onChangeAll = event => {
        const checkedDevices = []
        if (event.target.checked) {
            for (let i = 0; i < sortedDevices.items.length; i++) {
                const device = sortedDevices.items[i]
                if (hasDeviceAccess(device)) {
                    checkedDevices.push(device.getId())
                }
            }
        }
        setCheckedList(checkedDevices)
    }

    const onChange = event => {
        setCheckedList(prevState => {
            const newState = prevState.filter(id => id !== event.target.id)
            if (event.target.checked) {
                newState.push(event.target.id)
            }
            return newState
        })
    }

    const getOrgName = orgId => {
        const org = orgs.find(org => org.getId() === orgId)
        return org ? org.getName() : orgId
    }

    const getDeviceIdsForFunction = useCallback(
        name => {
            const deviceIds = []
            if (sortedDevices?.items?.length > 0) {
                for (let i = 0; i < sortedDevices.items.length; i++) {
                    const device = sortedDevices.items[i]
                    if (hasDeviceAccess(device) && checkedList.indexOf(device.getId()) !== -1) {
                        let addDevice = false
                        switch (name) {
                            case ACTION_TYPE.ACTIVATE:
                                if (!device.isActivated() && device.getAssignedOrg()) addDevice = true
                                break
                            case ACTION_TYPE.DEACTIVATE:
                                if (device.isActivated()) addDevice = true
                                break
                            case ACTION_TYPE.UNASSIGN:
                                if (device.getAssignedOrg()) addDevice = true
                                break
                            case ACTION_TYPE.CHANGE_ASSIGN:
                            case ACTION_TYPE.CHANGE_OWNER:
                            case ACTION_TYPE.SET_PARAMS:
                            case ACTION_TYPE.DELETE_PARAMS:
                            case ACTION_TYPE.SET_STATUS:
                                addDevice = true
                                break
                        }
                        if (addDevice) deviceIds.push(device)
                    }
                }
            }
            return deviceIds
        },
        [sortedDevices, checkedList],
    )

    const currentPage = sortedDevices?.offset > 0 ? sortedDevices?.offset / rowsPerPage : 0
    const onDeviceDialogClose = reloadData => {
        setDialog(null)
        if (reloadData) {
            loadPage(page, rowsPerPage)
        }
    }
    const onChangePage = (event, nextPage) => {
        const table = document.querySelector('#table-devices')
        if (table) {
            table.scroll(0, 0)
        }
        setPage(nextPage)
    }

    useEffect(() => {
        loadPage(page, rowsPerPage)
    }, [page, rowsPerPage])

    const devicesToActivate = getDeviceIdsForFunction(ACTION_TYPE.ACTIVATE)
    const devicesToDeactivate = getDeviceIdsForFunction(ACTION_TYPE.DEACTIVATE)
    const devicesToUnassign = getDeviceIdsForFunction(ACTION_TYPE.UNASSIGN)
    const devicesToChangeAssign = getDeviceIdsForFunction(ACTION_TYPE.CHANGE_ASSIGN)
    const devicesToChangeOwner = getDeviceIdsForFunction(ACTION_TYPE.CHANGE_OWNER)
    const devicesToSetParams = getDeviceIdsForFunction(ACTION_TYPE.SET_PARAMS)
    const devicesToDeleteParams = getDeviceIdsForFunction(ACTION_TYPE.DELETE_PARAMS)
    const devicesToSetStatus = getDeviceIdsForFunction(ACTION_TYPE.SET_STATUS)

    const pushToOrg = orgId => {
        set(SETTING_LAST_SELECTED_ORG, orgId)
        history.push('/')
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
    }

    return (
        <main className={classes.listContainer} height="100%">
            <div className={classes.toolbar} />
            {children}
            <div className={clsx(classes.deviceToolbarRowButtons, loading && classes.loadingContainer)}>
                <Checkbox
                    color="primary"
                    indeterminate={!allCheckboxChecked && checkedList.length > 0}
                    checked={allCheckboxChecked}
                    onChange={onChangeAll}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
                <Button color="default" disabled={devicesToActivate.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.ACTIVATE, devices: devicesToActivate })}>
                    {t('activate')} ({devicesToActivate.length})
                </Button>
                <Button color="default" disabled={devicesToDeactivate.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.DEACTIVATE, devices: devicesToDeactivate })}>
                    {t('deactivate')} ({devicesToDeactivate.length})
                </Button>
                <Button color="default" disabled={devicesToUnassign.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.UNASSIGN, devices: devicesToUnassign })}>
                    {t('unassign')} ({devicesToUnassign.length})
                </Button>
                <Button color="default" disabled={devicesToChangeAssign.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.CHANGE_ASSIGN, devices: devicesToChangeAssign })}>
                    {t('change_assign')} ({devicesToChangeAssign.length})
                </Button>
                <Button color="default" disabled={devicesToChangeOwner.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.CHANGE_OWNER, devices: devicesToChangeOwner })}>
                    {t('change_owner')} ({devicesToChangeOwner.length})
                </Button>
                <Button color="default" disabled={devicesToSetStatus.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.SET_STATUS, devices: devicesToSetStatus })}>
                    {t('set_status')} ({devicesToSetStatus.length})
                </Button>
                <Button color="default" disabled={devicesToSetParams.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.SET_PARAMS, devices: devicesToSetParams })}>
                    {t('set_parameter')} ({devicesToSetParams.length})
                </Button>
                <Button color="default" disabled={devicesToDeleteParams.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.DELETE_PARAMS, devices: devicesToDeleteParams })}>
                    {t('delete_parameter')} ({devicesToDeleteParams.length})
                </Button>
                <Button color="default" disabled={devicesToDeleteParams.length === 0} onClick={() => setDialog({ type: ACTION_TYPE.SEND_COMMAND, devices: devicesToDeleteParams })}>
                    {t('send_command')} ({devicesToDeleteParams.length})
                </Button>
            </div>
            <TableContainer component={Paper} className={clsx(classes.list, loading && classes.loadingContainer)} id={'table-devices'}>
                <Table stickyHeader className={classes.table} aria-label="device-table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell>{t('device')}</TableCell>
                            <TableCell>{t('device_type')}</TableCell>
                            <TableCell>{t('device_subtype')}</TableCell>
                            <TableCell>{t('owner')}</TableCell>
                            <TableCell>{t('assigned')}</TableCell>
                            <TableCell>{t('activated')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedDevices?.items?.map(device => {
                            const ownerOrgTitle = getOrgName(device.getOwnerOrg())
                            const assignOrgTitle = getOrgName(device.getAssignedOrg())

                            const disableOwnerOrgClick = ownerOrgTitle === device.getOwnerOrg()
                            const disableAssignOrgClick = assignOrgTitle === device.getAssignedOrg()

                            return (
                                <TableRow key={device.getId()} className={clsx(!hasDeviceAccess(device) && classes.disabled)}>
                                    <TableCell padding="checkbox" component="th">
                                        <Checkbox
                                            color="primary"
                                            id={device.getId()}
                                            checked={checkedList && checkedList.indexOf(device.getId()) !== -1}
                                            onChange={onChange}
                                            disabled={!hasDeviceAccess(device)}
                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {device.getPhysicalId()}
                                    </TableCell>
                                    <TableCell>{device.getType()}</TableCell>
                                    <TableCell>{device.getSubType()}</TableCell>
                                    <TableCell onClick={() => pushToOrg(device.getOwnerOrg())} className={clsx(classes.clickableCell, disableOwnerOrgClick && classes.disabledClick)}>
                                        {!disableOwnerOrgClick && <LinkIcon className={classes.linkIcon} />}
                                        {ownerOrgTitle}
                                    </TableCell>
                                    <TableCell onClick={() => pushToOrg(device.getAssignedOrg())} className={clsx(classes.clickableCell, disableAssignOrgClick && classes.disabledClick)}>
                                        {!disableAssignOrgClick && <LinkIcon className={classes.linkIcon} />} {assignOrgTitle}
                                    </TableCell>
                                    <TableCell>{device.isActivated() ? <CheckIcon /> : null}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {sortedDevices?.items.length === 0 && (
                    <Typography align="center" className={classes.noDataText} color={'textSecondary'} variant="body2">
                        {t('no_data_available')}
                    </Typography>
                )}
            </TableContainer>

            {sortedDevices?.items?.length > 0 && (
                <TablePagination
                    rowsPerPageOptions={PAGE_SIZES}
                    component="div"
                    count={sortedDevices?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={t('rows_per_page')}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.stickyPagination}
                    onChangePage={onChangePage}
                    labelDisplayedRows={({ from, to, count }) => (
                        <Typography variant={'body2'}>
                            {sortedDevices.relation === 'gte' ? t('table_pagination_count_label_more_than', { from, to, count }) : t('table_pagination_count_label', { from, to, count })}
                        </Typography>
                    )}
                />
            )}
            <DeviceFunctionDialog type={dialog?.type} devices={dialog?.devices} onClose={onDeviceDialogClose} />
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    deviceToolbarRowButtons: {
        paddingLeft: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(0,0,0,0.2)',
        '& button': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    listContainer: {
        display: 'flex',
        flexGrow: 1,
        paddingTop: 0,
        flexDirection: 'column',
        height: '100%',
    },
    list: {
        flex: 1,
    },
    loadingContainer: {
        opacity: 0.5,
    },
    table: {
        overflow: 'auto',
        maxHeight: '100%',
    },
    noDataText: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(5),
    },
    disabled: {
        opacity: 0.5,
    },
    clickableCell: {
        cursor: 'pointer',
    },
    disabledClick: {
        pointerEvents: 'none',
    },
    stickyPagination: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#333',
    },
    linkIcon: {
        marginBottom: -7,
        width: 16,
        marginRight: 10,
    },
}))

export default DeviceListView
