import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PageContext } from '../../../Context/PageProvider'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'

import { v4 as uuidv4 } from 'uuid'

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

class RoleDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errorMessage: null,
      value: null,
      items: null,
    }
  }

  componentDidMount() {
    if (this.props.role) {
      this.setState({
        value: this.props.role.getName(),
      })
    }
  }

  handleSubmit() {
    const { value } = this.state
    this.setState({ loading: true, mapErrorMessage: null })
    const roleId = this.props.role ? this.props.role.getId() : uuidv4()
    this.context.authzService
      .upsertRole(roleId, value)
      .then(role => {
        this.setState({ loading: false, value: null })
        this.props.onSuccess(role)
      })
      .catch(error => {
        this.setState({ loading: false, mapErrorMessage: mapErrorMessage(error) })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { loading } = this.state
    const { classes, t } = this.props
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.props.role ? t('edit_role') : t('add_role')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              <TextValidator
                label={t('name')}
                value={this.state.value}
                onChange={event => {
                  this.setState({ value: event.target.value })
                }}
                margin="dense"
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
                fullWidth
              />
              {mapErrorMessage && <DialogContentText className={classes.errorText}>{mapErrorMessage}</DialogContentText>}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {this.props.role ? t('edit_role') : t('add_role')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
RoleDialog.contextType = PageContext

RoleDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool,
  role: PropTypes.any,
}

export default withTranslation()(withStyles(styles)(RoleDialog))
