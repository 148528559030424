import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Paper, TextField, IconButton, Typography, Dialog, DialogActions, DialogContent, Button, InputBase, Tooltip } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Description';

const nl2br = require('react-nl2br');

function MultilineTextfield({ value, onChange, className, disabled = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  var hasLineBreak = /\r|\n/.exec(value || '');
  var cleanedValue = (value || '').replace(/\s/g, '');

  return (
    <Paper className={className ? [classes.searchPaper, className].join(' ') : classes.searchPaper}>
      {!hasLineBreak && <TextField disabled={disabled} fullWidth placeholder={t('value')} value={value} onChange={onChange} InputLabelProps={{ shrink: true }} />}
      {hasLineBreak && (
        <Tooltip title={<div style={{ whiteSpace: 'normal' }}>{nl2br(value)}</div>}>
          <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => setDialogOpen(true)}>
            <InputBase fullWidth readOnly={true} value={cleanedValue} style={{ pointerEvents: 'none' }} />
          </div>
        </Tooltip>
      )}
      <IconButton disabled={disabled} className={classes.deleteIcon} size="small" onClick={() => setDialogOpen(true)} edge="end">
        <EditIcon />
      </IconButton>
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth={'md'}>
          <DialogContent>
            <TextField multiline={true} minRows={20} maxRows={20} fullWidth placeholder={t('value')} value={value} onChange={onChange} InputLabelProps={{ shrink: true }} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary">
              {t('ok')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  searchPaper: {
    boxShadow: 'none',
    display: 'flex',
    //backgroundColor: theme.palette.grey["200"],
    position: 'relative',
  },
  deleteIcon: {
    marginLeft: theme.spacing(2),
  },
  textFieldKey: {
    marginRight: theme.spacing(2),
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default MultilineTextfield;
