import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { get, set } from 'local-storage';
import { Drawer, Toolbar as MaterialToolbar } from '@material-ui/core';
import OrgListView from '../../Components/Org/OrgListView';
import Toolbar from '../../Components/Common/Toolbar';
import AlertDialog from '../../Components/Common/AlertDialog';
import ReportList, { LAST_SELECTED_REPORT_KEY } from '../../Components/Monitoring/ReportList';
import { CORE_DEVICE_REPORT_DEFINATION, CORE_DEVICE_REPORT_SUMMARY } from '../../m2m-cloud-api/MessageLog/Contants';
import MonitoringSummary from '../../Components/Monitoring/MonitoringSummary';
import MonitoringDetail, { QUERY_ORG_TYPE } from '../../Components/Monitoring/MonitoringDetail';
import { exportDataToExcel } from '../../Utilities/ExcelExport';
import Draggable from 'react-draggable';
import clsx from 'clsx';
import moment from 'moment';
import { LAST_SELECTED_ORG_KEY, useMonitoring } from './useMonitoring';

export const DRAWER_WIDTH_KEY = 'monitoring-org-drawer-width';
const reportDrawerWidth = 300;
const orgDrawerLeft = reportDrawerWidth;
const minDrawerWidth = 300;
const orgDrawerDefaultWidth = get(DRAWER_WIDTH_KEY) || 400;
const maxDrawerWidth = (document.documentElement.clientWidth - orgDrawerLeft) / 2;

const Monitoring = ({ classes, t }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeItems, setActiveItems] = useState({ activeOrg: null, activeOrgs: null, activeReportOrg: null, summaryReportBackState: null });
  const [orgDrawerWidth, setOrgDrawerWidth] = useState(orgDrawerDefaultWidth);
  const { orgItems, reportOrgItems, loadAllOrgs, filterByParentAndChildrenOrgsAndRecursiveDept } = useMonitoring();

  const { activeOrg, activeReportOrg, summaryReportBackState } = activeItems;
  const reportSelectedOrgIds = useMemo(() => {
    return activeOrg ? filterByParentAndChildrenOrgsAndRecursiveDept(activeOrg).map(o => o.getId()) : [];
  }, [activeOrg]);

  const handleDrag = e => {
    const _drawerWidth = Math.max(minDrawerWidth, Math.min(e.x - orgDrawerLeft, maxDrawerWidth));
    set(DRAWER_WIDTH_KEY, _drawerWidth);
    setOrgDrawerWidth(_drawerWidth);
  };

  useEffect(() => {
    (async function init() {
      try {
        await loadAllOrgs();
      } catch (error) {
        console.log('Monitoring search org error... ', error);
        setErrorMessage(error);
      }
    })();
  }, []);

  useEffect(() => {
    const lastSelectedOrgId = get(LAST_SELECTED_ORG_KEY);
    const lastSelectedOrg = lastSelectedOrgId && orgItems.find(org => org.getId() === lastSelectedOrgId);

    let _activeOrg = lastSelectedOrg ? lastSelectedOrg : orgItems.length > 0 ? orgItems[0] : null;
    if (activeOrg) {
      const updatedOrg = orgItems.find(org => org.getId() === activeOrg.getId());
      if (updatedOrg) {
        _activeOrg = updatedOrg;
      }
    }

    const lastSelectedReportOrgId = get(LAST_SELECTED_REPORT_KEY);
    const lastSelectedReportOrg = lastSelectedReportOrgId && reportOrgItems.find(org => org.getId() === lastSelectedReportOrgId);

    let _activeReportOrg = lastSelectedReportOrg ? lastSelectedReportOrg : reportOrgItems.length > 0 ? reportOrgItems[0] : null;
    if (activeReportOrg) {
      const updatedReportOrg = reportOrgItems.find(org => org.getId() === activeReportOrg.getId());
      if (updatedReportOrg) {
        _activeReportOrg = updatedReportOrg;
      }
    }
    setActiveItems({ activeOrg: _activeOrg, activeReportOrg: _activeReportOrg });
  }, [orgItems, reportOrgItems]);

  const onClickOrg = useCallback(org => {
    set(LAST_SELECTED_ORG_KEY, org.getId());
    setActiveItems(prevState => ({ ...prevState, activeOrg: org, summaryReportBackState: null }));
  }, []);

  const onChangeReportOrg = useCallback(report => {
    setActiveItems(prevState => ({ ...prevState, activeReportOrg: report, summaryReportBackState: null }));
  }, []);

  const onGotoDetailReport = async (activeOrgIdToSelect, activeReportOrgId) => {
    const summaryReportBackState = { activeOrg: activeOrg, activeReportOrg: activeReportOrg };
    const _activeOrg = orgItems.find(org => org.getId() === activeOrgIdToSelect);
    const _activeReportOrg = [...(reportOrgItems || []), ...(orgItems || [])].find(org => org.getId() === activeReportOrgId);
    setActiveItems({ activeOrg: _activeOrg, activeReportOrg: _activeReportOrg, summaryReportBackState });
  };

  const onSummaryReportGoBack = () => {
    const { activeOrg, activeReportOrg } = summaryReportBackState;
    setActiveItems({ activeOrg: activeOrg, activeReportOrg: activeReportOrg, summaryReportBackState: null });
  };

  const isSummaryReport = activeReportOrg?.hasTags([CORE_DEVICE_REPORT_SUMMARY]) ? true : false;
  const isDetailReport = !isSummaryReport && activeReportOrg?.hasTags([CORE_DEVICE_REPORT_DEFINATION]) ? true : false;

  const downloadData = useCallback(
    (fields, exportData) => {
      try {
        const fileName = `${activeOrg.getName()}_${isSummaryReport ? 'summary' : 'detail'}_${moment().format('YYYY-MM-DD')}`;
        exportDataToExcel(fields, exportData, fileName);
      } catch (error) {
        setErrorMessage(error);
        console.log('Export Orgs Error:', error);
      }
    },
    [activeOrg],
  );

  return (
    <div className={classes.root}>
      <Draggable
        axis="x"
        handle=".draggable-handle"
        defaultPosition={{ x: orgDrawerLeft + orgDrawerWidth, y: 0 }}
        bounds={{ left: orgDrawerLeft + minDrawerWidth, right: orgDrawerLeft + maxDrawerWidth }}
        onDrag={handleDrag}
        grid={[1, 1]}
        scale={1}>
        <div className={clsx(classes.draggable, 'draggable-handle')} />
      </Draggable>
      <div className={classes.drawerRoot}>
        <Drawer
          className={classes.reportDrawer}
          variant="permanent"
          classes={{
            paper: classes.reportDrawerPaper,
          }}>
          <Toolbar title={t('monitoring')} />
          <ReportList orgItems={reportOrgItems} activeReportOrg={activeReportOrg} onChangeReport={onChangeReportOrg} />
        </Drawer>
        <Drawer
          className={classes.orgDrawer}
          variant="permanent"
          style={{ width: orgDrawerWidth }}
          classes={{
            paper: classes.orgDrawerPaper,
          }}>
          <MaterialToolbar></MaterialToolbar>
          <OrgListView orgItems={orgItems} activeOrgId={activeOrg && activeOrg.getId()} onClickItem={onClickOrg} onAdd={null} />
        </Drawer>
      </div>
      <main className={classes.content} height="100%">
        <div className={classes.toolbar} />
        {isSummaryReport && <MonitoringSummary orgItems={orgItems} reportOrg={activeReportOrg} selectedOrg={activeOrg} onGotoDetailReport={onGotoDetailReport} onDownloadData={downloadData} />}
        {isDetailReport && activeReportOrg && activeOrg && (
          <MonitoringDetail
            queryOrgType={QUERY_ORG_TYPE.ASSIGNED_ORG}
            selectedOrg={activeOrg}
            reportSelectedOrgIds={reportSelectedOrgIds}
            reportOrgId={activeReportOrg.getId()}
            onGoBack={summaryReportBackState ? onSummaryReportGoBack : null}
            onDownloadData={downloadData}
          />
        )}
      </main>

      {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerRoot: {
    display: 'flex',
  },
  orgDrawer: {
    flexShrink: 0,
    backgroundColor: 'transparent',
  },
  orgDrawerPaper: {
    width: 'inherit',
    left: 'auto',
    backgroundColor: theme.palette.common.black,
  },
  reportDrawer: {
    width: reportDrawerWidth,
    flexShrink: 0,
    backgroundColor: 'transparent',
  },
  reportDrawerPaper: {
    width: reportDrawerWidth,
    backgroundColor: theme.palette.common.darkBackground,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  draggable: {
    position: 'absolute',
    zIndex: 1299,
    width: 4,
    marginLeft: -4,
    height: '100%',
    backgroundColor: theme.palette.common.white,
    opacity: 0.0,
    cursor: 'col-resize',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
});

export default withTranslation()(withStyles(styles)(Monitoring));
