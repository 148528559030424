import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, LinearProgress, IconButton, MenuItem, ListItemIcon, Menu } from '@material-ui/core';
import { CORE_DEVICE_REPORT_LINK_DETAILED } from '../../../m2m-cloud-api/MessageLog/Contants';
import AlertDialog from '../../Common/AlertDialog';
import Searchbox from '../../Common/Searchbox';
import MonitoringSummaryTable from './MonitoringSummaryTable';

import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useMonitoringSummary } from './useMonitoringSummary';

const HEADER_HEIGHT = 56;

function MonitoringSummary({ reportOrg, selectedOrg, orgItems, onDownloadData, onGotoDetailReport }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { loading, hasData, filteredTreeData, errorMessage, setErrorMessage, searchTerm, setSearchTerm, menuAnchorEl, setMenuAnchorEl, downloadData } = useMonitoringSummary(
        orgItems,
        reportOrg,
        selectedOrg,
        onDownloadData,
    );

    const reportOrgDetailedReportLinkOrgId = reportOrg?.getParam(CORE_DEVICE_REPORT_LINK_DETAILED);
    const onGotoDetailReportProxy = useCallback(
        orgId => {
            onGotoDetailReport(orgId, reportOrgDetailedReportLinkOrgId);
        },
        [reportOrgDetailedReportLinkOrgId],
    );

    return (
        <Fragment>
            <div className={classes.headerContainer}>
                <Grid className={classes.searchWrapper}>
                    <Searchbox value={searchTerm} onChange={setSearchTerm} />
                    <IconButton size={'small'} disabled={!filteredTreeData?.length} onClick={event => setMenuAnchorEl(event.currentTarget)}>
                        <MoreIcon />
                    </IconButton>
                    {menuAnchorEl && (
                        <Menu id="menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)}>
                            <MenuItem
                                onClick={event => {
                                    setMenuAnchorEl(null);
                                    downloadData();
                                }}>
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <GetAppIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit"> {t('export')}</Typography>
                            </MenuItem>
                        </Menu>
                    )}
                </Grid>
            </div>
            {loading && <LinearProgress className={classes.progress} />}
            {filteredTreeData?.length > 0 && <MonitoringSummaryTable onGotoDetailReport={reportOrgDetailedReportLinkOrgId ? onGotoDetailReportProxy : undefined} data={filteredTreeData} />}
            {!loading && !hasData && (
                <Typography className={classes.noDataText} color={'textSecondary'} variant="body2">
                    {t('no_data_available')}
                </Typography>
            )}
            {!loading && hasData && filteredTreeData?.length === 0 && (
                <Typography className={classes.noDataText} color={'textSecondary'} variant="body2">
                    {t('no_match_found')}
                </Typography>
            )}
            {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
        </Fragment>
    );
}

export default MonitoringSummary;

const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: '100%',
        minHeight: 20, // needed for progress view
        overflow: 'auto',
        padding: 0,
    },
    smallColumn: {
        maxWidth: 130,
    },
    noDataText: {
        marginLeft: 24,
        marginTop: 16,
    },
    progress: {
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 99999,
        left: 0,
    },
    headerContainer: {
        height: HEADER_HEIGHT,
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        flexDirection: 'column',
    },
    searchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& button': {
            marginLeft: theme.spacing(2),
        },
    },
    fieldCount: {
        maxWidth: 80,
    },
}));
