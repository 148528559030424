import { useCallback, useContext, useState } from 'react';
import { PageContext } from '../../Context/PageProvider';
import { CORE_DEVICE_REPORT_DEFINATION, CORE_DEVICE_REPORT_SUMMARY, CORE_DEVICE_REPORT_CATEGORY } from '../../m2m-cloud-api/MessageLog/Contants';
import { PARAM_MONITORING_AREA_FILTER, PARAM_MONITORING_DETAILED_REPORTS_RECURSIVE_DEPTH } from '../../m2m-cloud-api/Api/UserService/Models/User';
import { PARAM_MONITORING_AREAS } from '../../m2m-cloud-api/Api/OrgService/Models/Org';
import { buildOrgTreeFromParentOrg } from '../../m2m-cloud-api/Api/Helper';

export const LAST_SELECTED_ORG_KEY = 'monitoring-last-selected-org';

export function useMonitoring() {
  const context = useContext(PageContext);
  const [orgItems, setOrgItems] = useState([]);
  const [reportOrgItems, setReportOrgItems] = useState([]);

  const loadAllOrgs = useCallback(async () => {
    let items = await context.orgService.searchOrg({ from: 0, size: 10000 });

    const userMonitoringAreasToFilter = fetchUserMonitoringAreaFilterItems(context.userService.getActiveUser());
    if (userMonitoringAreasToFilter) {
      items = filterOrgsByUserFilter(items, userMonitoringAreasToFilter);
    }

    setOrgItems(items);

    const reportItems = await context.orgService.searchOrg({
      tags: [CORE_DEVICE_REPORT_DEFINATION, CORE_DEVICE_REPORT_SUMMARY, CORE_DEVICE_REPORT_CATEGORY],
      visibility: ['public-report'],
      modeVisibility: 'or',
      from: 0,
      size: 10000,
    });

    setReportOrgItems(reportItems);
  });

  const filterByParentAndChildrenOrgsAndRecursiveDept = useCallback(
    parent => {
      const depth = fetchUserMonitoringDetailedReportsRecursiveDepth(context.userService.getActiveUser());

      function getChildren(node, currentDepth) {
        if (currentDepth > depth - 1) return [];

        const children = orgItems.filter(item => item.getParentId() === node.getId());
        let descendants = [];

        children.forEach(child => {
          descendants = descendants.concat(getChildren(child, currentDepth + 1));
        });

        return children.concat(descendants);
      }

      const rootNode = orgItems.find(item => item.getId() === parent.getId());
      if (!rootNode) return [];

      return [rootNode, ...getChildren(rootNode, 0)];
    },
    [orgItems],
  );

  return {
    orgItems,
    reportOrgItems,
    loadAllOrgs,
    filterByParentAndChildrenOrgsAndRecursiveDept,
  };
}

const filterOrgsByUserFilter = (orgItems, userMonitoringAreasToFilter) => {
  const orgs = [];
  const addOrgWithParents = org => {
    if (!orgs.find(o => o.getId() === org.getId())) {
      orgs.push(org);
    }
    const parentOrg = orgItems.find(_org => _org.getId() === org.getParentId());
    if (parentOrg) {
      addOrgWithParents(parentOrg);
    }
  };
  for (let i = 0; i < userMonitoringAreasToFilter.length; i++) {
    const areaFilter = userMonitoringAreasToFilter[i];
    for (let ii = 0; ii < orgItems.length; ii++) {
      const org = orgItems[ii];
      const orgMonitoringAreas = org.getParam(PARAM_MONITORING_AREAS)?.split(',') || [];
      if (orgMonitoringAreas.includes(areaFilter)) {
        addOrgWithParents(org);
        const childrenItems = buildOrgTreeFromParentOrg(orgItems, org);
        for (let i = 0; i < childrenItems.length; i++) {
          const childrenOrg = childrenItems[i];
          addOrgWithParents(childrenOrg);
        }
      }
    }
  }
  return orgs;
};

function fetchUserMonitoringAreaFilterItems(user) {
  const userMonitoringAreasToFilterString = user?.getParam(PARAM_MONITORING_AREA_FILTER);
  const userMonitoringAreasToFilter = userMonitoringAreasToFilterString?.trim() !== '' && userMonitoringAreasToFilterString?.split(',');
  if (userMonitoringAreasToFilter?.length > 0) {
    return userMonitoringAreasToFilter;
  }
  return null;
}

function fetchUserMonitoringDetailedReportsRecursiveDepth(user) {
  const recursiveDepth = user?.getParam(PARAM_MONITORING_DETAILED_REPORTS_RECURSIVE_DEPTH);
  const parsedValue = parseInt(recursiveDepth, 10);

  if (isNaN(parsedValue)) {
    return 2; // default depth
  }

  return parsedValue;
}
