import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, LinearProgress, Grid, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import DeviceDynamicListView from './DeviceDynamicList';
import DeviceDialog from '../DeviceManagement/DeviceDialog';
import ClaimDeviceDialog from '../DeviceManagement/ClaimDeviceDialog';
import UnClaimDeviceDialog from '../DeviceManagement/UnClaimDeviceDialog';
import DeviceEventDataDialog from '../DeviceManagement/DeviceEventDataDialog';
import DeviceTriggerEventDialog from '../DeviceManagement/DeviceTriggerEventDialog';
import DevicesSendCommandDialog from '../DeviceManagement/DevicesSendCommandDialog';
import DeviceAssignDialog from '../DeviceManagement/DeviceAssignDialog';
import AlertDialog from '../Common/AlertDialog';
import ScheduleSelectionDialog from '../Common/ScheduleSelectionDialog';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import PropListDialog from '../Common/PropListDialog';
import MonitoringStatusEditor from './MonitoringStatusEditor';
import { DEVICE_TYPE } from '../../m2m-cloud-api/Api/DeviceService/Models/Device';
import { PARAM_DEVICE_REPORT_DEFINITION } from '../../m2m-cloud-api/Api/OrgService/Models/Org';
import { CODE_DEVICE_DRIVER_NAME } from '../../m2m-cloud-api/Api/Drivers/CodeDriver';
import Config from '../../config/Config';
import MonitoringDetailFilterDialog from './MonitoringDetailFilterDialog';
import MonitoringOrgSelect from '../Common/MonitoringOrgSelect';
import clsx from 'clsx';
import Searchbox from '../Common/Searchbox';

import RefreshIcon from '@material-ui/icons/Refresh';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowBack from '@material-ui/icons/ArrowBack';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeviceAssignmentsDialog from '../DeviceManagement/DeviceAssignmentsDialog';
import DeviceActivationsDialog from '../DeviceManagement/DeviceActivationsDialog';
import { exportDeviceReport } from '../../Utilities/ExportDeviceReport';

const defaultDeviceReportDefinition = Config.settings['default-device-report-definition'];
const PAGE_SIZE = 100;

export const QUERY_ORG_TYPE = {
    OWNER_ORG: 'owner_org',
    ASSIGNED_ORG: 'assigned_org',
};

export function fetchReportOrgId(allOrgs, selectedOrg) {
    let orgs = [];
    if (selectedOrg) {
        let org = selectedOrg;
        orgs.push(org);
        while (org && org.getParentId()) {
            org = allOrgs && allOrgs.find(currentOrg => currentOrg && currentOrg.getId() === org.getParentId());
            if (org) {
                orgs = [...orgs, org];
            }
        }
    }
    const reportOrg = orgs.find(org => org.getParam(PARAM_DEVICE_REPORT_DEFINITION));
    if (reportOrg) {
        const reportDefinitionValue = reportOrg.getParam(PARAM_DEVICE_REPORT_DEFINITION);
        const isUuid4RegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        if (isUuid4RegexExp.test(reportDefinitionValue)) {
            return reportDefinitionValue;
        }
        return reportOrg.getId();
    }
    return defaultDeviceReportDefinition;
}

class MonitoringDetail extends React.PureComponent {
    constructor(props) {
        super(props);

        this.querySearchAfterHistory = new Map();

        this.state = {
            loading: true,
            errorMessage: null,
            selectedReportOrgId: props.reportOrgId || null,
            reportData: null,
            deviceDialogVisible: false,
            unClaimDialogVisible: false,
            monitoringStatusEditDialogVisible: false,
            deviceType: null,
            devicesSendCommandDialogVisible: false,
            sendCommandsFilter: null,
            deviceTriggerEventDialogVisible: false,
            selectedDevice: null,
            deviceEventDataDialogVisible: false,
            deviceAssignDataDialogVisible: false,
            orgToAssignDialogOpen: false,
            deviceSettingsDialogVisible: false,
            deviceToAssign: null,
            filterDialogOpen: false,
            filter: null,
            searchTerm: '',
            currentPage: 0,
            menuAnchorEl: null,
            deviceActivationDataDialogVisible: false,
        };
    }

    componentDidMount() {
        this.resetDevicesAndLoad();
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevProp.reportOrgId !== this.props.reportOrgId || prevProp.selectedOrg?.getId() !== this.props.selectedOrg?.getId()) {
            this.resetDevicesAndLoad();
        }
    }

    async resetDevicesAndLoad(newState = {}) {
        this.setState({ ...newState, currentPage: 0, reportData: null }, () => this.queryAllDevices());
    }

    async loadPrevPage() {
        this.setState({ currentPage: this.state.currentPage - 1, reportData: null }, () => this.queryAllDevices());
    }

    async loadNextPage() {
        this.setState({ currentPage: this.state.currentPage + 1, reportData: null }, () => this.queryAllDevices());
    }

    async reloadDevices(useOrgCache = true, newState = {}) {
        this.setState({ ...newState, reportData: null }, () => this.queryAllDevices(useOrgCache));
    }

    async prepareReportData(useOrgCache = true, searchAfter, pageSize = PAGE_SIZE) {
        const { selectedReportOrgId, searchTerm, filter } = this.state;
        const { reportOrgId, reportSelectedOrgIds } = this.props;

        const _reportOrgId = selectedReportOrgId || reportOrgId;

        const requestFilter = { ...(filter || {}) };
        if (searchTerm.trim() !== '') {
            requestFilter.search = searchTerm;
        }
        const reportData = await this.context.deviceService.getDeviceReport(_reportOrgId, reportSelectedOrgIds, searchAfter, pageSize, requestFilter);
        if (reportSelectedOrgIds !== reportSelectedOrgIds) {
            return;
        }
        const itemOrgIds = [];
        const orgIdHeaderIndexes = [];
        if (reportData) {
            // handle org id's
            for (let i = 0; i < reportData.headers.length; i++) {
                const header = reportData.headers[i];
                if (['ownerOrg', 'assignedOrg'].indexOf(header.selection) !== -1) {
                    orgIdHeaderIndexes.push(i);
                }
            }
            const handleDeviceOrgId = orgId => {
                if (!orgId) return;
                if (itemOrgIds.indexOf(orgId) === -1) {
                    itemOrgIds.push(orgId);
                }
            };
            reportData.entry?.map(entry => {
                for (let i = 0; i < orgIdHeaderIndexes.length; i++) {
                    const headerIndex = orgIdHeaderIndexes[i];
                    handleDeviceOrgId(entry.fields[headerIndex]);
                }
            });
            let itemOrgs = await this.context.fetchOrgs(itemOrgIds, useOrgCache);
            // build field mapped values
            for (let i = 0; i < reportData.entry.length; i++) {
                const entry = reportData.entry[i];
                reportData.entry[i]['fieldMappedValues'] = {};
                // org id's
                for (let ii = 0; ii < orgIdHeaderIndexes.length; ii++) {
                    const headerIndex = orgIdHeaderIndexes[ii];
                    const org = itemOrgs.find(org => org.getId() === entry.fields[headerIndex]);
                    if (org) {
                        reportData.entry[i]['fieldMappedValues'][headerIndex] = org.getName();
                    }
                }
                for (let ii = 0; ii < entry.fields.length; ii++) {
                    const header = reportData.headers[ii];
                    let field = entry.fields[ii];
                    if (field) {
                        if (header.selection.length >= 7 && header.selection.indexOf('event') === 0 && header.selection.substring(header.selection.length - 2) === 'ts') {
                            reportData.entry[i]['fieldMappedValues'][ii] = moment(Number(field)).format('YYYY-MM-DD HH:mm:ss');
                        }
                        if (header.selection === 'activated') {
                            if (field === 'true') {
                                reportData.entry[i]['fieldMappedValues'][ii] = 'icon--check';
                            } else {
                                reportData.entry[i]['fieldMappedValues'][ii] = 'icon--uncheck';
                            }
                        }
                    }
                }
            }
            return { reportData, itemOrgs };
        }
    }

    async queryAllDevices(useOrgCache) {
        const { currentPage } = this.state;
        const { reportOrgId } = this.props;
        this.setState({ deviceDialogVisible: false, loading: true });

        try {
            if (!reportOrgId) {
                throw new Error('No report defition org id defined!');
            }

            const { reportData, itemOrgs } = await this.prepareReportData(useOrgCache, this.querySearchAfterHistory.get(currentPage - 1));
            if (reportData && itemOrgs) {
                this.querySearchAfterHistory.set(currentPage, reportData.searchAfter);
                this.setState({ reportData, itemOrgs });
            }
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ reportData: null, loading: false, errorMessage: mapErrorMessage(error) });
        }
    }

    onAssignDevice = device => {
        this.setState({ orgToAssignDialogOpen: true, deviceToAssign: device });
    };

    onUnassignDevice = device => {
        const promisses = [this.context.deviceService.unassignDevice(device.getId())];
        this.context.deviceService
            .updateDevice(device.getId(), promisses)
            .then(result => this.reloadDevices())
            .catch(error => {
                console.log('onUnassignDevice, error', error);
                this.setState({ errorMessage: mapErrorMessage(error) });
            });
    };

    activateDevice = device => {
        const promisses = [this.context.deviceService.activateDevice(device.getId())];
        this.context.deviceService
            .updateDevice(device.getId(), promisses)
            .then(result => this.reloadDevices())
            .catch(error => {
                console.log('activateDevice, error', error);
                this.setState({ errorMessage: mapErrorMessage(error) });
            });
    };

    deactivateDevice = device => {
        const promisses = [this.context.deviceService.deactivateDevice(device.getId())];
        this.context.deviceService
            .updateDevice(device.getId(), promisses)
            .then(result => this.reloadDevices())
            .catch(error => {
                console.log('deactivateDevice, error', error);
                this.setState({ errorMessage: mapErrorMessage(error) });
            });
    };

    updateSettings(device, newSettings, updateOnlyNewSettings = false, reloadDeviceList = true) {
        this.setState({ loading: true });
        let settings = {};
        if (!updateOnlyNewSettings) {
            settings = device.getSettings() || {};
            Object.keys(settings).map(key => {
                settings[key] = null;
            });
        }
        Object.keys(newSettings).map(key => {
            settings[key] = newSettings[key];
        });
        const promisses = [this.context.deviceService.updateSettings(device.getId(), settings)];
        this.context.deviceService
            .updateDevice(device.getId(), promisses)
            .then(result => {
                this.setState({ deviceSettingsDialogVisible: false, monitoringStatusEditDialogVisible: false, selectedDevice: null, loading: false });
                if (reloadDeviceList) this.reloadDevices();
            })
            .catch(error => {
                console.log('updateSettings, error', error);
                this.setState({ errorMessage: mapErrorMessage(error), loading: false });
            });
    }

    async downloadData() {
        try {
            this.setState({ loading: true });
            let mergedReportData = { entry: [], searchAfter: null }

            do {
                const { reportData } = await this.prepareReportData(true, mergedReportData.searchAfter, 2500);
                if (reportData) {
                    mergedReportData = { ...reportData, entry: [...mergedReportData.entry, ...reportData.entry] }
                }
            } while (mergedReportData.searchAfter);

            if (!!mergedReportData.entry.length) {
                exportDeviceReport(mergedReportData, this.props.onDownloadData);
            }

            this.setState({ menuAnchorEl: null, loading: false });
        } catch (error) {
            console.log('Export device report error... ', error);
            this.setState({ errorMessage: mapErrorMessage(error), loading: false });
        }
    }

    render() {
        const {
            errorMessage,
            loading,
            reportData,
            items,
            deviceDialogVisible,
            unClaimDialogVisible,
            monitoringStatusEditDialogVisible,
            deviceType,
            selectedDevice,
            devicesSendCommandDialogVisible,
            sendCommandsFilter,
            deviceTriggerEventDialogVisible,
            deviceEventDataDialogVisible,
            deviceAssignDataDialogVisible,
            deviceActivationDataDialogVisible,
            orgToAssignDialogOpen,
            deviceSettingsDialogVisible,
            deviceToAssign,
            selectedReportOrgId,
        } = this.state;
        const { t, selectedOrg, classes, onGoBack } = this.props;
        let filteredReportData = reportData ? { ...reportData } : null;
        const hasItems = reportData?.entry?.length > 0 ? true : false;

        return (
            <Fragment>
                {reportData && (
                    <div className={classes.searchContainer}>
                        {onGoBack && (
                            <IconButton className={classes.backButton} disabled={loading} disableFocusRipple={true} size={'small'} onClick={onGoBack}>
                                <ArrowBack />
                            </IconButton>
                        )}
                        <Typography variant={'h5'} style={{ flex: 1 }}>
                            {reportData.name}
                            <IconButton className={classes.button} disabled={loading} disableFocusRipple={true} size={'small'} onClick={() => this.reloadDevices(false)}>
                                <RefreshIcon />
                            </IconButton>
                        </Typography>
                        <Grid className={classes.searchWrapper}>
                            <MonitoringOrgSelect defaultReportOrgId={selectedReportOrgId} className={classes.select} onChange={reportOrgId => this.resetDevicesAndLoad({ selectedReportOrgId: reportOrgId })} />
                            <Searchbox
                                value={this.state.searchTerm}
                                className={classes.search}
                                onChange={value => this.resetDevicesAndLoad({ searchTerm: value })}
                                autoFocus={Boolean(this.state.searchTerm)}
                            />
                            <IconButton className={clsx(this.state.filterDialogOpen && classes.iconButtonActive)} size={'small'} disabled={loading} onClick={event => this.setState({ filterDialogOpen: true })}>
                                <FilterListIcon />
                                {this.state.filter && <span className={classes.iconButtonBadge}></span>}
                            </IconButton>
                            <IconButton
                                size={'small'}
                                disabled={!filteredReportData || (filteredReportData && filteredReportData.length <= 0)}
                                onClick={event => this.setState({ menuAnchorEl: event.currentTarget })}>
                                <MoreIcon />
                            </IconButton>
                            {this.state.menuAnchorEl && (
                                <Menu id="menu" anchorEl={this.state.menuAnchorEl} open={Boolean(this.state.menuAnchorEl)} onClose={() => this.setState({ menuAnchorEl: null })}>
                                    <MenuItem disabled={this.state.loading} onClick={this.downloadData.bind(this)}>
                                        <ListItemIcon style={{ minWidth: 35 }}>
                                            <GetAppIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit"> {t('export')}</Typography>
                                    </MenuItem>
                                </Menu>
                            )}
                        </Grid>
                    </div>
                )}
                {loading && <LinearProgress className={classes.progress} />}
                {!loading && !hasItems && !this.state.filter && (
                    <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                        {t('no_data_available')}
                    </Typography>
                )}
                {!loading && !hasItems && this.state.filter && (
                    <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                        {t('no_match_found')}
                    </Typography>
                )}
                {hasItems && filteredReportData.entry.length > 0 && (
                    <DeviceDynamicListView
                        api={this.context}
                        data={filteredReportData}
                        currentPage={this.state.currentPage}
                        pageLimit={PAGE_SIZE}
                        onChangePage={page => {
                            const isPrevPage = page < this.state.currentPage;
                            isPrevPage ? this.loadPrevPage() : this.loadNextPage();
                        }}
                        org={selectedOrg}
                        onAssign={this.onAssignDevice}
                        onUnassign={this.onUnassignDevice}
                        onActivateDevice={this.activateDevice}
                        onDeactivateDevice={this.deactivateDevice}
                        onTriggerEvent={device => this.setState({ deviceTriggerEventDialogVisible: true, selectedDevice: device })}
                        onSendCommand={(device, sendCommands) => this.setState({ devicesSendCommandDialogVisible: true, sendCommandsFilter: sendCommands, selectedDevice: device })}
                        onShowEventData={device => this.setState({ deviceEventDataDialogVisible: true, selectedDevice: device })}
                        onShowAssignmentsData={device => this.setState({ deviceAssignDataDialogVisible: true, selectedDevice: device })}
                        onEditSettings={device => this.setState({ deviceSettingsDialogVisible: true, selectedDevice: device })}
                        activeDeviceId={null}
                        onAdd={this.props.queryOrgType !== QUERY_ORG_TYPE.OWNER_ORG ? deviceType => this.setState({ deviceDialogVisible: true, deviceType }) : undefined}
                        onUnclaim={device => this.setState({ unClaimDialogVisible: true, selectedDevice: device })}
                        onMonitoringStatusEdit={device => this.setState({ monitoringStatusEditDialogVisible: true, selectedDevice: device })}
                        onShowActivationsData={device => this.setState({ deviceActivationDataDialogVisible: true, selectedDevice: device })}
                    />
                )}
                <MonitoringDetailFilterDialog
                    open={this.state.filterDialogOpen}
                    defaultValues={this.state.filter}
                    org={selectedOrg}
                    onSubmit={filter => this.resetDevicesAndLoad({ filter, filterDialogOpen: false })}
                    onCancel={() => this.setState({ filterDialogOpen: false })}
                />
                {deviceDialogVisible && deviceType === DEVICE_TYPE.VIRTUAL && (
                    <DeviceDialog
                        open={deviceDialogVisible}
                        api={this.context}
                        org={selectedOrg}
                        onSuccess={() => this.reloadDevices()}
                        onCancel={() => this.setState({ deviceDialogVisible: false, deviceType: null })}
                    />
                )}
                {deviceDialogVisible && deviceType === DEVICE_TYPE.TIMER && (
                    <ScheduleSelectionDialog
                        open={deviceDialogVisible}
                        onSuccess={async cronString => {
                            await this.context.deviceService.timerDriver.registerTimer(selectedOrg.getId(), cronString);
                            this.reloadDevices();
                        }}
                        onCancel={() => this.setState({ deviceDialogVisible: false, deviceType: null })}
                    />
                )}
                {unClaimDialogVisible && (
                    <UnClaimDeviceDialog
                        api={this.context}
                        open={true}
                        devices={[selectedDevice]}
                        onSuccess={deviceIds => this.setState({ items: items?.filter(item => !deviceIds || deviceIds.indexOf(item.getId()) === -1) })}
                        onClose={() => this.setState({ unClaimDialogVisible: false, selectedDevice: null })}
                    />
                )}
                {deviceDialogVisible && deviceType === DEVICE_TYPE.CLAIM_DEVICE && (
                    <ClaimDeviceDialog
                        api={this.context}
                        open={true}
                        org={selectedOrg}
                        onSuccess={devices => this.setState({ items: [...items, ...devices] })}
                        onClose={() => this.setState({ deviceDialogVisible: false, deviceType: null })}
                    />
                )}
                {selectedDevice && deviceEventDataDialogVisible && (
                    <DeviceEventDataDialog api={this.context} open={true} device={selectedDevice} onClose={() => this.setState({ deviceEventDataDialogVisible: false, selectedDevice: null })} />
                )}
                {selectedDevice && deviceAssignDataDialogVisible && (
                    <DeviceAssignmentsDialog api={this.context} open={true} device={selectedDevice} onClose={() => this.setState({ deviceAssignDataDialogVisible: false, selectedDevice: null })} />
                )}
                {selectedDevice && deviceActivationDataDialogVisible && (
                    <DeviceActivationsDialog api={this.context} open={true} device={selectedDevice} onClose={() => this.setState({ deviceActivationDataDialogVisible: false, selectedDevice: null })} />
                )}
                {deviceTriggerEventDialogVisible && selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME && (
                    <DeviceTriggerEventDialog
                        api={this.context}
                        open={deviceTriggerEventDialogVisible}
                        org={selectedOrg}
                        device={selectedDevice}
                        onCancel={() => this.setState({ deviceTriggerEventDialogVisible: false })}
                        onSuccess={() => this.setState({ deviceTriggerEventDialogVisible: false })}
                    />
                )}
                {devicesSendCommandDialogVisible && (
                    <DevicesSendCommandDialog
                        api={this.context}
                        open={devicesSendCommandDialogVisible}
                        org={selectedOrg}
                        sendCommandsFilter={sendCommandsFilter}
                        devices={[selectedDevice]}
                        onCancel={() => this.setState({ devicesSendCommandDialogVisible: false, sendCommandsFilter: null })}
                        onSuccess={() => this.setState({ devicesSendCommandDialogVisible: false, sendCommandsFilter: null })}
                    />
                )}
                {orgToAssignDialogOpen && (
                    <DeviceAssignDialog
                        api={this.context}
                        open={orgToAssignDialogOpen}
                        device={deviceToAssign}
                        onSuccess={() => this.setState({ orgToAssignDialogOpen: false, deviceToAssign: null }, () => this.reloadDevices())}
                        onCancel={() => this.setState({ orgToAssignDialogOpen: false, deviceToAssign: null })}
                    />
                )}
                {deviceSettingsDialogVisible && (
                    <PropListDialog
                        open={true}
                        loading={loading}
                        title={t('device_settings', { id: selectedDevice?.getPhysicalId() || '' })}
                        params={selectedDevice && selectedDevice.getSettings()}
                        onSubmit={params => this.updateSettings(selectedDevice, params)}
                        onCancel={() => this.setState({ deviceSettingsDialogVisible: false, selectedDevice: null })}
                    />
                )}
                {monitoringStatusEditDialogVisible && (
                    <MonitoringStatusEditor
                        open={true}
                        loading={loading}
                        params={selectedDevice && selectedDevice.getSettings()}
                        onSubmit={params => this.updateSettings(selectedDevice, params, true, false)}
                        onCancel={() => this.setState({ monitoringStatusEditDialogVisible: false, selectedDevice: null })}
                    />
                )}
                {errorMessage && (
                    <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
                )}
            </Fragment>
        );
    }
}

MonitoringDetail.contextType = PageContext;

MonitoringDetail.propTypes = {
    selectedOrg: PropTypes.any.isRequired,
    reportSelectedOrgIds: PropTypes.array.isRequired,
    reportOrgId: PropTypes.string.isRequired,
    queryOrgType: PropTypes.string,
    onDownloadData: PropTypes.func,
};

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    select: {
        marginRight: theme.spacing(2),
    },
    progress: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    iconButtonActive: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    iconButtonBadge: {
        right: theme.spacing(0.5),
        top: theme.spacing(0.75),
        width: 10,
        height: 10,
        borderRadius: 5,
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid #fff',
    },
    searchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& button': {
            marginLeft: theme.spacing(2),
        },
    },
    search: {
        marginRight: theme.spacing(1),
    },
});

export default withTranslation()(withStyles(styles)(MonitoringDetail));
