import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction, IconButton, AppBar, Typography } from '@material-ui/core';
import { CORE_DEVICE_REPORT_DEFINATION, CORE_DEVICE_REPORT_SUMMARY, CORE_DEVICE_REPORT_CATEGORY } from '../../m2m-cloud-api/MessageLog/Contants';
import AlertDialog from '../Common/AlertDialog';
import { get, set } from 'local-storage';
import Searchbox from '../Common/Searchbox';
import clsx from 'clsx';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const LAST_SELECTED_REPORT_KEY = 'monitoring-last-selected-report';
export const OPEN_IDS_REPORT_KEY = 'monitoring-open-ids';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  stickyHeader: {
    fontWeight: 500,
    fontSize: 16,
    background: '#333',
    lineHeight: '34px',
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  activeListItemButton: {
    backgroundColor: theme.palette.primary.main,
  },
  listItemChildren: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  search: {
    flex: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noMatchFoundLabel: {
    marginLeft: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const ReportListItem = ({ org, activeReportOrg, openIds, onClick, triggerExpand, type = CORE_DEVICE_REPORT_SUMMARY }) => {
  const classes = useStyles();

  const hasReport = org.hasTags([type]);
  const childrens = org.childrens;
  const canOpen = childrens?.length > 0;
  const isOpen = canOpen && openIds.find(openId => openId === org.getId()) ? true : false;
  const isActive = org.getId() === activeReportOrg?.getId();

  return (
    <>
      <ListItem button={hasReport ? true : false} onClick={hasReport ? () => onClick(org) : undefined} className={clsx(classes.listItem, isActive && classes.activeListItemButton)}>
        <ListItemText primary={org.getName()} />
        {canOpen && (
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => triggerExpand(org)} edge="end" aria-label="Expand">
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {isOpen &&
        childrens?.map(children => (
          <ListItem
            button
            onClick={() => onClick(children)}
            className={clsx(classes.listItem, classes.listItemChildren, children.getId() === activeReportOrg?.getId() && classes.activeListItemButton)}
            key={`item-${children.getId()}`}>
            <ListItemText primary={children.getName()} />
          </ListItem>
        ))}
    </>
  );
};

const sort = (a, b) => a.getName().localeCompare(b.getName());
const searchFilter = (org, searchTerm) => {
  if (!searchTerm || searchTerm.trim() === '') return true;
  const searchString = `${org.getName() || ''} ${org.getId() || ''}`;
  return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
};

const ReportList = ({ orgItems, activeReportOrg, onChangeReport }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openIds, setOpenIds] = useState(get(OPEN_IDS_REPORT_KEY) || []);

  // sort org to get report categories on top
  orgItems = orgItems.sort((a, b) => a.hasTags([CORE_DEVICE_REPORT_CATEGORY]) - b.hasTags([CORE_DEVICE_REPORT_CATEGORY]));

  let summaryOrgs = [];
  let reportOrgs = [];
  for (let i = 0; i < orgItems.length; i++) {
    const org = orgItems[i];
    const matched = searchFilter(org, searchTerm);
    const parentCategoryOrg = orgItems.find(o => o.getId() === org.getParentId() && o.hasTags([CORE_DEVICE_REPORT_CATEGORY]))?.clone();
    if (org.hasTags([CORE_DEVICE_REPORT_SUMMARY])) {
      if (parentCategoryOrg && !summaryOrgs.find(o => o.getId() === parentCategoryOrg.getId())) {
        parentCategoryOrg.childrens = orgItems
          .filter(item => item.hasTags([CORE_DEVICE_REPORT_SUMMARY]) && item.getParentId() === parentCategoryOrg.getId())
          .sort(sort)
          .filter(org => searchFilter(org, searchTerm));
        if (matched || parentCategoryOrg.childrens?.length > 0) {
          summaryOrgs.push(parentCategoryOrg);
        }
      } else if (!parentCategoryOrg && !summaryOrgs.find(o => o.getId() === org.getId()) && matched) {
        summaryOrgs.push(org);
      }
    }
    if (org.hasTags([CORE_DEVICE_REPORT_DEFINATION])) {
      if (parentCategoryOrg && !reportOrgs.find(o => o.getId() === parentCategoryOrg.getId())) {
        parentCategoryOrg.childrens = orgItems
          .filter(item => item.hasTags([CORE_DEVICE_REPORT_DEFINATION]) && item.getParentId() === parentCategoryOrg.getId())
          .sort(sort)
          .filter(org => searchFilter(org, searchTerm));
        if (matched || parentCategoryOrg.childrens?.length > 0) {
          reportOrgs.push(parentCategoryOrg);
        }
      } else if (!parentCategoryOrg && !reportOrgs.find(o => o.getId() === org.getId()) && matched) {
        reportOrgs.push(org);
      }
    }
  }
  summaryOrgs = summaryOrgs.sort(sort);
  reportOrgs = reportOrgs.sort(sort);

  const handleSelectedOrgs = () => {
    const lastSelectedReportOrgId = get(LAST_SELECTED_REPORT_KEY);
    const lastSelectedReportOrg = lastSelectedReportOrgId && reportOrgs.find(org => org.getId() === lastSelectedReportOrgId);

    let _activeReportOrg = lastSelectedReportOrg ? lastSelectedReportOrg : reportOrgs.length > 0 ? reportOrgs[0] : null;

    if (activeReportOrg) {
      const updatedReportOrg = reportOrgs.find(org => org.getId() === activeReportOrg.getId());
      if (updatedReportOrg) {
        _activeReportOrg = updatedReportOrg;
      }
    }
    onChangeReport(_activeReportOrg);
  };

  const triggerExpand = org => {
    const id = org.getId();
    const isOpen = openIds.find(openId => openId === id) ? true : false;
    const newIds = isOpen ? openIds.filter(openId => openId !== id) : [...openIds, id];
    set(OPEN_IDS_REPORT_KEY, newIds);
    setOpenIds(newIds);
  };

  const onClickReportItem = useCallback(org => {
    set(LAST_SELECTED_REPORT_KEY, org.getId());
    onChangeReport(org);
  }, []);

  useEffect(() => {
    handleSelectedOrgs();
  }, []);

  return (
    <Grid className={classes.root}>
      <AppBar position="sticky" color="transparent" className={classes.appBar}>
        <Searchbox value={searchTerm} onChange={value => setSearchTerm(value)} className={classes.search} />
      </AppBar>
      <List subheader={<li />}>
        <li key={`overview-section`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader className={classes.stickyHeader}>{t('overview')}</ListSubheader>
            {summaryOrgs.map(org => (
              <ReportListItem
                key={`item-${org.getId()}`}
                type={CORE_DEVICE_REPORT_SUMMARY}
                org={org}
                activeReportOrg={activeReportOrg}
                openIds={searchTerm.trim() === '' ? openIds : summaryOrgs.map(org => org.getId())}
                onClick={onClickReportItem}
                triggerExpand={triggerExpand}
              />
            ))}
            {orgItems?.length > 0 && !summaryOrgs?.length && <Typography className={classes.noMatchFoundLabel}>{t('no_match_found')}</Typography>}
          </ul>
        </li>
        <li key={`detail-reports-section`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader className={classes.stickyHeader}>{t('detail_reports')}</ListSubheader>
            {reportOrgs.map(org => (
              <ReportListItem
                key={`item-${org.getId()}`}
                type={CORE_DEVICE_REPORT_DEFINATION}
                org={org}
                activeReportOrg={activeReportOrg}
                openIds={searchTerm.trim() === '' ? openIds : reportOrgs.map(org => org.getId())}
                onClick={onClickReportItem}
                triggerExpand={triggerExpand}
              />
            ))}
            {orgItems?.length > 0 && !summaryOrgs?.length && <Typography className={classes.noMatchFoundLabel}>{t('no_match_found')}</Typography>}
          </ul>
        </li>
      </List>

      {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
    </Grid>
  );
};

export default ReportList;
