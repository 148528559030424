/**
 * export device report with given filtered device data
 * @param {Object} filteredData 
 * @param {Function} onDownloadData 
 */
export const exportDeviceReport = (filteredData, onDownloadData) => {
    let exportData = [];
    const fields = [];
    const excludedRowIndexes = [];
    for (let i = 0; i < filteredData.headers.length; i++) {
        const header = filteredData.headers[i];
        if (['actions'].indexOf(header.selection) === -1) {
            fields.push(header.name);
        } else {
            excludedRowIndexes.push(i);
        }
    }

    filteredData.entry.map(entry => {
        const filterdFields = entry.fields.filter((field, index) => excludedRowIndexes.indexOf(index) === -1);
        const data = [];
        for (let i = 0; i < filterdFields.length; i++) {
            const field = filterdFields[i];
            let value = entry.fieldMappedValues[i] || field || '-';
            if (value === 'icon--check') {
                value = '✓';
            } else if (value === 'icon--uncheck') {
                value = '';
            }
            data[fields[i]] = value;
        }
        exportData.push(data);
    });
    onDownloadData(fields, exportData);
}