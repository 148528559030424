import React, { Fragment, useEffect, useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import { get, set } from 'local-storage';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import { Drawer, Fab, Tabs, Tab } from '@material-ui/core';
import OrgListView from '../../Components/Org/OrgListView';
import Toolbar from '../../Components/Common/Toolbar';
import OrgView from '../../Components/Org/OrgView';
import Files from '../../Components/Common/Files';
import OrgDialog from '../../Components/Org/OrgDialog';
import PropListDialog from '../../Components/Common/PropListDialog';
import MonitoringDetail from '../../Components/Monitoring/MonitoringDetail';
import AssignDevices from '../../Components/DeviceManagement/AssignDevices';
import AppMessaging from '../../Components/Messaging/AppMessaging';
import AlertDialog from '../../Components/Common/AlertDialog';
import OrgMoveDialog from '../../Components/Org/OrgMoveDialog';
import OrgCloneDialog from '../../Components/Org/OrgCloneDialog';
import AddIcon from '@material-ui/icons/Add';
import { PARAM_DEVICE_REPORT_DEFINITION } from '../../m2m-cloud-api/Api/OrgService/Models/Org';
import Config from '../../config/Config';
import { exportDataToExcel } from '../../Utilities/ExcelExport';
import moment from 'moment';
import Draggable from 'react-draggable';
import clsx from 'clsx';
const defaultDeviceReportDefinition = Config.settings['default-device-report-definition'];

export const DRAWER_WIDTH_KEY = 'org-drawer-width';
const drawerWidth = get(DRAWER_WIDTH_KEY) || 400;
const minDrawerWidth = 300;
const maxDrawerWidth = document.documentElement.clientWidth / 2;

export const SETTING_LAST_SELECTED_ORG = 'setting-last-selected-org';
const SETTING_LAST_SELECTED_TAB_INDEX = 'setting-last-selected-org-tab-index';

export function fetchReportOrgId(allOrgs, selectedOrg) {
    let orgs = [];
    if (selectedOrg) {
        let org = selectedOrg;
        orgs.push(org);
        while (org && org.getParentId()) {
            org = allOrgs && allOrgs.find(currentOrg => currentOrg && currentOrg.getId() === org.getParentId());
            if (org) {
                orgs = [...orgs, org];
            }
        }
    }
    const reportOrg = orgs.find(org => org.getParam(PARAM_DEVICE_REPORT_DEFINITION));
    if (reportOrg) {
        const reportDefinitionValue = reportOrg.getParam(PARAM_DEVICE_REPORT_DEFINITION);
        const isUuid4RegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        if (isUuid4RegexExp.test(reportDefinitionValue)) {
            return reportDefinitionValue;
        }
        return reportOrg.getId();
    }
    return defaultDeviceReportDefinition;
}

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
    },
    drawer: {
        flexShrink: 0,
        backgroundColor: 'transparent',
    },
    drawerPaper: {
        width: 'inherit',
        backgroundColor: theme.palette.common.black,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tabbar: {},
    draggable: {
        position: 'absolute',
        zIndex: 1299,
        width: 4,
        height: '100%',
        backgroundColor: theme.palette.common.white,
        opacity: 0.0,
        cursor: 'col-resize',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.7,
        },
    },
});

const Org = ({ classes, t }) => {
    const context = useContext(PageContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [tabIndex, setTabIndex] = useState(get(SETTING_LAST_SELECTED_TAB_INDEX) || 0);
    const [orgItems, setOrgItems] = useState(null);
    const [activeOrg, setActiveOrg] = useState(null);
    const [orgToDeactivate, setOrgToDeactivate] = useState(null);
    const [editOrg, setEditOrg] = useState(null);
    const [editParams, setEditParams] = useState(false);
    const [copyOrg, setCopyOrg] = useState(false);
    const [moveOrg, setMoveOrg] = useState(false);
    const [orgDialogOpen, setOrgDialogOpen] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(drawerWidth);

    const handleDrag = e => {
        const _drawerWidth = Math.max(minDrawerWidth, Math.min(e.x, maxDrawerWidth));
        set(DRAWER_WIDTH_KEY, _drawerWidth);
        setSidebarWidth(_drawerWidth);
    };

    useEffect(() => {
        queryAllOrgs();
    }, []);

    const queryAllOrgs = async () => {
        const items = await context.orgService.searchOrg({ from: 0, size: 10000 });
        const lastSelectedOrgId = get(SETTING_LAST_SELECTED_ORG);
        const lastSelectedOrg = lastSelectedOrgId && items.find(org => org.getId() === lastSelectedOrgId);

        let activeOrg = lastSelectedOrg ? lastSelectedOrg : items.length > 0 ? items[0] : null;
        if (activeOrg) {
            const updatedOrg = items.find(org => org.getId() === activeOrg.getId());
            if (updatedOrg) {
                activeOrg = updatedOrg;
            }
        }
        setOrgItems(items);
        setActiveOrg(activeOrg);
    };

    const onClickOrg = org => {
        set(SETTING_LAST_SELECTED_ORG, org.getId());
        setActiveOrg(null);
        setTimeout(() => {
            setActiveOrg(org);
        }, 100);
    };

    const onTabChange = (event, newValue) => {
        set(SETTING_LAST_SELECTED_TAB_INDEX, newValue);
        setTabIndex(newValue);
    };

    const deactivateOrg = async () => {
        try {
            const orgId = orgToDeactivate.getId();
            setLoading(true);
            await context.orgService.canWriteOrg(orgId);
            await context.orgService.updateOrg(orgId, [context.orgService.deactivate(orgId)]);
            setLoading(false);
            setOrgToDeactivate(null);
            queryAllOrgs();
        } catch (error) {
            setErrorMessage(mapErrorMessage(error));
        }
    };

    const updateParams = async params => {
        const orgId = activeOrg.getId();
        setLoading(true);
        try {
            await context.orgService.canWriteOrg(orgId);
            const promises = [];
            const currentParams = activeOrg.getParams();
            Object.keys(currentParams).map(key => {
                if (Object.keys(params).indexOf(key) === -1) {
                    promises.push(context.orgService.deleteParam(orgId, key));
                }
            });
            Object.keys(params).map(key => {
                if (Object.keys(currentParams).indexOf(key) === -1 || currentParams[key] !== params[key]) {
                    promises.push(context.orgService.putParam(orgId, key, params[key]));
                }
            });

            await context.orgService.updateOrg(orgId, promises);
            setEditParams(false);
            queryAllOrgs();
        } catch (error) {
            setErrorMessage(mapErrorMessage(error));
        }
        setLoading(false);
    };

    const downloadData = (fields, exportData) => {
        try {
            const fileName = `${activeOrg.getName()}_devices_${moment().format('YYYY-MM-DD')}`;
            exportDataToExcel(fields, exportData, fileName);
        } catch (error) {
            setErrorMessage(error);
            console.log('Export Orgs Error:', error);
        }
    };

    return (
        <div className={classes.root}>
            <Draggable axis="x" handle=".draggable-handle" defaultPosition={{ x: sidebarWidth, y: 0 }} bounds={{ left: minDrawerWidth, right: maxDrawerWidth }} onDrag={handleDrag} grid={[1, 1]} scale={1}>
                <div className={clsx(classes.draggable, 'draggable-handle')} />
            </Draggable>
            <Drawer className={classes.drawer} style={{ width: sidebarWidth }} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                <Toolbar title={t('org_page_title')} />

                <OrgListView orgItems={orgItems} activeOrgId={activeOrg?.getId()} onClickItem={onClickOrg} onAdd={null} openIdsSaveKey={'org-open-ids'} />

                <Fab
                    onClick={() => {
                        setEditOrg(false);
                        setOrgDialogOpen(true);
                    }}
                    className={classes.fab}
                    color="primary"
                    aria-label="Add">
                    <AddIcon />
                </Fab>
            </Drawer>
            <main className={classes.content} height="100%">
                <div className={classes.toolbar} />
                {activeOrg && (
                    <Fragment>
                        <Tabs className={classes.tabbar} value={tabIndex} indicatorColor="primary" onChange={onTabChange} aria-label={t('org')}>
                            <Tab label={t('app_messaging')} />
                            <Tab label={t('devices')} />
                            <Tab label={t('info')} />
                            <Tab label={t('assign_devices')} />
                            <Tab label={t('files')} />
                        </Tabs>
                        {tabIndex === 0 && <AppMessaging org={activeOrg} />}
                        {tabIndex === 1 && (
                            <MonitoringDetail selectedOrg={activeOrg} reportOrgId={fetchReportOrgId(orgItems, activeOrg)} reportSelectedOrgIds={[activeOrg.getId()]} onDownloadData={downloadData} />
                        )}
                        {tabIndex === 2 && (
                            <OrgView
                                org={activeOrg}
                                onEditClick={() => {
                                    setOrgDialogOpen(true);
                                    setEditOrg(true);
                                }}
                                onEditParamsClick={() => setEditParams(true)}
                                onDeactivateClick={() => setOrgToDeactivate(activeOrg)}
                                onCopyClick={() => setCopyOrg(true)}
                                onMoveClick={() => setMoveOrg(true)}
                            />
                        )}
                        {tabIndex === 3 && <AssignDevices org={activeOrg} />}
                        {tabIndex === 4 && <Files api={context} org={activeOrg} />}
                    </Fragment>
                )}
            </main>
            {orgDialogOpen && (
                <OrgDialog
                    open={true}
                    isEdit={editOrg ? true : false}
                    org={activeOrg}
                    onSuccess={name => {
                        setOrgDialogOpen(false);
                        setEditOrg(false);
                        queryAllOrgs();
                    }}
                    onCancel={() => {
                        setOrgDialogOpen(false);
                        setEditOrg(false);
                    }}
                />
            )}

            {moveOrg && (
                <OrgMoveDialog
                    open={true}
                    org={activeOrg}
                    onSuccess={() => {
                        setMoveOrg(false);
                        queryAllOrgs();
                    }}
                    onCancel={() => setMoveOrg(false)}
                />
            )}

            {copyOrg && (
                <OrgCloneDialog
                    open={true}
                    org={activeOrg}
                    onSuccess={createdOrg => {
                        const items = [...orgItems, createdOrg];
                        setCopyOrg(false);
                        setOrgItems(items);
                        onClickOrg(createdOrg);
                    }}
                    onCancel={() => setCopyOrg(false)}
                />
            )}

            {activeOrg && editParams && <PropListDialog open={true} loading={loading} title={t('parameters')} params={activeOrg.getParams()} onSubmit={updateParams} onCancel={() => setEditParams(false)} />}

            {orgToDeactivate && (
                <AlertDialog
                    open={true}
                    title={t('org_disable_comfirmation_title')}
                    message={t('org_disable_comfirmation_description')}
                    onCancel={() => setOrgToDeactivate(null)}
                    submitButtonTitle={t('deactivate')}
                    onSubmit={deactivateOrg}
                />
            )}

            {errorMessage && <AlertDialog open={true} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
        </div>
    );
};

export default withTranslation()(withStyles(styles)(Org));
