import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { DefaultStyles } from '../../theme'
import { CircularProgress } from '@material-ui/core'

const styles = theme => ({
  content: { ...DefaultStyles.AbsoluteFill, ...DefaultStyles.CenterVertical },
})

const Loading = ({ classes }) => {
  return (
    <div className={classes.content}>
      <div className={classes.contentWrapper}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default withStyles(styles)(Loading)
