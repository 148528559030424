import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import ListGroupItem from './ListGroupItem';

function MonitoringSummaryTable({ data, onGotoDetailReport }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('org')}</TableCell>
            <TableCell className={classes.countCol}>{t('count')}</TableCell>
            <TableCell>{t('time_range')}</TableCell>
            <TableCell>{t('event')}</TableCell>
            <TableCell>{t('data')}</TableCell>
            <TableCell className={classes.actionsCol}> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(item => (
            <ListGroupItem key={item.orgId} item={item} classes={classes} onGotoDetailReport={onGotoDetailReport} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MonitoringSummaryTable;

const useStyles = makeStyles(theme => ({
  countCol: {
    width: 80,
  },
  actionsCol: {
    width: 24,
  },
}));
