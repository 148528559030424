import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { PARAM_CODE_NAME_SPACE } from '../../m2m-cloud-api/Api/OrgService/Models/Org'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { v4 as uuidv4 } from 'uuid'

class DeviceDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      token: '',
      loading: false,
      errorMessage: null,
    }
  }

  handleSubmit() {
    const { org } = this.props
    const { name, token } = this.state

    this.setState({ loading: true, errorMessage: null })

    const namespace = org.getParam(PARAM_CODE_NAME_SPACE)
    this.props.api.deviceService.codeDriver
      .registerCode(namespace, name, token.trim() !== '' ? token : null)
      .then(result => {
        this.props.onSuccess()
      })
      .catch(error => {
        console.log('registerCode, error: ', JSON.stringify(error))
        this.setState({ errorMessage: mapErrorMessage(error) })
      })
      .finally(() => this.setState({ loading: false }))
  }

  generateNewToken() {
    const token = uuidv4().replace(new RegExp('-', 'g'), '')
    this.setState({
      token,
    })
  }

  render() {
    const { name, token, loading, errorMessage } = this.state
    const { t, classes } = this.props

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('create_device')}</DialogTitle>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
            <DialogContent>
              <TextValidator
                className={classes.field}
                validators={['matchRegexp:^[a-zA-Z0-9-_]+$']}
                value={name}
                onChange={event => {
                  this.setState({ name: event.target.value, error: null })
                }}
                margin="dense"
                id={'name'}
                label={t('code')}
                errorMessages={[t('this_field_is_required')]}
                fullWidth
              />
              <Grid container>
                <Grid item xs={8}>
                  <TextField className={classes.textField} value={token} label={t('token')} margin="dense" />
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained" onClick={this.generateNewToken.bind(this)} className={[classes.button, classes.buttonField]}>
                    {t('generate')}
                  </Button>
                </Grid>
              </Grid>
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={this.props.onCancel}>
                {t('cancel')}
              </Button>
              <Button disabled={loading} type="submit" color="primary">
                {t('create')}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    )
  }
}

DeviceDialog.contextType = PageContext

DeviceDialog.propTypes = {
  api: PropTypes.any.isRequired,
  open: PropTypes.bool,
  org: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
  textField: {
    width: '90%',
  },
  buttonField: {
    marginTop: 18,
  },
})

export default withTranslation()(withStyles(styles)(DeviceDialog))
