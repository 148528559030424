import React from 'react';

import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

export const PRIMARY_COLOR = '#E63A1B';
export const SECONDARY_COLOR = '#706F6F';

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {},
        body: {
          overscrollBehavior: 'none',
        },
      },
    },

    MuiTableCell: {
      root: {
        padding: '2px 16px',
      },
      head: {
        padding: '10px 16px',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none!important',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        boxShadow: 'none !important',
      },
      contained: {
        textTransform: 'uppercase',
      },
      containedSecondary: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: '#1a1a1a',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#808080',
      },
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      darkBackground: '#222',
    },
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: {
      main: red.A400,
    },
  },
  shape: {
    borderRadius: 2,
  },
  font: {
    extraSmall: 12,
    small: 12,
    medium: 14,
    large: 18,
  },
  padding: {
    extraSmall: 2,
    small: 4,
    medium: 8,
    large: 16,
    extraLarge: 32,
  },
  margin: {
    extraSmall: 2,
    small: 4,
    medium: 8,
    large: 16,
    extraLarge: 32,
  },
});

//console.log('theme', theme)

// Light theme
const lightTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    type: 'light',
    primary: {
      main: '#E63A1B',
      lightBadge: 'rgba(230,58,27,0.6)',
    },
    secondary: {
      main: '#706F6F',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: theme.palette.common.white,
    },
  },
  overrides: {
    ...theme.overrides,
    MuiCssBaseline: {
      '@global': {
        html: {},
        body: {
          overscrollBehavior: 'none',
        },
        '.MuiSvgIcon-root': {},
        '.MuiTab-textColorPrimary.Mui-selected': {
          color: `${theme.palette.common.white} !important`,
          backgroundColor: theme.palette.primary.main,
        },
        '.MuiTabScrollButton-root': {
          backgroundColor: '#f6f6f6 !important',
          marginLeft: '12px !important',
          paddingTop: '2px !important',
          marginBottom: '4px !important',
          marginRight: '12px !important',
          color: `${theme.palette.common.black} !important`,
          '&:first-child': {
            marginLeft: '0 !important',
          },
          '&:last-child': {
            marginRight: '0 !important',
          },
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: '#1a1a1a',
      },
    },
  },
});

const darkTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputLabel: {
      root: {
        color: theme.palette.common.white,
      },
    },
    MuiListItem: {
      root: {
        color: theme.palette.common.white,
        '&$selected': {},
      },
    },
    MuiCard: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.1)',
        color: theme.palette.common.white,
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: theme.palette.common.black,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#333',
      },
    },
  },
  palette: {
    common: theme.palette.common,
    type: 'dark',
    primary: {
      main: '#E63A1B',
      lightBadge: 'rgba(230,58,27,0.6)',
    },
    secondary: {
      main: '#706F6F',
    },
    error: {
      main: red.A400,
    },
  },
});

//console.log('darkTheme', darkTheme)

export const DefaultStyles = {
  AbsoluteFill: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  CenterVertical: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  SingleLineLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
};

const withDarkTheme = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <ThemeProvider theme={darkTheme}>
          <WrappedComponent {...this.props} />
        </ThemeProvider>
      );
    }
  };
};

const withLightTheme = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <ThemeProvider theme={lightTheme}>
          <WrappedComponent {...this.props} />
        </ThemeProvider>
      );
    }
  };
};

export default darkTheme;
export { darkTheme, lightTheme, withLightTheme, withDarkTheme };
