import React, { useContext, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { PageContext } from '../../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { TextValidator } from 'react-material-ui-form-validator'
import { PARAM_NAME, PARAM_CODE_NAME_SPACE } from '../../m2m-cloud-api/Api/OrgService/Models/Org'

const OrgCloneDialog = ({ org, open, onSuccess, onCancel }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const context = useContext(PageContext)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const form = useRef()

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const params = { ...org.getParams() }
      let createNameSpace = false
      if (params[PARAM_CODE_NAME_SPACE]) {
        delete params[PARAM_CODE_NAME_SPACE]
        createNameSpace = true
      }
      params[PARAM_NAME] = name
      const createdOrg = await context.orgService.create(org.getParentId(), params, org.getTags())
      if (createNameSpace) {
        // set namespace
        await context.orgService.putParam(createdOrg.getId(), PARAM_CODE_NAME_SPACE, createdOrg.getId())
      }
      onSuccess(createdOrg)
    } catch (error) {
      setErrorMessage(t(mapErrorMessage(error)))
    }
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'sm'}>
      <DialogTitle>{t('copy_org')}</DialogTitle>
      <DialogContent>
        <ValidatorForm ref={form} onSubmit={handleSubmit} onError={errors => console.log('form error:', errors)}>
          <TextValidator
            className={classes.field}
            value={name}
            disabled={loading}
            onChange={event => setName(event.target.value)}
            margin="dense"
            id={'name'}
            label={t('name')}
            validators={['required']}
            errorMessages={['this_field_is_required']}
            fullWidth
          />
          {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
        </ValidatorForm>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button disabled={loading} onClick={() => form.current.submit()} color="primary">
          {t('create')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({}))

export default OrgCloneDialog
