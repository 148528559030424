import React, { Fragment, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { IconButton, Fab, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import AddAccessTokenDialog from './AddAccessTokenDialog'
import moment from 'moment'
import AlertDialog from '../Common/AlertDialog'

import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'

const TABLE_HEAD_VALUES = {
  NAME: 'name',
  KEY: 'key',
  VALID_UNTIL: 'valid_until',
  VALID: 'valid',
  ACTIONS: 'actions',
}

function AccessTokenListView({ api, data, onReloadTokens, onError }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const context = api
  const [tokenDialogVisible, setTokenDialogVisible] = useState(false)
  const [deleteToken, setDeleteToken] = useState(null)

  const onOpenTokenDialog = useCallback(() => {
    setTokenDialogVisible(true)
  }, [])
  const onClosetokenDialog = useCallback(added => {
    if (added) {
      onReloadTokens()
    }
    setTokenDialogVisible(false)
  }, [])

  const onDeleteToken = useCallback(async id => {
    try {
      await context.userService.deleteAccessToken(id)
      onReloadTokens()
    } catch (error) {
      onError(error)
    }
  }, [])

  return (
    <Fragment>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {Object.keys(TABLE_HEAD_VALUES).map(key => (
              <TableCell align={TABLE_HEAD_VALUES[key] === TABLE_HEAD_VALUES.ACTIONS ? 'right' : 'left'}>{t(`table_head_${TABLE_HEAD_VALUES[key]}`)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(token => (
            <TableRow>
              <TableCell align="left">{token.getName()}</TableCell>
              <TableCell align="left">{token.getKey()}</TableCell>
              <TableCell>{token.getExpiresDate()}</TableCell>
              <TableCell align={'left'}>{moment(token.expires) < moment() ? <CloseIcon /> : <CheckIcon />}</TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={() => setDeleteToken(token.getId())} edge="end">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab onClick={onOpenTokenDialog} size={'small'} color="secondary" aria-label="Add" className={classes.fab}>
        <AddIcon />
      </Fab>
      {tokenDialogVisible && <AddAccessTokenDialog open={true} onClose={onClosetokenDialog} />}
      {deleteToken && (
        <AlertDialog
          open={true}
          title={t('token_delete_comfirmation_title')}
          message={t('token_delete_comfirmation_description')}
          onCancel={() => setDeleteToken(null)}
          submitButtonTitle={t('delete')}
          onSubmit={() => onDeleteToken(deleteToken)}
        />
      )}
    </Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  table: {
    flex: 1,
    maxHeight: '100%',
    overflow: 'auto',
  },
  fab: {
    position: 'fixed',
    top: 'auto',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default AccessTokenListView
