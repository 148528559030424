import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Paper, InputBase } from '@material-ui/core';
import debounce from 'lodash.debounce';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

function Searchbox({ value, onChange, className, ...props }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    var debounce_fun = useMemo(() => {
        return debounce(onChange, 500);
    }, []);

    const onTextChange = event => {
        setSearchTerm(event.target.value);
        debounce_fun(event.target.value.trim());
    };

    useEffect(() => {
        setSearchTerm(value);
    }, []);

    return (
        <Paper className={className ? [classes.searchPaper, className].join(' ') : classes.searchPaper}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase {...props} className={classes.searchTextField} value={searchTerm ?? ''} onChange={onTextChange} placeholder={t('search')} />
            {value !== '' && (
                <CloseIcon
                    className={classes.closeIcon}
                    onClick={() => {
                        setSearchTerm('');
                        onChange('');
                    }}
                />
            )}
        </Paper>
    );
}

const useStyles = makeStyles(theme => ({
    searchPaper: {
        boxShadow: 'none',
        display: 'flex',
        //backgroundColor: theme.palette.grey["200"],
        position: 'relative',
        alignItems: 'center',
    },
    searchIcon: {
        margin: theme.spacing(0.5),
        color: theme.palette.secondary.main,
    },
    closeIcon: {
        position: 'absolute',
        top: '50%',
        right: 0,
        marginRight: theme.spacing(0.5),
        color: theme.palette.secondary.main,
        width: 20,
        cursor: 'pointer',
        transform: 'translate(0%, -50%)',
    },
    searchTextField: {
        flex: 1,
        paddingRight: 30,
    },
}));

export default Searchbox;
