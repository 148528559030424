import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  list: {
    width: 320,
    backgroundColor: theme.palette.primary.main,
    height: '100%',
  },
}))

function DrawerMenu({ items, active, open, onOpen, onClose, history }) {
  const classes = useStyles()

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (open) {
      onOpen()
    } else {
      onClose()
    }
  }

  return (
    <SwipeableDrawer open={open} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
      <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <List>
          {items.map((item, index) => (
            <ListItem key={item.path} onClick={() => history.push(item.path)} button>
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  )
}

export default withRouter(DrawerMenu)
