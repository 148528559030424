import { Route, Redirect } from 'react-router-dom';
import React, { useContext } from 'react';
import { PageContext } from './Context/PageProvider';

const DefaultRoute = ({ component: Component, ...rest }) => {
  const context = useContext(PageContext);
  return <Route {...rest} render={props => <Component {...rest} {...props} context={context} />} />;
};

export default DefaultRoute;
