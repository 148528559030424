import i18n from 'i18next';

/**
 * map error mesage
 * @param {Error} error
 *
 * @returns {String} - translated error text
 */
export function mapErrorMessage(error) {
  if (error.originError && error.originError.name) {
    const key = 'error_' + error.originError.name;
    const message = i18n.t(key);
    if (key === message) {
      if (error.originError.detail && error.originError.detail.trim() !== '') {
        return error.originError.detail;
      }
      return error.originError.name;
    }
    return message;
  }
  return error.message;
}

/**
 * convert given bytes to file size
 * @param {Number} bytes
 * @returns {String}
 */
export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}
