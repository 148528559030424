import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, CircularProgress, List, ListItem } from '@material-ui/core'
import { PageContext } from '../../../Context/PageProvider'
import AlertDialog from '../../Common/AlertDialog'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'
import Searchbox from '../../Common/Searchbox'

const styles = theme => ({
  progress: {
    margin: theme.spacing(5),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  content: {
    padding: 0,
  },
  dialogPaper: {
    height: '80%',
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.main,
  },
  searchBox: {
    paddingLeft: theme.spacing(2),
  },
})

export class AddRoleDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      errorMessage: null,
      roles: null,
      roleId: null,
      searchTerm: '',
    }
  }

  componentDidMount() {
    this.loadRoles()
  }

  isPrivateRole(role) {
    const user = this.context.userService.getActiveUser()
    if (role && user && user.userRoleMeta) {
      return user.userRoleMeta.getPrivateRole() === role.getId()
    }
    return false
  }

  render() {
    const { loading, errorMessage, roles, searchTerm, roleId } = this.state
    const { classes, t } = this.props

    let filteredRoles = roles && roles.length > 0 ? roles.filter(role => !searchTerm || searchTerm.trim() === '' || role.getName().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) : []

    return (
      <div>
        <Dialog classes={{ paper: classes.dialogPaper }} open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('add_role')}</DialogTitle>
          {roles && roles.length > 0 && <Searchbox className={classes.searchBox} value={searchTerm} onChange={value => this.setState({ searchTerm: value })} />}
          <DialogContent className={classes.content}>
            {loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} />
              </div>
            )}
            {!loading && (
              <List className={classes.list}>
                {filteredRoles &&
                  filteredRoles.map((role, index) => {
                    return (
                      !this.isPrivateRole(role) && (
                        <ListItem
                          button
                          disabled={this.props.addedRoleIds.indexOf(role.getId()) >= 0}
                          className={role && role.getId() === roleId ? [classes.listItem, classes.activeListItem].join(' ') : classes.listItem}
                          key={role.getId()}
                          value={role.getId()}
                          onClick={() => this.setState({ roleId: role.getId() })}>
                          {role.getName()}
                        </ListItem>
                      )
                    )
                  })}
                {!loading && filteredRoles && filteredRoles.length === 0 && (
                  <ListItem className={classes.listItem} key={'emptyListItem'}>
                    {roles && roles.length > 0 ? t('no_match_found') : t('no_entries_available')}
                  </ListItem>
                )}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button disabled={loading || roleId === null} onClick={() => this.handleSubmit()} color="primary">
              {t('add_role')}
            </Button>
          </DialogActions>
        </Dialog>
        {errorMessage && (
          <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
        )}
      </div>
    )
  }

  handleSubmit() {
    const { groupId, t } = this.props
    let roleId = this.state.roleId

    this.context.authzService
      .addRole(groupId, roleId)
      .then(group => {
        this.setState({ roleId: null, loading: false })
        this.props.onSuccess()
      })
      .catch(error => {
        console.warn('addRole error', error)
        this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  loadRoles() {
    this.context.authzService
      .listAccessableRolesOfCurrentUser()
      .then(roles => {
        this.readRoles(roles)
      })
      .catch(error => {
        console.warn('loadRoles error', error)
        this.setState({ roles: null, loading: false, error: error })
      })
  }

  readRoles(roles) {
    this.context.authzService
      .readMultipleRoles(roles)
      .then(roles => {
        roles = roles.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        this.setState({ roles, loading: false })
      })
      .catch(error => {
        console.warn('readRoles error', error)
        this.setState({ roles: null, loading: false, error: error })
      })
  }
}
AddRoleDialog.contextType = PageContext

AddRoleDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  addedRoleIds: PropTypes.array.isRequired,
}

export default withTranslation()(withStyles(styles)(AddRoleDialog))
