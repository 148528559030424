import React, { useContext, useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { TextField, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, InputLabel, FormControlLabel } from '@material-ui/core'
import clsx from 'clsx'
import { DRIVER_TYPE } from '../../m2m-cloud-api/Api/DeviceService/Models/Device'
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper'
import { ValidatorForm } from 'react-material-ui-form-validator'

const DEVICE_TYPES = Object.values(DRIVER_TYPE)
const DEFAULT_FORM_VALUES = { driver: 'all', type: '', owner: true, assigned: true }

const MonitoringDetailFilterDialog = ({ open, defaultValues, org, onCancel, onSubmit }) => {
  const context = useContext(PageContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const [orgs, setOrgs] = useState([])
  const [values, setValues] = useState(defaultValues || DEFAULT_FORM_VALUES)
  const form = useRef()

  useEffect(async () => {
    const orgItems = await context.orgService.searchOrg({ from: 0, size: 10000 })
    setOrgs(orgItems)
  }, [])

  const resetValues = () => {
    setValues(DEFAULT_FORM_VALUES)
    handleSubmit(DEFAULT_FORM_VALUES)
  }

  const handleSubmit = _values => {
    const cleanedValues = {}
    const vals = _values || values
    Object.keys(vals).forEach(key => {
      if (vals[key] !== null && vals[key] !== undefined && vals[key] !== '' && vals[key] !== 'all') {
        cleanedValues[key] = vals[key]
        if (vals['owner'] ? !vals['assigned'] : vals['assigned']) {
          cleanedValues[vals['owner'] ? 'ownerOrg' : 'assignedOrg'] = org.getId()
        }
        delete cleanedValues['owner']
        delete cleanedValues['assigned']
      }
    })
    onSubmit(Object.keys(cleanedValues).length > 0 ? cleanedValues : null)
  }

  return (
    <Dialog open={open} onClose={null} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('filter')}</DialogTitle>
      <DialogContent>
        <ValidatorForm ref={form} onSubmit={() => handleSubmit()} onError={errors => console.log('form error:', errors)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormField
                id={'driver'}
                disabled={false}
                fieldType={FIELD_TYPE.SELECT}
                value={values?.driver}
                renderRootNode={false}
                options={[{ value: 'all', title: t('all') }, ...DEVICE_TYPES.map(deviceType => ({ value: deviceType, title: deviceType.toUpperCase() }))]}
                title={t('driver')}
                validators={[]}
                errorMessages={[]}
                onChange={event => setValues(prevValues => ({ ...prevValues, ['driver']: event.target.value }))}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={clsx(classes.formItem, classes.formItemText)}
                fullWidth
                label={t('device_management_type')}
                value={values?.type}
                onChange={event => setValues(prevValues => ({ ...prevValues, ['type']: event.target.value }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={!values?.assigned}
                    checked={values?.owner ? true : false}
                    onChange={event => setValues(prevValues => ({ ...prevValues, ['owner']: event.target.checked }))}
                  />
                }
                label={t('owner')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={!values?.owner}
                    checked={values?.assigned ? true : false}
                    onChange={event => setValues(prevValues => ({ ...prevValues, ['assigned']: event.target.checked }))}
                  />
                }
                label={t('assigned')}
              />
            </Grid>
            {/*
                            <Grid item xs={6}>
                                <FormField
                                    id={'ownerOrg'}
                                    disabled={false}
                                    fieldType={FIELD_TYPE.ORG_SELECT}
                                    value={values.ownerOrg || ''}
                                    renderRootNode={false}
                                    options={orgs || []}
                                    defaultValue={t('please_select')}
                                    resetEnabled={true}
                                    title={t('owner')}
                                    validators={[]}
                                    errorMessages={[]}
                                    onChange={(value) => setValues((prevValues) => ({...prevValues, ['ownerOrg']: value}))} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormField
                                    id={'assignedOrg'}
                                    disabled={false}
                                    fieldType={FIELD_TYPE.ORG_SELECT}
                                    value={values.assignedOrg || ''}
                                    renderRootNode={false}
                                    options={orgs || []}
                                    defaultValue={t('please_select')}
                                    resetEnabled={true}
                                    title={t('assigned')}
                                    validators={[]}
                                    errorMessages={[]}
                                    onChange={(value) => setValues((prevValues) => ({...prevValues, ['assignedOrg']: value}))} />
                            </Grid>
                            */}
          </Grid>
        </ValidatorForm>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button onClick={resetValues} color="primary">
          {t('reset')}
        </Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  formItem: {
    marginBottom: theme.spacing(2),
  },
  formItemText: {
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}))

export default MonitoringDetailFilterDialog
