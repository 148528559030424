import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { IconButton, TableCell, Typography } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FlashIcon from '@material-ui/icons/FlashOn';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import LinkOnIcon from '@material-ui/icons/InsertLink';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { PageContext } from '../../../App';

function DeviceDynamicListCell({ data, entry, field, index, onSendCommand, onShowEventData, onMonitoringStatusEdit, onSetMenuAnchorEl, onSelectedDevice, onActivateDevice, onDeactivateDevice }) {
    const { t } = useTranslation();
    const context = useContext(PageContext);
    const classes = useStyles();
    const deviceRef = useRef(null);

    let fieldValue = entry.fieldMappedValues[index] || field || '-';
    const header = data?.headers[index];

    const onActionCall = useCallback(
        async (actionToCall, ...props) => {
            if (!deviceRef.current) {
                deviceRef.current = await context.deviceService.getDevice(entry.deviceId);
            }
            actionToCall(deviceRef.current, props);
        },
        [deviceRef.current],
    );

    if (header.selection === 'actions') {
        return (
            <TableCell align="right">
                <IconButton
                    size="small"
                    onClick={async event => {
                        onSetMenuAnchorEl(event.currentTarget);
                        onActionCall(onSelectedDevice);
                    }}>
                    <MoreVertIcon />
                </IconButton>
            </TableCell>
        );
    }

    if (header.selection.indexOf('actionbar') === 0) {
        const contentMatch = header.selection.match(/actionbar\[(.*)\]/);
        const onlyFunctions = contentMatch.length > 1 ? contentMatch[1].replaceAll(/\[.*\]/g, '') : '';
        const functionsArray = onlyFunctions.split(',');

        return (
            <TableCell align="right">
                <div className={classes.buttonContainer}>
                    {functionsArray.map(func => {
                        let title = '';
                        let Icon = null;
                        let onClick = null;

                        switch (func) {
                            case 'generic.showevents':
                                title = t('show_event_data');
                                Icon = FlashIcon;
                                onClick = event => onActionCall(onShowEventData);
                                break;
                            case 'monitoring.statuseditor':
                                title = t('monitoring_status');
                                Icon = EditIcon;
                                onClick = event => onActionCall(onMonitoringStatusEdit);
                                break;
                            case 'generic.sendcommand':
                                title = t('send_command');
                                Icon = SendIcon;
                                onClick = event => {
                                    let sendCommands = null;
                                    const sendCommandMatch = contentMatch[1].match(/generic\.sendcommand(\[.*\])/);
                                    if (sendCommandMatch?.length > 1) {
                                        try {
                                            sendCommands = JSON.parse(sendCommandMatch[1].replaceAll("'", '"'));
                                        } catch (error) {
                                            console.warn('actionbar parse error', error);
                                        }
                                    }
                                    onActionCall(onSendCommand, sendCommands);
                                };
                                break;
                            case 'generic.activate':
                                title = t('activate');
                                Icon = LinkOnIcon;
                                onClick = () => onActionCall(onActivateDevice);
                                break;
                            case 'generic.deactivate':
                                title = t('deactivate');
                                Icon = LinkOffIcon;
                                onClick = () => onActionCall(onDeactivateDevice);
                                break;
                            default:
                                break;
                        }
                        if (onClick === null) return null;
                        return (
                            <IconButton size="small" title={title} onClick={onClick}>
                                <Icon />
                            </IconButton>
                        );
                    })}
                </div>
            </TableCell>
        );
    }
    if (fieldValue === 'icon--check') {
        return (
            <TableCell>
                <CheckIcon />
            </TableCell>
        );
    } else if (fieldValue === 'icon--uncheck') {
        return (
            <TableCell>
                <BlockIcon />
            </TableCell>
        )
    }
    return (
        <TableCell>
            <Typography>
                <span className={classes[header.selection]}>{fieldValue}</span>
            </Typography>
        </TableCell>
    );
}

export default DeviceDynamicListCell;

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));
