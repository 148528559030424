import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, makeStyles, LinearProgress } from '@material-ui/core'
import PropList from './PropList'

const PropListDialog = ({ open, title, loading, errorMessage, deleteMode, submitButtonTitle, onCancel, onSubmit, progressProps, ...props }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const initialParams = props.params ? Object.keys(props.params).sort().map(key => ({ key, value: props.params[key] })) : [{ key: '', value: '' }]
    const [params, setParams] = useState(initialParams)

    return (
        <div>
            <Dialog open={open} onClose={onCancel} fullWidth maxWidth={deleteMode ? 'sm' : 'md'}>
                {loading && <LinearProgress className={classes.progress} {...progressProps} />}
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <PropList params={params} onChange={setParams} />
                    {errorMessage && (
                        <DialogContentText style={{ marginTop: 20 }} color="error">
                            {errorMessage}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onCancel}>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            let _params = {}
                            params.map(({ key, value }) => {
                                if (key?.trim() !== '') {
                                    _params[key] = value
                                }
                            })
                            onSubmit(_params)
                        }}
                        color="primary">
                        {deleteMode ? t('delete') : submitButtonTitle || t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 99999,
        left: 0,
    },
}));

export default PropListDialog
