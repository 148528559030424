import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  logo: {
    width: 432,
    maxWidth: '90%',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 280,
      height: 'auto',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  logoCore: {
    width: 240,
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
  },
}))

function Logo({}) {
  const classes = useStyles()
  return <img className={[classes.logo, classes.logoCore].join(' ')} src={'/assets/logo-core-ui.png'} />
}

export default Logo
