import React, { useCallback, useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import { PARAM_MFA } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import clsx from 'clsx'
import QRCode from 'react-qr-code'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'
import AlertDialog from '../../Common/AlertDialog'
import RecoveryCodesList from './RecoveryCodesList'

const InstallTwoFactorAuthDialog = ({ open, onCancel, userService }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const user = userService.getActiveUser()
  const [stepTwoVisible, setStepTwoVisible] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const installAuthFactor = useCallback(async code => {
    try {
      if (code) {
        const value = await userService.installAuthFactor('totp', code)
        if (value?.params?.ack) {
          userService.putParam(user.getUserId(), PARAM_MFA, true)
          setCanContinue(true)
        }
      } else {
        setCanContinue(false)
      }
    } catch (error) {
      setCanContinue(false)
      setErrorMessage(mapErrorMessage(error))
      console.log('installAuthFactor error... ', errorMessage)
    }
  }, [])

  return (
    <Fragment>
      <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'sm'} aria-labelledby="tfa-enable-dialog">
        <Box display={'flex'} alignItems={'center'}>
          <DialogTitle className={classes.dialogTitle} id="tfa-title">
            {t('multi_factor_authentication')}
          </DialogTitle>
          <DialogTitle id="tfa-title-step">{`${t('step')} ${stepTwoVisible ? '2 / 2' : '1 / 2'}`}</DialogTitle>
        </Box>
        <DialogContent className={classes.dialogContent}>
          {!stepTwoVisible && <StepOneContent t={t} onContinue={installAuthFactor} userService={userService} />}
          {stepTwoVisible && <StepTwoContent t={t} userService={userService} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t('close')}</Button>
          {!stepTwoVisible && (
            <Button onClick={() => canContinue && setStepTwoVisible(true)} disabled={!canContinue} type="submit" className={clsx(classes.enableButton, !canContinue && 'disabled')}>
              {t('continue')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
    </Fragment>
  )
}

const StepOneContent = ({ t, onContinue, userService }) => {
  const [verifyCode, setVerifyCode] = useState(null)
  const classes = useStyles()
  const [qrValue, setQrValue] = useState(null)

  const requestAuthFactor = useCallback(async () => {
    try {
      const value = await userService.requestAuthFactor('totp')
      setQrValue(value && value?.params['url.google'] ? value.params['url.google'] : null)
    } catch (error) {
      console.log('requestAuthFactor error... ', error)
    }
  }, [])

  useEffect(() => {
    requestAuthFactor()
  }, [])

  useEffect(() => {
    verifyCode?.length === 6 ? onContinue(verifyCode) : onContinue(false)
  }, [verifyCode])
  return (
    <Box>
      <DialogContentText color={'textPrimary'}>{t('install_multi_factor_auth_step_1')}</DialogContentText>
      <Box marginTop={3} display={'flex'}>
        <Box className={classes.qrCodeWrapper}>{qrValue && <QRCode value={qrValue} size={120} />}</Box>
        <TextField
          value={verifyCode}
          onChange={event => event?.target?.value.length <= 6 && setVerifyCode(event?.target?.value)}
          id={'verify-code'}
          label={t('verification_code')}
          helperText={t('6_digit_code')}
          type={'number'}
          className={classes.verifyCodeTextField}
          fullWidth
        />
      </Box>
    </Box>
  )
}
const StepTwoContent = ({ t, userService }) => {
  const classes = useStyles()
  const [recoveryCodes, setRecoveryCodes] = useState(null)

  const requestAuthFactorRecovery = useCallback(async () => {
    try {
      await userService.requestAuthFactor('recovery')
      const value = await userService.installAuthFactor('recovery')
      if (value?.params?.codes) {
        setRecoveryCodes(value?.params?.codes.split(','))
      }
    } catch (error) {
      console.log('requestAuthFactorRecovery error... ', error)
    }
  }, [])

  useEffect(() => {
    requestAuthFactorRecovery()
  }, [])

  return (
    <Box>
      <DialogContentText color={'textPrimary'}>{t('install_multi_factor_auth_step_2')}</DialogContentText>
      <RecoveryCodesList recoveryCodes={recoveryCodes} />
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  dialogTitle: {
    flex: 1,
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
  },
  enableButton: {
    backgroundColor: theme.palette.primary.main,
    '&.disabled': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  verifyCodeTextField: {
    flex: 1,
    marginLeft: theme.spacing(3),
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  recoveryCodesWrapper: {
    backgroundColor: theme.palette.common.white,
    minHeight: 150,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 12,
  },
  downloadRecoveryWrapper: {
    marginTop: 12,
    cursor: 'pointer',
    '& svg': {
      marginRight: 12,
    },
  },
  qrCodeWrapper: {
    backgroundColor: theme.palette.common.white,
    width: 150,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default InstallTwoFactorAuthDialog
