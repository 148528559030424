import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SETTINGS_CODE_TOKEN, DEFAULT_EVENT_NAME, CODE_DEVICE_DRIVER_NAME } from '../../../m2m-cloud-api/Api/Drivers/CodeDriver';
import { PageContext } from '../../../App';

function DeviceDynamicListMenu({
    onActivate,
    onDeactivateDevice,
    onAssign,
    onUnassign,
    onSendCommand,
    onTriggerEvent,
    onShowEventData,
    onEditSettings,
    onUnclaim,
    selectedDevice,
    onSelectedDevice,
    onShowAssignmentsData,
    menuAnchorEl,
    onSetMenuAnchorEl,
    onShowActivationsData
}) {
    const { t } = useTranslation();
    const context = useContext(PageContext)

    const buildTriggerUrl = useCallback(device => {
        const physicalId = device.physicalId;
        const code = physicalId.substring(physicalId.indexOf(':') + 1);
        const nameSpace = physicalId.substring(0, physicalId.indexOf(':'));
        const token = device.getSetting(SETTINGS_CODE_TOKEN);

        const url = context.deviceService.codeDriver.buildEventGetUrl(nameSpace, code, DEFAULT_EVENT_NAME, token, '1');
        return url;
    }, [])

    return (
        <Menu id="menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => onSetMenuAnchorEl(null)}>

            {onShowEventData && (
                <MenuItem
                    disabled={!selectedDevice.getAssignedOrg()}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onShowEventData(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('show_event_data')}</Typography>
                </MenuItem>
            )}
            {onShowActivationsData && (
                <MenuItem
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onShowActivationsData(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('device_activations')}</Typography>
                </MenuItem>
            )}
            {onShowAssignmentsData && (
                <MenuItem
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onShowAssignmentsData(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('device_assignments')}</Typography>
                </MenuItem>
            )}
            {onSendCommand && (
                <MenuItem
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onSendCommand(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('send_command')}</Typography>
                </MenuItem>
            )}
            {onEditSettings && (
                <MenuItem
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onEditSettings(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('edit_settings')}</Typography>
                </MenuItem>
            )}
            {selectedDevice && (
                <CopyToClipboard text={selectedDevice.getId()}>
                    <MenuItem
                        onClick={event => {
                            onSetMenuAnchorEl(null);
                            onSelectedDevice(null);
                        }}>
                        <Typography variant="inherit"> {t('copy_device_id_to_clipboard')}</Typography>
                    </MenuItem>
                </CopyToClipboard>
            )}
            {onTriggerEvent && selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME && (
                <MenuItem
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onTriggerEvent(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('trigger_event')}</Typography>
                </MenuItem>
            )}
            {onTriggerEvent && selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME && (
                <CopyToClipboard text={buildTriggerUrl(selectedDevice)}>
                    <MenuItem
                        disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                        onClick={event => {
                            onSetMenuAnchorEl(null);
                            onSelectedDevice(null);
                        }}>
                        <Typography variant="inherit"> {t('copy_event_trigger_link')}</Typography>
                    </MenuItem>
                </CopyToClipboard>
            )}
            {onActivate && (
                <MenuItem
                    disabled={!(selectedDevice.getAssignedOrg() && !selectedDevice.isActivated())}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onActivate(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('activate')}</Typography>
                </MenuItem>
            )}
            {onDeactivateDevice && (
                <MenuItem
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onDeactivateDevice(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('deactivate')}</Typography>
                </MenuItem>
            )}
            {onAssign && !selectedDevice.getAssignedOrg() && (
                <MenuItem
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onAssign(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('assign')}</Typography>
                </MenuItem>
            )}
            {onUnassign && (
                <MenuItem
                    disabled={!(selectedDevice.getAssignedOrg() && !selectedDevice.isActivated())}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onSelectedDevice(null);
                        onUnassign(selectedDevice);
                    }}>
                    <Typography variant="inherit"> {t('unassign')}</Typography>
                </MenuItem>
            )}
            {onUnclaim && (
                <MenuItem
                    disabled={selectedDevice.getAssignedOrg()}
                    onClick={event => {
                        onSetMenuAnchorEl(null);
                        onUnclaim(selectedDevice);
                        onSelectedDevice(null);
                    }}>
                    <Typography variant="inherit"> {t('unclaim_device')}</Typography>
                </MenuItem>
            )}
        </Menu>
    );
}

export default DeviceDynamicListMenu;