import { prepareTemplateContent } from '../m2m-cloud-api/Api/Helper';
import FileSaver from 'file-saver';
import toCsv from 'react-csv-downloader/dist/lib/csv';

const prepareTextValue = text => {
    if (text && text !== '') {
        return `"${text}"`;
    }
    return '';
};

export const csvExportDefinitions = (definitions, templates, actions, devices, fileName = null) => {
    let columns = [
        {
            id: 'definitionId',
            displayName: prepareTextValue('Definition-ID'),
        },
        {
            id: 'templateName',
            displayName: prepareTextValue('Template-Name'),
        },
        {
            id: 'actionName',
            displayName: prepareTextValue('Action-Name'),
        },
        {
            id: 'definitionText',
            displayName: prepareTextValue('Definition-Text'),
        },
        {
            id: 'deviceDriver',
            displayName: prepareTextValue('Device-Driver'),
        },
        {
            id: 'devicePhysicalId',
            displayName: prepareTextValue('Device-PhysicalId'),
        },
    ];

    let datas = [];
    let customColumns = [];
    definitions.map(definition => {
        const template = templates && templates.find(template => template.getId() === definition.getTemplateId());
        const action = actions && actions.find(action => action.getId() === definition.getActionId());
        const device = devices && devices.find(device => device.getId() === definition.getDeviceId());

        const physicalId = device ? device.getPhysicalId() : '-';
        const driver = device ? device.getDriver() : '-';
        const definitionText = template && action && device ? prepareTemplateContent(template, definition, device, null) : null;

        const data = {
            definitionId: prepareTextValue(definition.getId()),
            templateName: prepareTextValue(template.getName()),
            actionName: prepareTextValue(action.getName()),
            definitionText: definitionText && prepareTextValue(definitionText.header),
            deviceDriver: prepareTextValue(driver),
            devicePhysicalId: prepareTextValue(physicalId),
        };

        const headerAndBody = template ? `${template.getHeader()} ${template.getBody()}` : '';
        const matchedVariables = headerAndBody.match(/(\$def:[a-zA-ZÀ-ÿ0-9-_]+)/g);
        if (matchedVariables && matchedVariables.length > 0) {
            matchedVariables.map(variable => {
                const variableName = variable.substr(5);
                const title = `${variableName}`;
                const hasColumn = customColumns.findIndex(column => column.id === variableName) >= 0;
                if (!hasColumn) {
                    customColumns.push({ id: variableName, displayName: prepareTextValue(title) });
                }
                data[variableName] = prepareTextValue(definition.getParam(variableName));
            });
        }

        datas.push(data);
    });

    datas = datas.sort((a, b) => a.actionName.localeCompare(b.actionName) || a.templateName.localeCompare(b.templateName));
    customColumns.sort((a, b) => a.id.localeCompare(b.id));
    columns = [...columns, ...customColumns];
    exportCsv(fileName || 'Export.csv', columns, datas);
};


function exportCsv(filename, columns, datas, separator = ';', noHeader = false, bom = true) {
    const csv = toCsv(columns, datas, separator, noHeader);

    if (filename.indexOf('.csv') < 0) {
        filename += '.csv';
    }

    downloadCsvFile(filename, csv, bom);
}

export function downloadCsvFile(filename, csvString, bom = true) {
    const bomCode = bom ? '\ufeff' : '';
    const blob = new Blob([`${bomCode}${csvString}`], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, filename);
}
