import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, ListItem, ListItemText, Typography, DialogContentText, LinearProgress } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Virtuoso } from 'react-virtuoso'
import { ItemContainerNoPadding } from './ListContainer'
import Searchbox from './Searchbox'
import i18n from '../../i18n'

const ScheduleSelectionDialog = ({ open, cronString, apiErrorMessage, loading, onCancel, onSuccess }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const items = [
    { name: t('hourly'), value: '0 0 0/1 * * ?' },
    { name: t('every_2_hours'), value: '0 0 0/2 * * ?' },
    { name: t('every_3_hours_between_8_am_and_6_pm'), value: '0 0 8-18/3 * * ?' },
    { name: t('first_monday_in_month'), value: '0 30 8 ? 1-12 1#1' },
    { name: t('friday_at_3_pm'), value: '0 0 15 ? * 5' },
  ]

  const [errorMessage, setErrorMessage] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [values, setValues] = useState({ sec: '', min: '', hour: '', days: '', months: '', weekday: '' })

  useEffect(() => {
    if (cronString) {
      const pieces = cronString.split(' ')
      if (pieces.length >= 6) {
        const _values = {}
        _values.sec = pieces[0]
        _values.min = pieces[1]
        _values.hour = pieces[2]
        _values.days = pieces[3]
        _values.months = pieces[4]
        _values.weekday = pieces[5]
        setValues(_values)
      }
    }
  }, [cronString])

  useEffect(() => {
    if (!open) {
      clearSchedule()
    }
  }, [open])

  const _handleCronExpiression = async () => {
    const cronExpression = values.sec + ' ' + values.min + ' ' + values.hour + ' ' + values.days + ' ' + values.months + ' ' + values.weekday
    //const isCronValid = CronValidator.isValidCronExpression(cronExpression)
    //if (isCronValid) {
    return cronExpression
    //}
    setErrorMessage(i18n.t('invalid_cron_expression'))
    return null
  }

  const handleSubmit = async () => {
    const cronExpression = await _handleCronExpiression()
    if (/*cronExpression &&*/ !errorMessage) {
      onSuccess(cronExpression)
    }
  }

  const cancelSchedule = async () => {
    onCancel()
  }

  const clearSchedule = async () => {
    setErrorMessage(null)
    setValues({ sec: '', min: '', hour: '', days: '', months: '', weekday: '' })
  }

  const onItemSelect = index => {
    const value = items[index].value
    const splitFields = value.split(' ')
    setValues({ sec: splitFields[0], min: splitFields[1], hour: splitFields[2], days: splitFields[3], months: splitFields[4], weekday: splitFields[5] })
  }

  const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
        {loading && <LinearProgress />}
        <div className={classes.dialogTitleContainer}>
          <DialogTitle id="form-dialog-title">
            <div>{cronString ? t('edit_schedule') : t('schedule')}</div>
          </DialogTitle>
          <div className={classes.searchContainer} style={loading ? { pointerEvents: 'none' } : null}>
            <Searchbox value={searchTerm} onChange={value => setSearchTerm(value)} />
          </div>
        </div>
        <Divider />
        <DialogContent className={classes.dialogContent} style={loading ? { pointerEvents: 'none' } : null}>
          <Paper className={classes.listPaper}>
            {items && items.length === 0 && (
              <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                {t('no_entries_available')}
              </Typography>
            )}
            {items && items.length > 0 && filteredItems.length === 0 && (
              <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">
                {t('no_match_found')}
              </Typography>
            )}
            {filteredItems && filteredItems.length > 0 && (
              <Virtuoso
                components={{
                  Item: ItemContainerNoPadding,
                }}
                style={{ width: '100%', flex: 1, height: '100%', listStyle: 'none', paddingTop: 0, paddingBottom: 0 }}
                totalCount={(filteredItems && filteredItems.length) || 0}
                itemContent={index => (
                  <ListItem button className={classes.listItem} onClick={() => onItemSelect(index)}>
                    <ListItemText className={classes.listItemText} primary={filteredItems[index].name} />
                  </ListItem>
                )}></Virtuoso>
            )}
          </Paper>
        </DialogContent>
        <Divider />
        {
          <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log('form error:', errors)}>
            <div className={classes.paramsContainer}>
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.sec}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, sec: event.target.value })
                }}
                margin="dense"
                label={t('second')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.min}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, min: event.target.value.trim() })
                }}
                margin="dense"
                label={t('minute')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.hour}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, hour: event.target.value.trim() })
                }}
                margin="dense"
                label={t('hour')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.days}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, days: event.target.value.trim() })
                }}
                margin="dense"
                label={t('days')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.months}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, months: event.target.value.trim() })
                }}
                margin="dense"
                label={t('months')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
              <TextValidator
                className={classes.formControlRight}
                disabled={loading}
                value={values.weekday}
                onChange={async event => {
                  setErrorMessage(null)
                  setValues({ ...values, weekday: event.target.value.trim() })
                }}
                margin="dense"
                label={t('weekday')}
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
              />
            </div>
            {(errorMessage || apiErrorMessage) && <DialogContentText className={classes.errorText}>{errorMessage || apiErrorMessage}</DialogContentText>}
            <DialogActions>
              <Button disabled={loading} onClick={cancelSchedule}>
                {t('cancel')}
              </Button>
              <Button disabled={loading} type="submit" color="primary">
                {t('select')}
              </Button>
            </DialogActions>
          </ValidatorForm>
        }
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
  },
  dialogContent: {
    padding: 0,
    height: 440,
  },
  listPaper: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    boxShadow: 'none',
    overflow: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    maringRight: theme.spacing(1),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  paramsContainer: {
    flexDirection: 'row',
    display: 'flex',
    margin: theme.spacing(1.5),
  },
  formControlRight: {
    flex: 0.5,
    marginLeft: theme.spacing(1),
  },
}))

export default ScheduleSelectionDialog
