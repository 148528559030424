import { PageContext } from '../../../Context/PageProvider';
import { useContext, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CORE_DEVICE_REPORT_SUMMARY_EVENTS, CORE_DEVICE_REPORT_SUMMARY_DEVICE_SEARCH_FILTER, CORE_DEVICE_REPORT_PARAM_FILTER } from '../../../m2m-cloud-api/MessageLog/Contants';
import { PARAM_MONITORING_AREAS } from '../../../m2m-cloud-api/Api/OrgService/Models/Org';
import { PARAM_MONITORING_AREA_FILTER } from '../../../m2m-cloud-api/Api/UserService/Models/User';
import { buildOrgTreeFromParentOrg, buildParentToChildOrgTreeFromChildOrg } from '../../../m2m-cloud-api/Api/Helper';
import moment from 'moment';
import { mapItemParams } from './mapItemParams';

export function useMonitoringSummary(orgItems, reportOrg, selectedOrg, onDownloadData) {
    const context = useContext(PageContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const bulkDataFetchIndex = useRef(0);
    const searchAfterHistory = useMemo(() => new Map(), [])

    const filteredData = useMemo(() => {
        let _filteredData = [...(data || [])].map(item => {
            return {
                count: item.count,
                event: item.evt,
                org: item.org,
                orgId: item.orgId,
                params: item.params,
                tsMin: item.tsMin,
                tsMax: item.tsMax,
            };
        });
        if (searchTerm?.trim()) {
            _filteredData = _filteredData.filter(data => {
                const searchValue = `${data.count} ${data.event} ${data.org} ${mapItemParams(data).join(',')} ${data.timeRange}`;
                return searchValue.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
            });
        }
        return _filteredData;
    }, [searchTerm, data]);

    const filteredTreeData = useMemo(() => {
        return buildGroupedMonitoringData(orgItems, selectedOrg, filteredData);
    }, [orgItems, selectedOrg, filteredData]);

    const loadDevices = useCallback(
        async orgIgs => {
            let params = { assignedOrg: orgIgs };
            const deviceSearchFilter = reportOrg?.getParam(CORE_DEVICE_REPORT_SUMMARY_DEVICE_SEARCH_FILTER);
            const deviceSearchFilterJson = JSON.parse(deviceSearchFilter);
            params = { ...params, ...deviceSearchFilterJson };

            const reportSummaryEvents = reportOrg?.getParam(CORE_DEVICE_REPORT_SUMMARY_EVENTS);
            if (reportSummaryEvents) {
                let items = [];
                let size = 10000;
                let result = params && (await context.deviceService.searchDevices(params, null, size));
                searchAfterHistory.set(0, result.searchAfter)
                items = [...items, ...result.items];
                const currentPage = result.offset + result.size
                while (currentPage < result.count) {
                    result = params && (await context.deviceService.searchDevices(params, searchAfterHistory.get(currentPage - 1), size));
                    items = [...items, ...result.items];
                    searchAfterHistory.set(currentPage, result.searchAfter)
                }
                return items;
            }
            return null;
        },
        [context.deviceService, reportOrg],
    );

    const loadOrgData = useCallback(
        async (org, deviceIds, events, filter) => {
            const summaryReports = events && (await context.deviceService.getMonitoringSummaryReport(deviceIds, events, filter));
            return { org, summaryReports };
        },
        [context.deviceService],
    );

    const handleSummaryReport = useCallback(async () => {
        try {
            setLoading(true);
            bulkDataFetchIndex.current += 1;
            const curerntBulkDataFetchIndex = bulkDataFetchIndex.current;
            const reportSummaryEvents = reportOrg?.getParam(CORE_DEVICE_REPORT_SUMMARY_EVENTS);
            let reportSummaryParamFilter = null;
            const reportSummaryParamFilterString = reportOrg?.getParam(CORE_DEVICE_REPORT_PARAM_FILTER);
            if (reportSummaryParamFilterString && reportSummaryParamFilterString.trim() !== '') {
                try {
                    reportSummaryParamFilter = JSON.parse(reportSummaryParamFilterString);
                } catch (error) {
                    console.warn("can't parse filter json: ", reportSummaryParamFilterString, 'error: ', error);
                }
            }
            if (reportSummaryEvents) {
                const user = context.userService.getActiveUser();
                const allOrgs = buildOrgTreeFromParentOrg(orgItems, selectedOrg);
                let orgs = [];
                const monitoringAreaFilter = user.getParam(PARAM_MONITORING_AREA_FILTER)?.split(',');
                if (monitoringAreaFilter) {
                    orgs = allOrgs.filter(currentOrg => {
                        const orgItemsTree = buildParentToChildOrgTreeFromChildOrg(orgItems, currentOrg);
                        for (const org of orgItemsTree) {
                            const orgMonitoringAreas = org.getParam(PARAM_MONITORING_AREAS)?.split(',') || [];
                            for (let i = 0; i < orgMonitoringAreas.length; i++) {
                                const orgMonitoringArea = orgMonitoringAreas[i];
                                if (monitoringAreaFilter.includes(orgMonitoringArea)) return true;
                            }
                        }
                        return false;
                    });
                }
                const devices = await loadDevices(orgs.map(org => org.getId()));
                const events = reportSummaryEvents?.split(',');
                const countParallelRequests = 3;
                for (let i = 0; i < orgs.length; i = i + countParallelRequests) {
                    const promises = [];
                    for (let ii = i; ii < Math.min(i + countParallelRequests, orgs.length); ii++) {
                        const orgItem = orgs[ii];
                        const deviceIds = devices.filter(device => device.getAssignedOrg() === orgItem.getId()).map(org => org.getId());
                        if (deviceIds?.length > 0) {
                            promises.push(loadOrgData(orgItem, deviceIds, events, reportSummaryParamFilter));
                        }
                    }

                    if (promises.length > 0) {
                        let summaryReports = [];
                        const results = await Promise.all(promises);
                        for (let ii = 0; ii < results.length; ii++) {
                            const result = results[ii];
                            if (result && result.org && result.summaryReports?.length > 0) {
                                let reports = result.summaryReports.map(summaryReport => ({ ...summaryReport, org: result.org?.getName(), orgId: result.org?.getId() }));
                                summaryReports = [...summaryReports, ...reports];
                            }
                        }

                        if (bulkDataFetchIndex.current === curerntBulkDataFetchIndex) {
                            setData(prevState => [...(prevState || []), ...summaryReports]);
                        }
                    }
                }
            } else {
                setData(null);
            }
            if (bulkDataFetchIndex.current === curerntBulkDataFetchIndex) {
                setLoading(false);
            }
        } catch (error) {
            console.log('Report org error... ', error);
            setLoading(false);
            setErrorMessage(error.message);
        }
    }, [context.userService, loadDevices, loadOrgData, orgItems, reportOrg, selectedOrg]);

    useEffect(() => {
        setData(null);
        if (reportOrg && selectedOrg) {
            handleSummaryReport();
        }
    }, [reportOrg, selectedOrg, handleSummaryReport]);

    const downloadData = useCallback(() => {
        try {
            const exportData = [];
            for (let i = 0; i < filteredData.length; i++) {
                const data = filteredData[i];
                exportData.push({
                    [t('org')]: data.org,
                    [t('time_range')]: `${moment(data.tsMin).format('YYYY-MM-DD HH:mm:ss')} / ${moment(data.tsMax).format('YYYY-MM-DD HH:mm:ss')}`,
                    [t('count')]: data.count,
                    [t('event')]: data.event,
                    [t('data')]: mapItemParams(data).join(','),
                });
            }
            const fields = [t('org'), t('time_range'), t('count'), t('event'), t('data')];
            onDownloadData(fields, exportData);
        } catch (error) {
            setErrorMessage(error.message);
            console.log('Export Orgs Error:', error);
        }
    }, [filteredData]);

    return {
        loading,
        hasData: !!data?.length,
        filteredTreeData,
        errorMessage,
        searchTerm,
        setSearchTerm,
        menuAnchorEl,
        setMenuAnchorEl,
        downloadData,
        setErrorMessage
    };
}

function buildGroupedMonitoringData(orgItems, org, originMonitoringData) {
    const orgsFlatten = buildOrgTreeFromParentOrg(orgItems, org);
    const orgsTree = createTree(orgsFlatten, originMonitoringData);
    return orgsTree;
}

function createTree(orgs, originMonitoringData) {
    if (!originMonitoringData) return [];
    // create a map to process date efficient
    const map = new Map();

    const mapParams = params => {
        const newParams = {};
        const keys = Object.keys(params);
        keys.forEach(key => {
            newParams[key] = [params[key]];
        });
        return newParams;
    };

    // fill map with data and use id as key
    orgs.forEach(org => {
        map.set(org.getId(), {
            orgId: org.getId(),
            parentOrgId: org.getParentId(),
            org: org.getName(),
            count: 0,
            events: [],
            params: {
                data0: [],
            },
            tsMin: 0,
            tsMax: 0,
            children: [],
            data: originMonitoringData.filter(d => d.orgId === org.getId()).map(({ event, params, ...props }) => ({ ...props, events: [event], params: mapParams(params) })),
        });
    });

    // if a single data item exists in an org, the data is added to the parent org.
    map.forEach((item, orgId, map) => {
        if (item.data.length === 1) {
            const parentItem = map.get(item.parentOrgId);
            if (parentItem) {
                parentItem.data = [...parentItem.data, ...item.data];
                item.data = [];
            }
        }
    });

    // build tree
    orgs.forEach(org => {
        const parent = map.get(org.getParentId());
        if (parent) {
            parent.children.push(map.get(org.getId()));
        }
    });

    // fetch top org
    const topOrg = orgs.find(o => !map.get(o.getParentId()));

    // traverse tree from child to parent
    const topOrgData = map.get(topOrg.getId());
    traverseTreeFromChildToParent(topOrgData, null, (item, parentItem) => {
        item.data.forEach(data => {
            applyDataFromChildToParent(item, data);
        });
        if (parentItem) {
            applyDataFromChildToParent(parentItem, item);
        }
        item.children = item.children.filter(i => i.children.length > 0 || i.data.length);
    });

    return topOrgData.children.length || topOrgData.data.length > 0 ? [topOrgData] : [];
}

function traverseTreeFromChildToParent(item, parentItem, callback) {
    if (!item) return;

    if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
            traverseTreeFromChildToParent(child, item, callback);
        });
    }
    callback(item, parentItem);
}

function applyDataFromChildToParent(parentItem, childItem) {
    parentItem.count += childItem.count;
    if (parentItem.tsMin === 0 || (childItem.tsMin > 0 && childItem.tsMin < parentItem.tsMin)) {
        parentItem.tsMin = childItem.tsMin;
    }
    if (parentItem.tsMax === 0 || childItem.tsMax > parentItem.tsMax) {
        parentItem.tsMax = childItem.tsMax;
    }
    childItem.events.forEach(event => {
        if (!parentItem.events.includes(event)) {
            parentItem.events.push(event);
        }
    });
    childItem.params?.data0?.forEach(data0 => {
        if (!parentItem.params.data0.includes(data0)) {
            parentItem.params.data0.push(data0);
        }
    });
}
