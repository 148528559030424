import React from 'react'
import PropTypes from 'prop-types'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import ChipInput from 'material-ui-chip-input'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import { PARAM_FORM_FIELDS } from '../../m2m-cloud-api/Api/OrgService/Models/Org'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper'

class OrgDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      params: this.getDefaultValues(),
      tags: (props.isEdit && props.org && props.org.getTags()) || [],
      vis: (props.isEdit && props.org && props.org.getVis()) || [],
      loading: false,
      errorMessage: null,
      parentOrgId: props.isEdit && props.org ? props.org.getParentId() : props.org ? props.org.getId() : null,
      orgItems: null,
    }
  }

  componentWillMount() {
    this.queryAllOrgs()
  }

  queryAllOrgs() {
    this.context.orgService.searchOrg({ from: 0, size: 10000 }).then(items => {
      this.setState({ orgItems: items })
    })
  }

  getDefaultValues() {
    const { org, isEdit } = this.props
    let params = {}
    if (isEdit && org) {
      PARAM_FORM_FIELDS.map(field => {
        params[field.id] = org.getParam(field.id)
      })
    }
    return params
  }

  buildApiOrgUpdateRequests(orgId) {
    const { params, vis } = this.state
    const { org, isEdit } = this.props

    const promisses = []
    if (isEdit) {
      // handle params
      PARAM_FORM_FIELDS.map(field => {
        promisses.push(this.context.orgService.putParam(orgId, field.id, params[field.id]))
      })
      // handle deleted vis items
      if (org.getVis().length > 0) {
        org.getVis().forEach((existingVis, index) => {
          if (!vis.includes(existingVis)) {
            promisses.push(this.context.orgService.deleteVis(orgId, existingVis))
          }
        })
      }
      // handle new added vis items
      vis.forEach((currentVis, index) => {
        if (!org.getVis().includes(currentVis)) {
          promisses.push(this.context.orgService.putVis(orgId, currentVis))
        }
      })
      // handle tags
      if (JSON.stringify(this.state.tags) !== JSON.stringify(org.getTags())) {
        promisses.push(this.context.orgService.setTags(orgId, this.state.tags))
      }
    } else {
      // add all new added vis items
      vis.forEach((currentVis, index) => {
        promisses.push(this.context.orgService.putVis(orgId, currentVis))
      })
    }
    return promisses
  }

  async handleSubmit() {
    const { parentOrgId } = this.state
    const { t, org, isEdit } = this.props

    this.setState({ loading: true, errorMessage: null })

    try {
      if (isEdit) {
        try {
          await this.context.orgService.canWriteOrg(org.getId())
        } catch (error) {
          this.setState({
            errorMessage: t('error_no_write_permissions'),
            loading: false,
          })
          return
        }
        await this.context.orgService.updateOrg(org.getId(), this.buildApiOrgUpdateRequests(org.getId()))
      } else {
        const createdOrg = await this.context.orgService.create(parentOrgId === 'root-node' ? null : parentOrgId, this.state.params, this.state.tags)
        const updateRequests = this.buildApiOrgUpdateRequests(createdOrg.getId())
        if (updateRequests.length > 0) {
          await this.context.orgService.updateOrg(createdOrg.getId(), updateRequests)
        }
      }
      this.props.onSuccess()
      this.setState({ loading: false })
    } catch (error) {
      this.setState({
        errorMessage: t(mapErrorMessage(error)),
        loading: false,
      })
    }
  }

  render() {
    const { orgItems, params, tags, vis, loading, errorMessage } = this.state
    const { t, org, classes, isEdit } = this.props

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{isEdit ? t('edit_org') : t('create_org')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              {!isEdit && (
                <FormField
                  id={'org'}
                  disabled={isEdit}
                  fieldType={FIELD_TYPE.ORG_SELECT}
                  value={this.state.parentOrgId}
                  options={orgItems}
                  title={t('parent_org')}
                  validators={['required']}
                  errorMessages={['this_field_is_required']}
                  OrgListViewProps={{
                    renderRootNode: true,
                  }}
                  onChange={event => {
                    this.setState({ parentOrgId: event })
                  }}
                />
              )}

              {PARAM_FORM_FIELDS.map(field => {
                const value = params[field.id]
                return (
                  <TextValidator
                    className={classes.field}
                    key={field.id}
                    value={value}
                    onChange={event => {
                      let _params = params
                      _params[field.id] = event.target.value
                      this.setState({ params: _params, errorMessage: null })
                    }}
                    margin="dense"
                    id={field.id}
                    multiline={field.type === 'Text' ? true : false}
                    label={t(field.title)}
                    validators={field.validators}
                    errorMessages={field.errorMessages.map(message => t(message))}
                    fullWidth
                  />
                )
              })}
              <ChipInput
                fullWidth
                className={classes.field}
                label={t('tags')}
                blurBehavior={'add'}
                value={tags}
                onAdd={chip => this.setState({ tags: [...this.state.tags, chip] })}
                onDelete={(chip, index) => this.setState({ tags: this.state.tags.filter((tag, i) => index !== i) })}
              />
              <ChipInput
                fullWidth
                className={classes.field}
                label={t('visibility')}
                blurBehavior={'add'}
                value={vis}
                onAdd={chip => this.setState({ vis: [...this.state.vis, chip] })}
                onDelete={(chip, index) => this.setState({ vis: this.state.vis.filter((tag, i) => index !== i) })}
              />
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel}>
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {t('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

OrgDialog.contextType = PageContext

OrgDialog.propTypes = {
  open: PropTypes.bool,
  parentOrg: PropTypes.any,
  org: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
})

export default withTranslation()(withStyles(styles)(OrgDialog))
