import React, { forwardRef, useMemo } from 'react'
import Table from 'material-table'

import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NextPageIcon from '@material-ui/icons/NavigateNext'
import PreviousPageIcon from '@material-ui/icons/NavigateBefore'

const TABLE_ICONS = {
    FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <PreviousPageIcon {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <NextPageIcon {...props} ref={ref} />),
}

const MaterialTable = ({ localization, options = {}, columns, data }) => {
    const tableStyle = useMemo(() => ({ boxShadow: 'none' }), [])
    const tableHeaderStyle = useMemo(() => ({ backgroundColor: 'transparent' }), [])

    return (
        <Table
            title={null}
            style={tableStyle}
            localization={localization}
            icons={TABLE_ICONS}
            options={{
                toolbar: false,
                selection: false,
                paging: false,
                emptyRowsWhenPaging: false,
                draggable: false,
                sorting: false,
                headerStyle: tableHeaderStyle,
                ...options
            }}
            columns={columns}
            data={data}
        />
    )
}

export default MaterialTable
