import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, List, ListItem, TextField, IconButton } from '@material-ui/core'
import MultilineTextfield from '../Form/MultilineTextfield'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const VALUE_TYPE = {
  key: 'key',
  value: 'value',
}

const PropList = ({ params, onChange, deleteMode = false, minHeight = 400, disabled = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (itemIndex, valueType, value) => {
    const _params = [...params]
    _params[itemIndex][valueType] = value
    onChange(_params)
  }

  const deleteRow = index => {
    const _params = [...params]
    delete _params.splice(index, 1)
    onChange(_params)
  }

  const addRow = () => {
    const _params = [...params]
    _params.push({ key: '', value: '' })
    onChange(_params)
  }

  return (
    <List component="nav" className={classes.list} style={{ minHeight: minHeight }} aria-label="params">
      {params.map(({ key, value }, index) => (
        <Fragment>
          <ListItem className={classes.listItem}>
            <TextField
              fullWidth
              disabled={disabled}
              className={[classes.textField, !deleteMode ? classes.textFieldKey : null]}
              placeholder={t('key')}
              value={key}
              onChange={event => handleChange(index, VALUE_TYPE.key, event.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            {!deleteMode && <MultilineTextfield disabled={disabled} value={value} className={classes.textFieldValue} onChange={event => handleChange(index, VALUE_TYPE.value, event.target.value)} />}
            <IconButton disabled={disabled} className={classes.deleteIcon} size="small" onClick={() => deleteRow(index)} edge="end">
              <DeleteIcon />
            </IconButton>
          </ListItem>
        </Fragment>
      ))}
      <Button disabled={disabled} color="primary" className={classes.addButton} onClick={addRow} startIcon={<AddIcon />}>
        {t('add')}
      </Button>
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  list: {},
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  deleteIcon: {
    marginLeft: theme.spacing(2),
  },
  textFieldKey: {
    marginRight: theme.spacing(2),
    width: 300,
    backgroundColor: 'transparent',
  },
  textFieldValue: {
    marginLeft: theme.spacing(2),
    flex: 1,
    backgroundColor: 'transparent',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
}))

export default PropList
