import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core'
import { PARAM_FORM_USER_FIELDS, PARAM_FORM_NICKNAME_FIELD } from '../../m2m-cloud-api/Api/UserService/Models/User'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { isAlreadyExistsError } from '../../m2m-cloud-api/Api/Helper'

const EMAIL_FIELD_ID = 'email'
const EMAIL_FIELD = {
  id: EMAIL_FIELD_ID,
  title: 'email',
  type: 'String',
  validators: ['required', 'isEmail'],
  errorMessages: ['this_field_is_required', 'email_is_not_valid'],
}

class CreateNewUserDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      params: {},
      loading: false,
      errorMessage: null,
    }
  }

  getFormFields() {
    return [PARAM_FORM_NICKNAME_FIELD, EMAIL_FIELD, ...PARAM_FORM_USER_FIELDS]
  }

  handleSubmit() {
    const { email, params } = this.state
    const { api, group, t } = this.props

    this.setState({ loading: true, errorMessage: null })

    api.userService
      .createUser(email, params, group.getId())
      .then(result => {
        this.props.onSuccess(result.userId)
      })
      .catch(error => {
        let message = mapErrorMessage(error)
        if (isAlreadyExistsError(error)) {
          message = t('error_email_adress_already_exists')
        }

        this.setState({
          errorMessage: message,
        })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { email, params, loading, errorMessage } = this.state
    const { t, classes } = this.props

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          {loading && <LinearProgress className={classes.progress} />}
          <DialogTitle id="form-dialog-title">{t('create_new_user')}</DialogTitle>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
            <DialogContent>
              {this.getFormFields().map(field => {
                const value = field.id === EMAIL_FIELD_ID ? email : params[field.id]
                return (
                  <TextValidator
                    className={classes.field}
                    key={field.id}
                    value={value}
                    onChange={event => {
                      if (field.id === EMAIL_FIELD_ID) {
                        this.setState({ email: event.target.value, errorMessage: null })
                      } else {
                        let _params = params
                        _params[field.id] = event.target.value
                        this.setState({ params: _params, errorMessage: null })
                      }
                    }}
                    margin="dense"
                    id={field.id}
                    multiline={field.type === 'Text' ? true : false}
                    label={t(field.title)}
                    validators={field.validators}
                    errorMessages={field.errorMessages.map(message => t(message))}
                    fullWidth
                  />
                )
              })}
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={this.props.onCancel}>
                {t('cancel')}
              </Button>
              <Button disabled={loading} type="submit" color="primary">
                {t('create')}
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    )
  }
}

CreateNewUserDialog.propTypes = {
  api: PropTypes.object.isRequired,
  group: PropTypes.any.isRequired,
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
})

export default withTranslation()(withStyles(styles)(CreateNewUserDialog))
