import React, { useState, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, LinearProgress, DialogTitle, FormControl, Typography, Button } from '@material-ui/core'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const UploadFileDialog = ({ api, open, onClose, onSelectedFile, org }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const inputImageRef = useRef()
  const [file, setFile] = useState(null)
  const [values, setValues] = useState({ type: '', typeInfo: '' })
  const context = api
  const validatorFormRef = useRef()

  const selectFile = useCallback(e => {
    error && setError(false)
    const _file = e.target.files[0]
    setFile(_file)
  }, [])

  const uploadFile = useCallback(async () => {
    try {
      if (file && values.type && values.typeInfo) {
        setLoading(true)
        const upload = await context.orgService.uploadFile(org.getId(), values.type, values.typeInfo, file)
        setTimeout(() => {
          setLoading(false)
          onClose()
        }, 800)
      }
    } catch (error) {
      setError(mapErrorMessage(error))
      console.log('Upload file error... ', error)
    }
  }, [file])

  return (
    <Dialog fullWidth classes={{ paper: classes.rootPaper }} open={open} onClose={onClose}>
      {loading && <LinearProgress color={'secondary'} className={classes.progress} />}
      <DialogTitle className={classes.dialogTitle} variant="h6">
        {t('upload_file')}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <ValidatorForm ref={validatorFormRef} onSubmit={uploadFile} onError={errors => setError(mapErrorMessage(errors))}>
          <TextValidator
            disabled={loading}
            className={classes.field}
            value={values.type}
            onChange={event => setValues(prevState => ({ ...prevState, type: event.target.value }))}
            margin="dense"
            multiline={false}
            label={t('device_type')}
            validators={['required']}
            errorMessages={[t('this_field_is_required')]}
            fullWidth
          />
          <TextValidator
            disabled={loading}
            className={classes.field}
            value={values.typeInfo}
            onChange={event => setValues(prevState => ({ ...prevState, typeInfo: event.target.value }))}
            margin="dense"
            multiline={false}
            label={t('type_info')}
            validators={['required']}
            errorMessages={[t('this_field_is_required')]}
            fullWidth
          />

          <input ref={inputImageRef} className={classes.inputStyle} accept="*" id="contained-button-file" type="file" onChange={selectFile} />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span" fullWidth startIcon={<CloudUploadIcon />}>
              {t('select_file')}
            </Button>
          </label>
          {error ||
            (file && (
              <Typography variant="caption" color={error ? 'error' : 'textPrimary'} className={classes.textCaption}>
                {error ? error : file?.name}
              </Typography>
            ))}
        </ValidatorForm>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button onClick={() => onClose()}>{t('cancel')}</Button>
        <Button disabled={!file || loading} onClick={() => validatorFormRef.current.submit()} color="primary">
          {t('upload')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const useStyles = makeStyles(theme => ({
  rootPaper: {
    maxWidth: 340,
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'flex-end',
  },
  inputStyle: {
    display: 'none',
  },
  imageRemoveButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.primary.main,
  },
  imageCard: {
    height: 200,
    marginBottom: theme.spacing(1),
  },
  textCaption: {
    marginTop: theme.spacing(1),
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}))
export default UploadFileDialog
