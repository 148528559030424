import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import AlertDialog from '../Common/AlertDialog'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import DeviceOrgSelection from './DeviceOrgSelection'
import PropListDialog from '../Common/PropListDialog'
import { ACTION_TYPE } from './DeviceListView'
import MonitoringStatusEditor from '../Monitoring/MonitoringStatusEditor'
import DevicesSendCommandDialog from './DevicesSendCommandDialog'
import { calcProgressPercentage } from '../../Helper'

function DeviceFunctionDialog({ type, devices, onClose }) {
    const context = useContext(PageContext)
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [error, setError] = useState(null)
    const [progressValue, setProgressValue] = useState(0)

    let title = ''
    let message = ''
    let sumitButtonTitle = t('ok')

    const handleSubmit = async (params = null) => {
        setLoading(true)

        try {
            for (let i = 0; i < devices.length; i++) {
                const device = devices[i]
                const deviceId = device.getId()
                const assignedOrg = device.getAssignedOrg()
                const isActivated = device.isActivated()
                switch (type) {
                    case ACTION_TYPE.ACTIVATE:
                        await context.deviceService.activateDevice(deviceId)
                        break
                    case ACTION_TYPE.DEACTIVATE:
                        await context.deviceService.deactivateDevice(deviceId)
                        break
                    case ACTION_TYPE.UNASSIGN:
                        await context.deviceService.unassignDevice(deviceId)
                        break
                    case ACTION_TYPE.CHANGE_ASSIGN:
                        if (assignedOrg) {
                            await context.deviceService.unassignDevice(deviceId)
                        }
                        await context.deviceService.assignDevice(deviceId, selectedOrg.getId())
                        if (isActivated) {
                            await context.deviceService.activateDevice(deviceId)
                        }
                        break
                    case ACTION_TYPE.CHANGE_OWNER:
                        await context.deviceService.changeOwner(deviceId, selectedOrg.getId())
                        break
                    case ACTION_TYPE.SET_STATUS:
                    case ACTION_TYPE.SET_PARAMS:
                    case ACTION_TYPE.DELETE_PARAMS:
                        let settings = {}
                        const removeEmptySetting = type === ACTION_TYPE.SET_STATUS || ACTION_TYPE.DELETE_PARAMS
                        Object.keys(params).map(key => {
                            settings[key] = !removeEmptySetting || (params[key] && params[key].trim() !== '') ? params[key] : null
                        })
                        if (Object.keys(settings).length > 0) {
                            await context.deviceService.updateSettings(deviceId, settings)
                        }
                        break
                    default: break;
                }
                setProgressValue(calcProgressPercentage(i + 1, devices.length))
            }
            setLoading(false)
            onClose(true)
            setSelectedOrg(null)
            setProgressValue(0)
        } catch (error) {
            setError(mapErrorMessage(error))
            setLoading(false)
            setSelectedOrg(null)
        }
    }

    const handleClose = () => {
        setSelectedOrg(null)
        onClose(false)
    }

    let showAlertDialog = false
    let showOrgSelection = false
    let showParamsDialog = false
    let paramsDeleteMode = false
    let showStatusDialog = false

    switch (type) {
        case ACTION_TYPE.ACTIVATE:
            showAlertDialog = true
            title = t('device_management_activate_devices_title')
            message = t('device_management_activate_devices_description', { count: devices.length })
            sumitButtonTitle = t('activate')
            break
        case ACTION_TYPE.DEACTIVATE:
            showAlertDialog = true
            title = t('device_management_deactivate_devices_title')
            message = t('device_management_deactivate_devices_description', { count: devices.length })
            sumitButtonTitle = t('deactivate')
            break
        case ACTION_TYPE.UNASSIGN:
            showAlertDialog = true
            title = t('device_management_unassign_devices_title')
            message = t('device_management_unassign_devices_description', { count: devices.length })
            sumitButtonTitle = t('unassign')
            break
        case ACTION_TYPE.CHANGE_ASSIGN:
            showOrgSelection = selectedOrg ? false : true
            showAlertDialog = !showOrgSelection
            title = t('change_assign')
            message = t('device_management_change_assign_devices_description', { count: devices.length })
            sumitButtonTitle = selectedOrg ? t('change_assign') : t('continue')
            break
        case ACTION_TYPE.CHANGE_OWNER:
            showOrgSelection = selectedOrg ? false : true
            showAlertDialog = !showOrgSelection
            title = t('change_owner')
            message = t('device_management_change_owner_devices_description', { count: devices.length })
            sumitButtonTitle = selectedOrg ? t('change_owner') : t('continue')
            break
        case ACTION_TYPE.SET_STATUS:
            showStatusDialog = true
            title = t('set_status')
            sumitButtonTitle = t('save')
            break
        case ACTION_TYPE.SET_PARAMS:
            showParamsDialog = true
            title = t('set_parameter')
            sumitButtonTitle = t('save')
            break
        case ACTION_TYPE.DELETE_PARAMS:
            showParamsDialog = true
            paramsDeleteMode = true
            title = t('delete_parameter')
            sumitButtonTitle = t('delete')
            break
    }

    return (
        <>
            <AlertDialog
                loading={loading}
                progressProps={{
                    value: progressValue,
                    variant: 'determinate'
                }}
                open={!error && type && showAlertDialog ? true : false}
                title={title}
                message={message}
                onCancel={handleClose}
                onSubmit={handleSubmit}
                submitButtonTitle={sumitButtonTitle}
                errorMessage={error}
            />
            <DeviceOrgSelection open={!error && type && showOrgSelection ? true : false} title={title} onCancel={handleClose} onSelect={setSelectedOrg} sumitButtonTitle={sumitButtonTitle} />
            {(!error && type && showParamsDialog ? true : false) && (
                <PropListDialog
                    open={true}
                    deleteMode={paramsDeleteMode}
                    loading={loading}
                    title={title}
                    params={null}
                    onCancel={handleClose}
                    onSubmit={handleSubmit}
                    progressProps={{
                        value: progressValue,
                        variant: 'determinate'
                    }}
                />
            )}
            {(!error && type && showStatusDialog ? true : false) && (
                <MonitoringStatusEditor
                    open={true}
                    loading={loading}
                    device={null}
                    onCancel={handleClose}
                    onSubmit={handleSubmit}
                    progressProps={{
                        value: progressValue,
                        variant: 'determinate'
                    }}
                />
            )}
            {!error && type === ACTION_TYPE.SEND_COMMAND && <DevicesSendCommandDialog api={context} open={true} sendCommandsFilter={null} devices={devices} onCancel={handleClose} onSuccess={handleClose} />}
            <AlertDialog open={error && type ? true : false} title={t('error')} message={error} onSubmit={() => onClose(true)} />
        </>
    )
}

export default DeviceFunctionDialog
