import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { DEFAULT_EVENT_NAME, SETTINGS_CODE_TOKEN } from '../../m2m-cloud-api/Api/Drivers/CodeDriver'
import { isNotFoundError } from '../../m2m-cloud-api/Api/Helper'
import { mapErrorMessage } from '../../Utilities/ApiHelper'

class DeviceTriggerEventDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      eventName: '',
      eventValue: '1',
      loading: true,
      errorMessage: null,
    }
  }

  componentDidMount() {
    const { device } = this.props
    this.props.api.deviceService
      .readCommandDescs(device.getId())
      .then(commands => {
        const hasTrigger1Command = commands.find(command => command.getName() === DEFAULT_EVENT_NAME)
        this.setState({ commands: commands, loading: false, eventName: hasTrigger1Command ? DEFAULT_EVENT_NAME : '' })
      })
      .catch(error => {
        this.setState({ loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  handleSubmit() {
    const { t } = this.props

    this.setState({ loading: true, errorMessage: null })

    const physicalId = this.props.device.physicalId
    const code = physicalId.substring(physicalId.indexOf(':') + 1)
    const namespace = physicalId.substring(0, physicalId.indexOf(':'))
    const token = this.props.device.getSetting(SETTINGS_CODE_TOKEN)

    this.props.api.deviceService.codeDriver
      .emitEventGet(namespace, code, this.state.eventName, token, this.state.eventValue)
      .then(result => {
        this.props.onSuccess()
      })
      .catch(error => {
        let message = mapErrorMessage(error)
        if (isNotFoundError(error)) {
          message = t('error_device_not_found', { device: error.originError.detail })
        }
        this.setState({ errorMessage: message })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { eventName, eventValue, loading, errorMessage, commands } = this.state
    const { t, classes } = this.props
    const events = (commands && commands.map(command => command.getName())) || []

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('trigger_event')}</DialogTitle>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
            <DialogContent>
              <TextValidator
                select
                className={classes.field}
                label={t('event_name')}
                value={eventName}
                onChange={event => {
                  this.setState({ eventName: event.target.value })
                }}
                margin="dense"
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
                fullWidth>
                {events.map(event => (
                  <MenuItem key={event} value={event}>
                    {event}
                  </MenuItem>
                ))}
              </TextValidator>
              <TextValidator
                label={t('value')}
                value={eventValue}
                onChange={event => {
                  this.setState({ eventValue: event.target.value })
                }}
                margin="dense"
                validators={['required']}
                errorMessages={[t('this_field_is_required')]}
                fullWidth
              />
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={this.props.onCancel}>
                {t('cancel')}
              </Button>
              {
                <Button disabled={loading} type="submit" color="primary">
                  {t('trigger')}
                </Button>
              }
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </div>
    )
  }
}

DeviceTriggerEventDialog.propTypes = {
  api: PropTypes.any.isRequired,
  open: PropTypes.bool,
  device: PropTypes.any.isRequired,
  org: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
})

export default withTranslation()(withStyles(styles)(DeviceTriggerEventDialog))
