import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import webServiceConfig from './config/Config';
import App from './App';
import { darkTheme } from './theme';
import './i18n';

let titlePrefix = '';
if (webServiceConfig && webServiceConfig.name.toLowerCase().indexOf('rad') >= 0) {
  titlePrefix = '[RAD] ';
}
if (webServiceConfig && webServiceConfig.name.toLowerCase().indexOf('dev') >= 0) {
  titlePrefix = '[DEV] ';
}

document.title = `${titlePrefix}Core UI`;

const packageJSON = require('../package.json');
console.log('%c CoreUI Version: ' + packageJSON.version + ' ', 'background: #E63A1B; color: #fff; font-size: 16px');

export const service = webServiceConfig;

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);
