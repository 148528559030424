import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import PageProvider, { PageContext } from './Context/PageProvider';

import DefaultRoute from './DefaultRoute';
import ProtectedRoute from './ProtectedRoute';
import Auth from './Pages/User/Auth';
import PasswordReset from './Pages/User/PasswordReset';
import Activate from './Pages/User/Activate';
import Signup from './Pages/User/Signup';
import Org from './Pages/Org/Org';
import Loading from './Components/Common/Loading';
import Profile from './Pages/User/Profile';
import AccessManagement from './Pages/AccessManagement/AccessManagement';
import Monitoring from './Pages/Monitoring/Monitoring';
import DeviceManagement from './Pages/DeviceManagement/DeviceManagement';

export const getPath = path => {
  const rootPath = '/';
  if (!path || path === '') return rootPath;
  return rootPath + path;
};

function AppRouter() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <PageProvider>
          <Switch>
            <DefaultRoute path="/auth/:otp?" exact component={Auth} />
            <DefaultRoute path="/password-reset/:email?/:code?" exact component={PasswordReset} />
            <DefaultRoute path="/signup" exact component={Signup} />
            <DefaultRoute path="/activate/:id/:code?" component={Activate} />
            <ProtectedRoute title="Access Management" path={'/access'} exact component={AccessManagement} />
            <ProtectedRoute path={'/monitoring'} exact component={Monitoring} />
            <ProtectedRoute path={'/device_management'} exact component={DeviceManagement} />
            <ProtectedRoute path={'/user'} exact component={Profile} />
            <ProtectedRoute title="MessageLOG Admin" path={'/'} component={Org} />
            <ProtectedRoute path="/">
              <Redirect to={'/'} />
            </ProtectedRoute>
          </Switch>
        </PageProvider>
      </Router>
    </Suspense>
  );
}

export default AppRouter;

export { PageContext };
