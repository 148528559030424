import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { v4 as uuidv4 } from 'uuid'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const FIELD_ID_NAME = `name`
const FIELD_ID_HEADER = `header`
const FIELD_ID_BODY = `body`

export const FORM_FIELDS = [
  {
    id: FIELD_ID_NAME,
    title: 'name',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
  {
    id: FIELD_ID_HEADER,
    title: 'header',
    fieldType: FIELD_TYPE.TEXT_FIELD,
    validators: [],
    errorMessages: [],
  },
  {
    id: FIELD_ID_BODY,
    title: 'body',
    fieldType: FIELD_TYPE.TEXT_FIELD_MULTILINE,
    validators: [],
    errorMessages: [],
  },
]

class TemplateDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      values: this.getDefaultValues(),
      loading: false,
      errorMessage: null,
      orgItems: null,
    }
  }

  componentDidMount() {
    const orgItems = this.context.orgService.getOrgListFromCache()
    this.setState({ orgItems })
  }

  isEditMode() {
    const { template } = this.props
    return template ? true : false
  }

  getDefaultValues() {
    const { org, template } = this.props
    let values = {}
    FORM_FIELDS.map(field => {
      if (this.isEditMode()) {
        values[field.id] = template[field.id]
      } else {
        values[field.id] = ''
      }
    })
    return values
  }

  handleSubmit() {
    const { template, org } = this.props
    const { values } = this.state

    this.setState({ loading: true, errorMessage: null })

    const upsertTemplate = templateId => {
      const name = values[FIELD_ID_NAME]
      const header = values[FIELD_ID_HEADER]
      const body = values[FIELD_ID_BODY]
      const orgId = org.getId()
      this.context.appMessageService
        .upsertTemplate(templateId, name, header, body, orgId)
        .then(result => {
          this.props.onSuccess()
        })
        .catch(error => {
          this.setState({
            errorMessage: mapErrorMessage(error),
          })
        })
        .finally(() => this.setState({ loading: false }))
    }

    if (this.isEditMode()) {
      upsertTemplate(template.getId())
    } else {
      const templateId = uuidv4()
      upsertTemplate(templateId)
    }
  }

  render() {
    const { values, orgItems, loading, errorMessage } = this.state
    const { t, classes } = this.props
    if (orgItems === null) return null

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.isEditMode() ? t('edit_template') : t('add_template')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              {FORM_FIELDS.map(field => {
                return (
                  <FormField
                    {...field}
                    title={t(field.title)}
                    value={values[field.id]}
                    onChange={event => {
                      let _values = values
                      _values[field.id] = event.target.value
                      this.setState({ values: _values, error: null })
                    }}
                  />
                )
              })}
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
              <ExpansionPanel classes={{ root: classes.expansionPanel }}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.expansionPanelSummary }}>
                  <Typography className={classes.heading}>{t('available_variables')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={classes.replacementBlocks}>
                    $deviceId - Deevice ID
                    <br />
                    $deviceType - Device Type
                    <br />
                    $deviceSubType - Device Sub-Type
                    <br />
                    $devicePhysicalId - Device Physical ID
                    <br />
                    $deviceDriver - Device Driver
                    <br />
                    $deviceAssignedOrgId - Device Assigned Org ID
                    <br />
                    $deviceOwnerOrgId - Device Owner Org ID
                    <br />
                    $eventName - Event Name (First Event)
                    <br />
                    $eventData0 - Event Trigger Data
                    <br />
                    $eventData1 - Event Trigger Data 1<br />
                    $eventTimestamp - Event Date
                    <br />
                    $eventTargetAppEndpoint - Event Target App Endpoint
                    <br />
                    $def:xxx - User-Defined Definition Variable
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel}>
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {this.isEditMode() ? t('edit_template') : t('add_template')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

TemplateDialog.contextType = PageContext

TemplateDialog.propTypes = {
  open: PropTypes.bool,
  template: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  replacementBlocks: {
    fontSize: theme.font.small,
  },
  expansionPanel: {
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  expansionPanelSummary: {
    padding: 0,
  },
})

export default withTranslation()(withStyles(styles)(TemplateDialog))
