import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import { DefaultStyles } from '../../theme';
import { AppBar, Tabs, Tab, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import Toolbar from '../../Components/Common/Toolbar';
import UserView from '../../Components/User/UserView';
import PropListDialog from '../../Components/Common/PropListDialog';
import EditProfile from '../../Components/User/EditProfile';
import ChangePasswordDialog from '../../Components/User/ChangePasswordDialog';
import AlertDialog from '../../Components/Common/AlertDialog';
import Moment from 'react-moment';
import InstallTwoFactorAuthDialog from '../../Components/User/TwoFactorAuth/InstallTwoFactorAuthDialog';
import EnableTwoFactorAuthDialog from '../../Components/User/TwoFactorAuth/EnableTwoFactorAuthDialog';
import AccessTokenListView from '../../Components/Profile/AccessTokenListView';

const TAB_TYPE = {
    PROFILE: 0,
    PENDING_INVITATIONS: 1,
    OFFERED_INVITATIONS: 2,
    ACCESS_TOKENS: 3,
};

const styles = theme => ({
    root: {
        ...DefaultStyles.AbsoluteFill,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,
    button: {
        marginRight: theme.spacing(2),
    },
    tableWrapper: {
        overflow: 'auto',
    },
    table: {
        flex: 1,
        maxHeight: '100%',
        overflow: 'auto',
    },
    profileView: {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
});

class Profile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: null,
            edit: false,
            editParams: false,
            changePasswordModalVisible: false,
            enableTwoFactorAuthDialogVisible: false,
            installTwoFactorAuthDialogVisible: false,
            invitationToAccept: null,
            invitationToRevoke: null,
            tabIndex: TAB_TYPE.PROFILE,
            offeredInvitations: null,
            pendingInvitations: null,
            groups: null,
            publicUsers: null,
            accessTokens: null,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        switch (this.state.tabIndex) {
            case TAB_TYPE.PENDING_INVITATIONS:
                this.getListPendingInvitations();
                break;
            case TAB_TYPE.OFFERED_INVITATIONS:
                this.getListOfferedInvitations();
                break;
            case TAB_TYPE.ACCESS_TOKENS:
                this.getAccessTokens();
                break;
        }
    }

    updateParams(params) {
        const user = this.context.userService.getActiveUser();
        const userId = user.getUserId();
        this.setState({ loading: true, errorMessage: null });
        const promises = [];
        const currentParams = user.getParams();
        Object.keys(currentParams).map(key => {
            if (Object.keys(params).indexOf(key) === -1) {
                promises.push(this.context.userService.deleteParam(userId, key));
            }
        });
        Object.keys(params).map(key => {
            if (Object.keys(currentParams).indexOf(key) === -1 || currentParams[key] !== params[key]) {
                promises.push(this.context.userService.putParam(userId, key, params[key], false));
            }
        });

        this.context.userService
            .reloadActiveUser()
            .then(user => {
                this.setState({ loading: false, editParams: false });
            })
            .catch(error => {
                this.setState({
                    errorMessage: mapErrorMessage(error),
                });
            });
    }

    getGroups(invitations) {
        let groupIds = [];
        invitations &&
            invitations.map(invitation => {
                if (groupIds.indexOf(invitation.getGroupId()) === -1) {
                    groupIds.push(invitation.getGroupId());
                }
            });
        return groupIds;
    }

    getPublicUsers(invitations) {
        let userIds = [];
        invitations &&
            invitations.map(invitation => {
                if (userIds.indexOf(invitation.getInvitee()) === -1) {
                    userIds.push(invitation.getInvitee());
                }
                if (userIds.indexOf(invitation.getInviter()) === -1) {
                    userIds.push(invitation.getInviter());
                }
            });
        return userIds;
    }

    loadPublicUsers(invitations) {
        const userIds = this.getPublicUsers(invitations);
        if (!userIds.length) {
            return Promise.resolve([])
        }
        return this.context.userService.getPublicUsersWithIds(userIds, false)
    }

    getListPendingInvitations() {
        this.setState({ loading: true, pendingInvitations: null, groups: null, publicUsers: null });
        this.context.authzService
            .listPendingInvitations()
            .then(pendingInvitations => {
                this.context.authzService
                    .readMultipleGroups(this.getGroups(pendingInvitations))
                    .then(groups => {
                        return this.loadPublicUsers(pendingInvitations)
                            .then(publicUsers => {
                                this.setState({ pendingInvitations, groups, publicUsers, loading: false });
                            })
                            .catch(error => {
                                console.log('loadPublicUsers error', error);
                                this.setState({ pendingInvitations: null, groups: null, publicUsers: null, loading: false, error });
                            });
                    })
                    .catch(error => {
                        console.log('pendingInvitations error', error);
                        this.setState({ pendingInvitations: null, groups: null, loading: false, error });
                    });
            })
            .catch(error => {
                console.log('pendingInvitation error', error);
                this.setState({ pendingInvitations: null, error });
            });
    }

    getListOfferedInvitations() {
        this.setState({ loading: true, offeredInvitations: null, groups: null, publicUsers: null });
        this.context.authzService
            .listOfferedInvitations()
            .then(offeredInvitations => {
                this.context.authzService
                    .readMultipleGroups(this.getGroups(offeredInvitations))
                    .then(groups => {
                        return this.loadPublicUsers(offeredInvitations)
                            .then(publicUsers => {
                                this.setState({ offeredInvitations, groups, publicUsers, loading: false });
                            })
                            .catch(error => {
                                console.log('loadPublicUsers error', error);
                                this.setState({ offeredInvitations: null, groups: null, publicUsers: null, loading: false, error });
                            });
                    })
                    .catch(error => {
                        console.log('offeredInvitations error', error);
                        this.setState({ offeredInvitations: null, groups: null, loading: false, error });
                    });
            })
            .catch(error => {
                console.log('getListOfferedInvitations error', error);
                this.setState({ offeredInvitations: null, loading: false, error });
            });
    }

    getAccessTokens() {
        this.setState({ loading: true, offeredInvitations: null, groups: null, publicUsers: null, accessTokens: null });
        this.context.userService
            .getAccessTokens()
            .then(accessTokens => {
                this.setState({ accessTokens, loading: false, error: null });
            })
            .catch(error => {
                console.log('getAccessTokens error', error);
                this.setState({ accessTokens: null, loading: false, error });
            });
    }

    readGroups(groups) {
        return this.context.authzService
            .readMultipleGroups(groups)
            .then(groups => {
                this.setState({ groups });
            })
            .catch(error => {
                console.log('groups error', error);
                this.setState({ groups: null, error });
            });
    }

    acceptInvitation() {
        const { pendingInvitations, invitationToAccept } = this.state;
        this.setState({ loading: true });
        const groupId = invitationToAccept.getGroupId();
        this.context.authzService
            .acceptInvitation(groupId)
            .then(success => {
                this.setState({
                    loading: false,
                    pendingInvitations: pendingInvitations.filter(pendingInvitation => !(pendingInvitation.getInviter() === invitationToAccept.getInviter() && pendingInvitation.getGroupId() === groupId)),
                    invitationToAccept: null,
                });
            })
            .catch(error => {
                console.log('acceptInvitation error', error);
                this.setState({ loading: false, error });
            });
    }

    revokeInvitation() {
        const { offeredInvitations, invitationToRevoke } = this.state;
        this.setState({ loading: true });
        const groupId = invitationToRevoke.getGroupId();
        const invitee = invitationToRevoke.getInvitee();

        this.context.authzService
            .revokeInvitation(groupId, invitee)
            .then(success => {
                this.setState({
                    loading: false,
                    offeredInvitations: offeredInvitations.filter(offeredInvitation => !(offeredInvitation.getInvitee() === invitee && offeredInvitation.getGroupId() === groupId)),
                    invitationToRevoke: null,
                });
            })
            .catch(error => {
                console.log('revokeInvitation error', error);
                this.setState({ loading: false, error });
            });
    }

    logout() {
        this.context.userService.logoutUser();
        this.props.history.push('/');
    }

    render() {
        const classes = this.props.classes;
        const { t } = this.props;
        const {
            pendingInvitations,
            offeredInvitations,
            publicUsers,
            invitationToAccept,
            invitationToRevoke,
            changePasswordModalVisible,
            enableTwoFactorAuthDialogVisible,
            installTwoFactorAuthDialogVisible,
            accessTokens,
        } = this.state;

        const user = this.context.userService.getActiveUser();

        const fetchGroupName = invation => {
            const group = this.state.groups.find(group => group.getId() === invation.getGroupId());
            return group ? group.getName() : invation.getGroupId();
        };

        const fetchNickName = userId => {
            const publicUser = publicUsers?.find(publicUser => publicUser.getId() === userId);
            return publicUser ? publicUser.getNickname() : userId;
        };

        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar} color="secondary">
                    <Toolbar title={this.props.t('user_page_title')} />
                </AppBar>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Tabs
                        className={classes.tabbar}
                        orientation="horizontal"
                        indicatorColor="primary"
                        textColor="primary"
                        value={this.state.tabIndex}
                        onChange={(event, newValue) => this.setState({ tabIndex: newValue }, () => this.loadData())}>
                        <Tab label={t('profile')} />
                        <Tab label={t('pending_invitations')} />
                        <Tab label={t('offered_invitations')} />
                        <Tab label={t('access_tokens')} />
                    </Tabs>
                    {this.state.tabIndex === TAB_TYPE.PROFILE && (
                        <div className={classes.profileView}>
                            <UserView
                                onEditClick={() => this.setState({ edit: true })}
                                onEditParamsClick={() => this.setState({ editParams: true })}
                                onPasswordChangeClick={() => this.setState({ changePasswordModalVisible: true })}
                                onLogoutClick={this.logout.bind(this)}
                                onMultiFactorClick={() => this.setState({ enableTwoFactorAuthDialogVisible: true })}
                            />
                        </div>
                    )}
                    {this.state.tabIndex === TAB_TYPE.PENDING_INVITATIONS && (
                        <div className={classes.tableWrapper}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{t('inviter')}</TableCell>
                                        <TableCell align="left">{t('group')}</TableCell>
                                        <TableCell align="left">{t('created')}</TableCell>
                                        <TableCell align="left">{t('row_actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pendingInvitations &&
                                        pendingInvitations.map(pendingInvitation => (
                                            <TableRow key={pendingInvitation.getInviter()}>
                                                <TableCell align="left">{fetchNickName(pendingInvitation.getInviter())}</TableCell>
                                                <TableCell align="left">{fetchGroupName(pendingInvitation)}</TableCell>
                                                <TableCell aling="left">
                                                    <Moment format="YYYY-MM-DD HH:mm">{pendingInvitation.getCreationTS()}</Moment>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ invitationToAccept: pendingInvitation });
                                                        }}
                                                        color="primary"
                                                        className={classes.button}>
                                                        {t('accept')}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    {this.state.tabIndex === TAB_TYPE.OFFERED_INVITATIONS && (
                        <div className={classes.tableWrapper}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{t('invitee')}</TableCell>
                                        <TableCell align="left">{t('group')}</TableCell>
                                        <TableCell align="left">{t('created')}</TableCell>
                                        <TableCell align="left">{t('row_actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offeredInvitations &&
                                        offeredInvitations.map(offeredInvitation => (
                                            <TableRow key={offeredInvitation.getInvitee()}>
                                                <TableCell align="left">{fetchNickName(offeredInvitation.getInvitee())}</TableCell>
                                                <TableCell align="left">{fetchGroupName(offeredInvitation)}</TableCell>
                                                <TableCell align="left">
                                                    <Moment format="YYYY-MM-DD HH:mm">{offeredInvitation.getCreationTS()}</Moment>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ invitationToRevoke: offeredInvitation });
                                                        }}
                                                        color="primary"
                                                        className={classes.button}>
                                                        {t('revoke')}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    {this.state.tabIndex === TAB_TYPE.ACCESS_TOKENS && accessTokens && (
                        <div className={classes.tableWrapper}>
                            <AccessTokenListView api={this.context} onReloadTokens={() => this.getAccessTokens()} onError={_error => this.setState({ error: mapErrorMessage(_error) })} data={accessTokens} />
                        </div>
                    )}
                </main>
                <EditProfile
                    open={this.state.edit}
                    userService={this.context.userService}
                    onCancel={() => this.setState({ edit: false })}
                    onSuccess={() => {
                        this.setState({ edit: false });
                    }}
                />
                {changePasswordModalVisible && (
                    <ChangePasswordDialog
                        open={true}
                        userService={this.context.userService}
                        onCancel={() => this.setState({ changePasswordModalVisible: false })}
                        onSuccess={() => this.setState({ changePasswordModalVisible: false })}
                    />
                )}
                {user && this.state.editParams && (
                    <PropListDialog
                        open={true}
                        loading={this.state.loading}
                        title={t('parameters')}
                        params={user.getParams()}
                        onSubmit={params => {
                            this.updateParams(params);
                        }}
                        onCancel={() => this.setState({ editParams: false })}
                    />
                )}
                {invitationToAccept && (
                    <AlertDialog
                        open={invitationToAccept ? true : false}
                        title={t('invitation_accept_comfirmation_title')}
                        message={t('invitation_accept_confirmation_description')}
                        onCancel={() => this.setState({ invitationToAccept: null })}
                        submitButtonTitle={t('accept')}
                        onSubmit={() => this.acceptInvitation()}
                    />
                )}
                {invitationToRevoke && (
                    <AlertDialog
                        open={invitationToRevoke ? true : false}
                        title={t('invitation_revoke_comfirmation_title')}
                        message={t('invitation_revoke_confirmation_description')}
                        onCancel={() => this.setState({ invitationToRevoke: null })}
                        submitButtonTitle={t('revoke')}
                        onSubmit={() => this.revokeInvitation()}
                    />
                )}
                {enableTwoFactorAuthDialogVisible && (
                    <EnableTwoFactorAuthDialog
                        open={true}
                        userService={this.context.userService}
                        onClose={status => {
                            const twoFactorStatus = typeof status === 'boolean' && status;
                            this.setState({
                                enableTwoFactorAuthDialogVisible: false,
                                installTwoFactorAuthDialogVisible: twoFactorStatus,
                            });
                        }}
                    />
                )}
                {installTwoFactorAuthDialogVisible && (
                    <InstallTwoFactorAuthDialog
                        open={true}
                        userService={this.context.userService}
                        onCancel={() => {
                            this.setState({ installTwoFactorAuthDialogVisible: false });
                        }}
                    />
                )}
            </div>
        );
    }
}

Profile.contextType = PageContext;

export default withTranslation()(withStyles(styles)(Profile));
