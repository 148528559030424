import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../../../Context/PageProvider'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { PERMISSION_ACTION_TYPE, ENTITY_GROUP_TYPE } from '../../../m2m-cloud-api/Api/AuthzService/Models/Permission'
import { FormField, SelectOrg, FIELD_TYPE } from '../../Form/FormFieldHelper'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'

const FIELD_ID_ENTITY_GROUP = `entity_group`
const FIELD_ID_ACTION = `action`
const FIELD_ID_RESOURCE = `resource`

export const FORM_FIELDS = [
  {
    id: FIELD_ID_ENTITY_GROUP,
    title: 'entity_group',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: ENTITY_GROUP_TYPE.ORG, title: 'org' },
      { value: ENTITY_GROUP_TYPE.GROUP, title: 'group' },
      { value: ENTITY_GROUP_TYPE.ROLE, title: 'role' },
    ],
    validators: [],
    errorMessages: [],
  },
  {
    id: FIELD_ID_ACTION,
    title: 'access',
    fieldType: FIELD_TYPE.SELECT,
    options: [
      { value: PERMISSION_ACTION_TYPE.READ, title: 'read_access' },
      { value: PERMISSION_ACTION_TYPE.WRITE, title: 'write_access' },
      { value: PERMISSION_ACTION_TYPE.WRITE_IMAGES, title: 'write_images_access' },
      { value: PERMISSION_ACTION_TYPE.PERMIT, title: 'permit_access' },
      { value: PERMISSION_ACTION_TYPE.MANAGE_USERS, title: 'manage_users' },
    ],
    validators: [],
    errorMessages: [],
  },
  {
    id: FIELD_ID_RESOURCE,
    title: 'resource',
    fieldType: FIELD_TYPE.SELECT_LIST,
    options: [],
    validators: [],
    errorMessages: [],
  },
]

class PermissionDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      values: this.getDefaultValues(),
      loading: false,
      errorMessage: null,
      orgItems: null,
    }
  }

  componentDidMount() {
    this.loadAllData()
  }

  loadAllData() {
    Promise.all([
      this.context.orgService.searchOrg({ from: 0, size: 10000 }),
      this.context.authzService.readAccessableGroupsOfCurrentUser(false),
      this.context.authzService.readAccessableRolesOfCurrentUser(false),
    ]).then(results => {
      this.setState({
        orgItems: results[0],
        groupItems: results[1],
        roleItems: results[2],
      })
    })
  }

  isEditMode() {
    const { permission } = this.props
    return permission ? true : false
  }

  getDefaultValues() {
    const { permission } = this.props
    let values = {}
    FORM_FIELDS.map(field => {
      if (this.isEditMode()) {
        values[field.id] = permission[field.id]
      } else {
        if (field.id === FIELD_ID_ENTITY_GROUP) {
          values[field.id] = ENTITY_GROUP_TYPE.ORG
        } else if (field.id === FIELD_ID_ACTION) {
          values[field.id] = PERMISSION_ACTION_TYPE.READ
        } else {
          values[field.id] = ''
        }
      }
    })
    return values
  }

  handleSubmit() {
    const { role } = this.props
    const { values } = this.state

    this.setState({ loading: true, errorMessage: null })

    const entityGroup = values[FIELD_ID_ENTITY_GROUP]
    const action = values[FIELD_ID_ACTION]
    const resource = values[FIELD_ID_RESOURCE]
    const roleId = role.getId()
    this.context.authzService
      .addPerm(roleId, entityGroup, action, resource)
      .then(result => {
        this.props.onSuccess()
      })
      .catch(error => {
        this.setState({ errorMessage: mapErrorMessage(error) })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { values, orgItems, groupItems, roleItems, loading, errorMessage } = this.state
    const { t, classes } = this.props
    if (orgItems === null) return null

    const buildLocalizedOptions = fieldOptions => {
      if (fieldOptions) {
        let options = []
        fieldOptions &&
          fieldOptions.map(option => {
            options.push({
              ...option,
              title: t(option.title),
            })
          })
        return options
      }
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('add_permission')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              {FORM_FIELDS.map(field => {
                if (field.id === FIELD_ID_ACTION) {
                  let options = field.options
                  if (this.state.values[FIELD_ID_ENTITY_GROUP] === ENTITY_GROUP_TYPE.ORG) {
                    options = options.filter(option => option.value !== PERMISSION_ACTION_TYPE.MANAGE_USERS)
                  }
                  options = buildLocalizedOptions(options)

                  return (
                    <FormField
                      {...field}
                      key={field.id}
                      title={t(field.title)}
                      options={options}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target.value
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                } else if (field.id === FIELD_ID_RESOURCE) {
                  let options = []
                  switch (this.state.values[FIELD_ID_ENTITY_GROUP]) {
                    case ENTITY_GROUP_TYPE.ORG:
                      field.renderRootNode = false
                      options = orgItems
                      field.title = 'org'
                      return (
                        <SelectOrg
                          {...field}
                          key={field.id}
                          title={t(field.title)}
                          options={options}
                          value={values[field.id]}
                          onChange={event => {
                            let _values = values
                            _values[field.id] = event.target ? event.target.value : event
                            this.setState({ values: _values, errorMessage: null })
                          }}
                        />
                      )
                      break
                    case ENTITY_GROUP_TYPE.GROUP:
                      groupItems.map(group => {
                        options.push({
                          value: group.getId(),
                          title: group.getName(),
                        })
                      })
                      field.title = 'group'
                      break
                    case ENTITY_GROUP_TYPE.ROLE:
                      roleItems.map(role => {
                        options.push({
                          value: role.getId(),
                          title: role.getName(),
                        })
                      })
                      field.title = 'role'
                      break
                  }

                  return (
                    <FormField
                      {...field}
                      key={field.id}
                      title={t(field.title)}
                      options={options}
                      value={values[field.id]}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target ? event.target.value : event
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                } else {
                  let options = buildLocalizedOptions(field.options)
                  return (
                    <FormField
                      {...field}
                      key={field.id}
                      value={values[field.id]}
                      options={options}
                      title={t(field.title)}
                      onChange={event => {
                        let _values = values
                        _values[field.id] = event.target ? event.target.value : event
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  )
                }
              })}
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {t('add_permission')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

PermissionDialog.contextType = PageContext

PermissionDialog.propTypes = {
  open: PropTypes.bool,
  permission: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

export default withTranslation()(withStyles(styles)(PermissionDialog))
