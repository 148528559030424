import React, { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import RecoveryCodesList from './RecoveryCodesList'

const RecoveryCodesDialog = ({ open, onClose, userService }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [recoveryCodes, setRecoveryCodes] = useState(null)

  const requestAuthFactorRecovery = useCallback(async () => {
    try {
      await userService.deleteAuthFactor('recovery')
      await userService.requestAuthFactor('recovery')
      const value = await userService.installAuthFactor('recovery')
      if (value?.params?.codes) {
        setRecoveryCodes(value?.params?.codes.split(','))
      }
    } catch (error) {
      console.log('requestAuthFactorRecovery error... ', error)
    }
  }, [])

  useEffect(() => {
    requestAuthFactorRecovery()
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'} aria-labelledby="tfa-enable-dialog">
      <DialogTitle id="tfa-title">{t('multi_factor_recovery_codes')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <RecoveryCodesList recoveryCodes={recoveryCodes} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogContent: {},
  actionButton: {
    backgroundColor: theme.palette.primary.main,
  },
  disableContentText: {
    flex: 1,
    color: 'rgba(255, 255, 255, 0.87)',
  },
}))

export default RecoveryCodesDialog
