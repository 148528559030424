import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Typography,
} from '@material-ui/core'
import moment from 'moment'
import Moment from 'react-moment'
import { DatePicker } from '../Common/DatePicker'
import MaterialTable from '../Common/MaterialTable'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { VictoryChart, VictoryLine, VictoryAxis, VictoryScatter, createContainer, VictoryTheme } from 'victory'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../theme'
import AlertDialog from '../Common/AlertDialog'

import NextPageIcon from '@material-ui/icons/NavigateNext'
import PreviousPageIcon from '@material-ui/icons/NavigateBefore'
import RefreshIcon from '@material-ui/icons/Refresh'
import Searchbox from '../Common/Searchbox'

const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi')

const TAB_TYPE = {
    CURRENT: 0,
    HISTORICAL: 1,
    LIVE: 2,
}

const HISTORICAL_EVENT_TYPE_ALL = 'all'
const CHART_HEIGHT = 480
const HEADER_AND_FOOTER_SIZE = 550

class DeviceEventDataDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            errorMessage: null,
            items: null,
            eventItems: null,
            tabIndex: TAB_TYPE.CURRENT,
            historicalStartDate: new Date(),
            historicalEndDate: new Date(),
            historicalEventType: null,
            eventTypes: null,
            historicalTabIndex: 0,
            chartHeight: CHART_HEIGHT,
            searchTerm: null,
        }
        this.victoryContainerRef = null
    }

    chechWindowResize = () => {
        const newChartHeight = Math.min(window.innerHeight - HEADER_AND_FOOTER_SIZE, 550)
        if (this.state.chartHeight !== newChartHeight) {
            this.setState({ chartHeight: newChartHeight })
        }
    }

    startLiveEvents() {
        const webSocket = this.props.api.deviceService.getWebSocket()
        webSocket.on('ready', message => {
            webSocket.sendMessage({ type: 'subscribe', deviceId: this.props.device.getId() })
        })
        webSocket.on('error', error => {
            this.setState({ errorMessage: mapErrorMessage(error) })
        })
        webSocket.on('message', message => {
            if (message?.resp === 'PhyEvent') {
                const payload = message.payload
                const items = [{ date: new Date(), name: payload.evt, value: payload.data0, gatewayPhysicalId: payload.gatewayPhysicalId || '-' }, ...(this.state.eventItems || [])]
                this.setState({ eventItems: items })
            }
        })
    }

    stopLiveEvents() {
        this.props.api.deviceService.destroyWebSocket()
    }

    componentDidMount() {
        window.addEventListener('resize', this.chechWindowResize)
        this.chechWindowResize()
        this.loadData()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.chechWindowResize)
        this.stopLiveEvents()
    }

    loadData() {
        switch (this.state.tabIndex) {
            case TAB_TYPE.CURRENT:
                this.queryCurrentDeviceData()
                this.stopLiveEvents()
                break
            case TAB_TYPE.HISTORICAL:
                this.queryHistoricalDeviceData()
                this.stopLiveEvents()
                break
            case TAB_TYPE.LIVE:
                this.setState({ items: null, eventItems: [] })
                this.startLiveEvents()
                break
        }
    }

    onDatePrevButton() {
        const { historicalStartDate, historicalEndDate } = this.state
        const diff = moment(historicalEndDate).diff(moment(historicalStartDate), 'days') + 1
        this.setState(
            {
                historicalStartDate: moment(historicalStartDate).subtract(diff, 'days').toDate(),
                historicalEndDate: moment(historicalStartDate).subtract(1, 'days').toDate(),
            },
            () => this.loadData(),
        )
    }

    onDateNextButton() {
        const { historicalStartDate, historicalEndDate } = this.state
        const diff = moment(historicalEndDate).diff(moment(historicalStartDate), 'days') + 1
        this.setState(
            {
                historicalStartDate: moment(historicalEndDate).add(1, 'days').toDate(),
                historicalEndDate: moment(historicalEndDate).add(diff, 'days').toDate(),
            },
            () => this.loadData(),
        )
    }

    queryCurrentDeviceData() {
        this.setState({ loading: true })

        this.props.api.deviceService
            .readEventDescs(this.props.device.getId())
            .then(events => {
                const eventTypes = events.map(event => event.getName())
                this.props.api.deviceService
                    .getDeviceData([this.props.device.getId()], eventTypes)
                    .then(eventValues => {
                        let historicalEventType = this.state.historicalEventType
                        if (historicalEventType === null && eventTypes.length > 0) {
                            const preperedDefaultEventName = 'TRIGGER1'
                            const searchIndex = eventTypes.indexOf(preperedDefaultEventName)
                            historicalEventType = searchIndex >= 0 ? eventTypes[searchIndex] : eventTypes[0]
                        }
                        this.setState({ eventTypes: eventTypes.sort(), historicalEventType, eventItems: this.prepareEventValues(eventValues, true), loading: false })
                    })
                    .catch(error => {
                        this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
                    })
            })
            .catch(error => {
                console.log('eventTypes error: ', error)
                this.setState({ eventTypes: null, loading: false, errorMessage: mapErrorMessage(error) })
            })
    }

    queryHistoricalDeviceData() {
        this.setState({ loading: true })

        const from = new Date(this.state.historicalStartDate.setHours(0, 0, 0, 0)).getTime()
        const to = new Date(this.state.historicalEndDate.setHours(23, 59, 59, 999)).getTime()

        const filterEventTypes =
            this.state.historicalEventType === HISTORICAL_EVENT_TYPE_ALL ? this.state.eventTypes || [] : this.state.historicalEventType !== null ? [this.state.historicalEventType] : []

        this.props.api.deviceService
            .getHistoricalDeviceData([this.props.device.getId()], filterEventTypes, from, to)
            .then(result => {
                const items = this.prepareEventValues(result)
                this.setState({ items: null }, () => {
                    this.setState({ items, loading: false })
                })
            })
            .catch(error => {
                console.warn('getHistoricalDeviceData, error', error)
                this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
            })
    }

    prepareEventValues(entries, sortByEventName = false) {
        const deviceEvents = entries?.find(entry => entry.deviceId === this.props.device.getId())
        let items =
            deviceEvents?.series?.map(event => ({ name: event.evt, value: event.params?.data0, date: new Date(Number(event.ts)), physicalGatewayIds: event.gatewayPhysicalIds?.join(', ') || '' })) || []
        if (sortByEventName) {
            items = items.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            items = items.sort((a, b) => b.date.getTime() - a.date.getTime())
        }
        return items
    }

    renderTable() {
        const { tabIndex, searchTerm, chartHeight, eventItems } = this.state
        const { t, classes } = this.props
        let items = tabIndex === TAB_TYPE.CURRENT ? eventItems : this.state.items

        if (searchTerm && searchTerm.trim() !== '' && items?.length > 0) {
            items = items.filter(item => {
                const searchString = moment(item.date).format('YYYY-MM-DD HH:mm:ss') + item.name + item.value + item.physicalGatewayIds
                return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
            })
        }

        if (!items) return null

        return (
            <Fragment>
                <MaterialTable
                    localization={{
                        body: { emptyDataSourceMessage: t('no_data_available') },
                    }}
                    options={{
                        paging: tabIndex === TAB_TYPE.CURRENT ? false : true,
                        pageSize: tabIndex === TAB_TYPE.CURRENT ? 1000 : tabIndex === TAB_TYPE.LIVE ? 100 : 20,
                        filtering: false,
                        pageSizeOptions: [10, 20, 50, 100],
                        minBodyHeight: chartHeight,
                        maxBodyHeight: chartHeight,
                    }}
                    columns={[
                        {
                            title: t('event'),
                            field: 'name',
                        },
                        {
                            title: t('date'),
                            field: 'date',
                            render: rowData =>
                                rowData.date ? (
                                    <Moment className={classes.eventDate} format="YYYY-MM-DD HH:mm:ss.SSS">
                                        {rowData.date}
                                    </Moment>
                                ) : null,
                        },
                        {
                            title: t('value'),
                            field: 'value',
                        },
                        {
                            title: t('gateway_ids'),
                            field: 'physicalGatewayIds',
                        },
                    ]}
                    data={items}
                />
            </Fragment>
        )
    }

    renderLiveTable() {
        const { t, classes } = this.props
        const { searchTerm, eventItems, tabIndex, chartHeight } = this.state
        let items = eventItems
        if (tabIndex !== TAB_TYPE.LIVE || !items) return null
        console.log('items:', items)

        if (searchTerm && searchTerm.trim() !== '' && items?.length > 0) {
            items = items.filter(item => {
                const searchString = moment(item.date).format('YYYY-MM-DD HH:mm:ss') + item.name + item.value + item.physicalGatewayIds
                return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
            })
        }

        return (
            <Fragment>
                <MaterialTable
                    localization={{
                        body: { emptyDataSourceMessage: t('no_data_available') },
                    }}
                    options={{
                        paging: true,
                        pageSize: 100,
                        filtering: false,
                        pageSizeOptions: [10, 20, 50, 100],
                        minBodyHeight: chartHeight,
                        maxBodyHeight: chartHeight,
                    }}
                    columns={[
                        {
                            title: t('event'),
                            field: 'name',
                        },
                        {
                            title: t('date'),
                            field: 'date',
                            render: rowData => (
                                <Moment className={classes.eventDate} format="YYYY-MM-DD HH:mm:ss.SSS">
                                    {rowData.date}
                                </Moment>
                            ),
                        },
                        {
                            title: t('value'),
                            field: 'value',
                        },
                        {
                            title: t('gateway_ids'),
                            field: 'gatewayPhysicalId',
                        },
                    ]}
                    data={items}
                />
            </Fragment>
        )
    }

    renderHistoricalChart() {
        const { t, classes } = this.props
        const { eventTypes, eventItems } = this.state
        let items = this.state.tabIndex === TAB_TYPE.CURRENT ? eventItems : this.state.items

        const hasYNoNumberValues = this.state.tabIndex === TAB_TYPE.HISTORICAL && items?.find(item => item.value && (item.value.trim() === '' || isNaN(item.value))) ? true : false
        items = items?.map(item => {
            return { x: item.date, y: hasYNoNumberValues ? item.value : Number(item.value) }
        })

        if (!items || items.length <= 0)
            return (
                <Grid container className={classes.chartNoDataRoot}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        {t('no_data_available')}
                    </Typography>
                </Grid>
            )
        return (
            <Grid container className={classes.chartRoot}>
                <VictoryChart
                    width={1000}
                    height={this.state.chartHeight}
                    theme={VictoryTheme.material}
                    scale={{ x: 'time' }}
                    domainPadding={20}
                    containerComponent={
                        <VictoryZoomVoronoiContainer
                            zoomDimension={'x'}
                            labels={({ datum }) => {
                                if (datum.childName === 'chart-line-0') {
                                    return `${moment(datum.x).format('YYYY-MM-DD HH:mm:ss.SSS')}`
                                } else if (datum.childName === 'chart-scatter-1') {
                                    return `${t('value')}: ${datum.y}`
                                }
                            }}
                        />
                    }>
                    <VictoryLine style={{ data: { stroke: SECONDARY_COLOR } }} data={items} interpolation="stepBefore" sortOrder={'descending'} />
                    <VictoryScatter data={items} style={{ data: { fill: PRIMARY_COLOR } }} />
                    <VictoryAxis
                        dependentAxis
                        tickFormat={t => t}
                        style={{
                            axis: {
                                stroke: '#fff',
                            },
                            grid: {
                                stroke: 'transparent',
                            },
                            tickLabels: {
                                fontSize: 11,
                                fill: '#fff',
                            },
                        }}
                    />
                    <VictoryAxis
                        fixLabelOverlap
                        tickFormat={t => {
                            return [moment(t).format('YYYY-MM-DD'), moment(t).format('HH:mm')]
                        }}
                        style={{
                            axis: {
                                stroke: '#fff',
                            },
                            grid: {
                                stroke: 'transparent',
                            },
                            tickLabels: {
                                fontSize: 10,
                                fill: '#fff',
                            },
                        }}
                    />
                </VictoryChart>
            </Grid>
        )
    }

    renderCurrrentTable() {
        const { eventTypes, eventItems } = this.state
        const { t, classes } = this.props

        if (!eventTypes || !eventItems) return null

        return (
            <Fragment>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('event')}</TableCell>
                            <TableCell align="right">{t('value')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventTypes.map(eventItem => {
                            const event = eventItems.find(eventValue => eventValue.name === eventItem)
                            const value = event ? event.value : '-'
                            const date = event ? event.date : null
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {eventItem}
                                    </TableCell>
                                    <TableCell align="right" title={value} className={classes.eventValueCell}>
                                        {value}
                                        <br />
                                        {date ? (
                                            <Moment className={classes.eventDate} format="YYYY-MM-DD HH:mm:ss.SSS">
                                                {date}
                                            </Moment>
                                        ) : (
                                            <div>&nbsp;</div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Fragment>
        )
    }

    renderHistoricaltab() {
        const { historicalTabIndex } = this.state
        const { t, classes } = this.props

        return (
            <Fragment>
                <Tabs
                    className={classes.tabs}
                    orientation="horizontal"
                    indicatorColor="primary"
                    textColor="primary"
                    value={historicalTabIndex}
                    onChange={(event, newValue) => this.setState({ historicalTabIndex: newValue })}>
                    <Tab label={t('list')} />
                    <Tab label={t('chart')} />
                </Tabs>
                {historicalTabIndex === 0 && this.renderTable()}
                {historicalTabIndex === 1 && this.renderHistoricalChart()}
            </Fragment>
        )
    }

    render() {
        const { eventTypes, loading, errorMessage, searchTerm, historicalEndDate } = this.state
        const { t, classes } = this.props

        const diff = moment().startOf('day').diff(moment(historicalEndDate).startOf('day'), 'days')
        const nextDateButtonDisabled = diff <= 0

        return (
            <div>
                <Dialog classes={{ paper: classes.dialogPaper }} open={this.props.open} onClose={this.props.onClose} fullWidth maxWidth={'lg'} aria-labelledby="form-dialog-title">
                    {loading && <LinearProgress className={classes.progress} />}
                    <DialogTitle id="form-dialog-title">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1, paddingTop: 8 }}>{t('device_event_data', { id: this.props.device?.getPhysicalId() || '' })}</div>
                            <Searchbox value={searchTerm} className={classes.search} onChange={value => this.setState({ searchTerm: value })} />
                            <IconButton onClick={() => this.loadData()} edge="end">
                                <RefreshIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <Tabs
                        className={classes.tabs}
                        orientation="horizontal"
                        indicatorColor="primary"
                        textColor="primary"
                        value={this.state.tabIndex}
                        onChange={(event, newValue) => this.setState({ tabIndex: newValue }, () => this.loadData())}>
                        <Tab label={t('current')} />
                        <Tab label={t('historical')} />
                        <Tab label={t('live')} />
                    </Tabs>
                    {(this.state.tabIndex === TAB_TYPE.CURRENT || this.state.tabIndex === TAB_TYPE.LIVE) && (
                        <Fragment>
                            <DialogContent>{this.state.tabIndex === TAB_TYPE.CURRENT ? this.renderTable() : this.renderLiveTable()}</DialogContent>
                            <DialogActions>
                                <Button disabled={loading} onClick={this.props.onClose} type="submit">
                                    {t('close')}
                                </Button>
                            </DialogActions>
                        </Fragment>
                    )}
                    {this.state.tabIndex === TAB_TYPE.HISTORICAL && (
                        <Fragment>
                            <DialogActions className={classes.header}>
                                <DialogContent className={classes.historicalFilterRoot}>
                                    <Grid container spacing={3}>
                                        <Grid item md={8}>
                                            <IconButton onClick={() => this.onDatePrevButton()} className={classes.dateNavButton}>
                                                <PreviousPageIcon />
                                            </IconButton>
                                            <DatePicker
                                                label={t('date_from')}
                                                value={this.state.historicalStartDate}
                                                className={classes.datePicker}
                                                onChange={date => {
                                                    this.setState({ historicalStartDate: date ? date.toDate() : null }, () => {
                                                        if (date && date.isValid()) {
                                                            this.queryHistoricalDeviceData()
                                                        }
                                                    })
                                                }}
                                            />
                                            <DatePicker
                                                label={t('date_to')}
                                                value={this.state.historicalEndDate}
                                                className={classes.datePicker}
                                                onChange={date => {
                                                    this.setState({ historicalEndDate: date ? date.toDate() : null }, () => {
                                                        if (date && date.isValid()) {
                                                            this.queryHistoricalDeviceData()
                                                        }
                                                    })
                                                }}
                                            />
                                            <IconButton onClick={() => this.onDateNextButton()} className={classes.dateNavButton} disabled={nextDateButtonDisabled}>
                                                <NextPageIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl className={classes.selectFormControl} fullWidth>
                                                <InputLabel htmlFor="user-type">{t('event')}</InputLabel>
                                                <Select
                                                    select
                                                    value={this.state.historicalEventType}
                                                    onChange={event => {
                                                        this.setState({ historicalEventType: event.target.value }, () => {
                                                            this.queryHistoricalDeviceData()
                                                        })
                                                    }}
                                                    margin="dense">
                                                    <MenuItem key={HISTORICAL_EVENT_TYPE_ALL} value={HISTORICAL_EVENT_TYPE_ALL}>
                                                        {t('all_events')}
                                                    </MenuItem>
                                                    {eventTypes.map(eventItem => (
                                                        <MenuItem key={eventItem} value={eventItem}>
                                                            {eventItem}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </DialogActions>
                            <DialogContent className={classes.dialogContent}>{this.renderHistoricaltab()}</DialogContent>
                            <DialogActions>
                                <Button disabled={loading} onClick={this.props.onClose} type="submit" color="primary">
                                    {t('close')}
                                </Button>
                            </DialogActions>
                        </Fragment>
                    )}
                    {errorMessage && (
                        <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
                    )}
                </Dialog>
            </div>
        )
    }
}

DeviceEventDataDialog.propTypes = {
    api: PropTypes.any.isRequired,
    open: PropTypes.bool,
    device: PropTypes.any,
    onClose: PropTypes.func.isRequired,
}

const styles = theme => ({
    dialogPaper: {},
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20,
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
    },
    tabs: {
        paddingBottom: 10,
    },
    eventValueCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 200,
        fontSize: theme.font.large,
    },
    datePicker: {
        marginRight: theme.spacing(2),
    },
    dateNavButton: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    eventDate: {
        fontSize: theme.font.small,
    },
    selectFormControl: {
        marginTop: 19,
    },
    progress: {
        position: 'absolute',
        width: '100%',
    },
    dialogContent: {
        padding: 4,
        paddingTop: 8,
    },
    chartRoot: {
        // width: '50%',
        margin: '0 auto',
    },
    chartNoDataRoot: {
        padding: 20,
        minHeight: CHART_HEIGHT + 53,
    },
    historicalFilterRoot: {
        overflow: 'hidden',
    },
    search: {
        backgroundColor: '#424242',
        height: 32,
        marginTop: 8,
    },
    tableHeader: {
        backgroundColor: 'transparent'
    }
})

export default withTranslation()(withStyles(styles)(DeviceEventDataDialog))
