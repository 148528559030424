import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { PageContext } from '../../../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { IconButton, Grid, List, ListItem, ListItemText, ListItemSecondaryAction, ListSubheader, Fab, Menu, MenuItem } from '@material-ui/core'
import AlertDialog from '../../Common/AlertDialog'
import CreateNewUserDialog from '../../User/CreateNewUserDialog'
import InviteUserDialog from '../../User/InviteUserDialog'
import AddRoleDialog from './AddRoleDialog'
import AddUserDialog from '../../User/AddUserDialog'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
    fab: {
        position: 'absolute',
        top: theme.spacing(0),
        right: theme.spacing(3),
        zIndex: 10,
    },
    gridColumn: {
        position: 'relative',
    },
    removeIcon: {
        marginRight: 3,
    },
})
class GroupView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errorMessage: null,
            roles: null,
            publicUsers: null,
            addUserAnchorEl: null,
            createNewUserDialogVisible: false,
            inviteUserDialogVisible: false,
            addRoleDialogVisible: false,
            addUserDialogVisible: false,
            dialogVisible: false,
        }
    }

    componentDidMount() {
        this.readRoles()
    }

    isPrivateGroup(group) {
        const user = this.context.userService.getActiveUser()
        if (group && user && user.userRoleMeta) {
            return user.userRoleMeta.getPrivateGroup() === group.getId()
        }
        return false
    }

    isPrivateRole(role) {
        const user = this.context.userService.getActiveUser()
        if (role && user && user.userRoleMeta) {
            return user.userRoleMeta.getPrivateRole() === role.getId()
        }
        return false
    }

    isUserSelf(userId) {
        const user = this.context.userService.getActiveUser()
        return user && userId === user.getUserId()
    }

    render() {
        const { roles, addUserAnchorEl, createNewUserDialogVisible, addUserDialogVisible, publicUsers, errorMessage } = this.state
        const { group, classes, t } = this.props

        if (roles === null || publicUsers === null) return null

        return (
            <Fragment>
                <Grid container>
                    <Grid className={classes.gridColumn} item md={6} xs={12}>
                        <List subheader={<ListSubheader component="div">{t('users')}</ListSubheader>}>
                            {group.getUsers().map((userId, index) => {
                                let name = t('empty_nickname', { userId })
                                if (this.isUserSelf(userId)) {
                                    name = t('you')
                                } else if (publicUsers) {
                                    const publicUser = publicUsers.find(publicUser => publicUser.getId() === userId)
                                    if (publicUser && publicUser.getNickname()) {
                                        name = publicUser.getNickname()
                                    }
                                }
                                return (
                                    <ListItem key={'groupUsers' + index}>
                                        <ListItemText primary={name} />
                                        {!this.isPrivateGroup(group) && (
                                            <ListItemSecondaryAction>
                                                <IconButton className={classes.removeIcon} onClick={() => this.setState({ removeUser: userId })} edge="end">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                )
                            })}
                        </List>
                        {!this.isPrivateGroup(group) && (
                            <Fragment>
                                <Fab onClick={event => this.setState({ addUserAnchorEl: event.currentTarget })} size={'small'} color="primary" aria-label="Add" className={classes.fab}>
                                    <AddIcon />
                                </Fab>
                                <Menu anchorEl={addUserAnchorEl} keepMounted open={Boolean(addUserAnchorEl)} onClose={() => this.setState({ addUserAnchorEl: null })}>
                                    <MenuItem onClick={() => this.setState({ addUserAnchorEl: null, createNewUserDialogVisible: true })}>{t('create_new_user')}</MenuItem>
                                    <MenuItem onClick={() => this.setState({ addUserAnchorEl: null, addUserDialogVisible: true })}>{t('add_user')}</MenuItem>
                                    <MenuItem onClick={() => this.setState({ addUserAnchorEl: null, inviteUserDialogVisible: true })}>{t('invite_user')}</MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                    </Grid>
                    <Grid className={classes.gridColumn} item md={6} xs={12}>
                        <List subheader={<ListSubheader component="div">{t('roles')}</ListSubheader>}>
                            {this.state.roles &&
                                this.state.roles.map((role, index) => {
                                    const name = this.isPrivateRole(role) ? t('private_role') : role.getName()
                                    return (
                                        <ListItem key={'groupRoles' + index}>
                                            { }
                                            <ListItemText primary={name} />
                                            {!this.isPrivateGroup(group) && (
                                                <ListItemSecondaryAction>
                                                    <IconButton className={classes.removeIcon} onClick={() => this.setState({ removeRole: role })} edge="end">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                    )
                                })}
                        </List>
                        {!this.isPrivateGroup(group) && (
                            <Fab onClick={() => this.setState({ addRoleDialogVisible: true })} size={'small'} color="primary" aria-label="Add" className={classes.fab}>
                                <AddIcon />
                            </Fab>
                        )}
                    </Grid>
                </Grid>
                <InviteUserDialog
                    api={this.context}
                    groupId={group.id}
                    open={this.state.inviteUserDialogVisible}
                    onSuccess={() => {
                        this.setState({ inviteUserDialogVisible: false })
                    }}
                    onCancel={() => {
                        this.setState({ inviteUserDialogVisible: false })
                    }}
                />
                {createNewUserDialogVisible && (
                    <CreateNewUserDialog
                        api={this.context}
                        open={true}
                        group={group}
                        onSuccess={userId => {
                            this.setState({ createNewUserDialogVisible: false }, () => {
                                this.props.onGroupUserAdded(userId)
                                setTimeout(async () => {
                                    const publicUsers = await this.readPublicUsers()
                                    this.setState({ publicUsers })
                                }, 100)
                            })
                        }}
                        onCancel={() => {
                            this.setState({ createNewUserDialogVisible: false })
                        }}
                    />
                )}
                {addUserDialogVisible && (
                    <AddUserDialog
                        api={this.context}
                        groupId={group.id}
                        open={true}
                        addedUserIds={this.props.group.getUsers()}
                        onSuccess={() => {
                            this.setState({ addUserDialogVisible: false }, () => {
                                this.props.onGroupChange()
                            })
                        }}
                        onCancel={() => {
                            this.setState({ addUserDialogVisible: false })
                        }}
                    />
                )}
                <AddRoleDialog
                    groupId={group.id}
                    open={this.state.addRoleDialogVisible}
                    addedRoleIds={this.props.group.getRoles()}
                    onSuccess={() => {
                        this.setState({ addRoleDialogVisible: false }, () => {
                            this.props.onGroupChange()
                        })
                    }}
                    onCancel={() => {
                        this.setState({ addRoleDialogVisible: false })
                    }}
                />
                {this.state.removeUser && (
                    <AlertDialog
                        open={this.state.removeUser ? true : false}
                        title={t('group_user_comfirmation_title')}
                        message={t('group_user_comfirmation_description')}
                        onCancel={() => this.setState({ removeUser: null })}
                        submitButtonTitle={t('delete')}
                        onSubmit={() => this.removeUser(group.id, this.state.removeUser)}
                    />
                )}
                {this.state.removeRole && (
                    <AlertDialog
                        open={this.state.removeRole ? true : false}
                        title={t('group_role_remove_comfirmation_title')}
                        message={t('group_role_remove_comfirmation_description')}
                        onCancel={() => this.setState({ removeRole: null })}
                        submitButtonTitle={t('remove')}
                        onSubmit={() => this.removeRole(group.id, this.state.removeRole)}
                    />
                )}
                {errorMessage && (
                    <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
                )}
            </Fragment>
        )
    }

    async readRoles() {
        const { group, t } = this.props
        this.setState({ roles: null, publicUsers: null })

        try {
            let roles = await this.context.authzService.readMultipleRoles(group.getRoles())
            roles = roles.sort((a, b) => {
                var nameA = a.getName().toUpperCase()
                var nameB = b.getName().toUpperCase()
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })

            const publicUsers = await this.readPublicUsers()
            this.setState({ roles, publicUsers })
        } catch (error) {
            this.setState({ errorMessage: mapErrorMessage(error) })
        }
    }

    async readPublicUsers() {
        const { group } = this.props
        return await this.context.userService.getPublicUsersWithIds(group.getUsers(), false)
    }

    removeRole(groupId, role) {
        const { t } = this.props
        this.context.authzService
            .removeRole(groupId, role.id)
            .then(role => {
                this.setState({ removeRole: null })
                this.props.onGroupChange()
            })
            .catch(error => {
                console.log('removeRole error', error)
                this.setState({ errorMessage: mapErrorMessage(error) })
            })
    }

    removeUser(groupId, userId) {
        const { t } = this.props
        this.context.authzService
            .removeUser(groupId, userId)
            .then(user => {
                this.setState({ removeUser: null })
                this.props.onGroupChange()
            })
            .catch(error => {
                console.log('removeUser error', error)
                this.setState({ errorMessage: mapErrorMessage(error) })
            })
    }
}

GroupView.contextType = PageContext

GroupView.propTypes = {
    onGroupChange: PropTypes.func.isRequired,
}

export default withTranslation()(withStyles(styles)(GroupView))
