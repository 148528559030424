import React, { useState, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PageContext } from '../../Context/PageProvider';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, LinearProgress, DialogContentText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { mapErrorMessage } from '../../Utilities/ApiHelper';

const FIELD_IDS = {
    MONITORING_STATUS: 'monitoring.status',
    MONITORING_COMMENT: 'monitoring.comment',
};

const STATUS_OPTIONS = ['operational', 'exchange', 'removal', 'installation'];
const DEFAULT_STATUS_OPTION = STATUS_OPTIONS[0];

const MonitoringStatusEditor = ({ open, params, loading, onCancel, onSubmit, progressProps }) => {
    const classes = useStyles();
    const context = useContext(PageContext);
    const { t } = useTranslation();
    const formRef = useRef(null);

    const defaultParams = params || {};
    const [values, setValues] = useState({
        [FIELD_IDS.MONITORING_STATUS]: defaultParams[FIELD_IDS.MONITORING_STATUS] || DEFAULT_STATUS_OPTION,
        [FIELD_IDS.MONITORING_COMMENT]: defaultParams[FIELD_IDS.MONITORING_COMMENT] || '',
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async () => {
        try {
            const settings = { ...values };
            if (settings[FIELD_IDS.MONITORING_STATUS] === STATUS_OPTIONS[0]) {
                settings[FIELD_IDS.MONITORING_STATUS] = null;
            }
            if (settings[FIELD_IDS.MONITORING_COMMENT].trim() === '') {
                settings[FIELD_IDS.MONITORING_COMMENT] = null;
            }
            onSubmit(settings);
        } catch (error) {
            setErrorMessage(mapErrorMessage(error));
        }
    };

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'sm'}>
            {loading && <LinearProgress className={classes.progress} {...progressProps} />}
            <DialogTitle id="form-dialog-title">{t('monitoring_status')}</DialogTitle>
            <DialogContent>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log('form error:', errors)}>
                    <FormField
                        id={FIELD_IDS.MONITORING_STATUS}
                        fieldType={FIELD_TYPE.SELECT}
                        value={values[FIELD_IDS.MONITORING_STATUS]}
                        options={STATUS_OPTIONS.map(key => ({ value: key, title: t('monitoring_status_' + key) }))}
                        title={t('status')}
                        onChange={event => setValues(prevValue => ({ ...prevValue, [FIELD_IDS.MONITORING_STATUS]: event.target.value }))}
                    />
                    <FormField
                        id={FIELD_IDS.MONITORING_COMMENT}
                        fieldType={FIELD_TYPE.TEXT_FIELD_MULTILINE}
                        value={values[FIELD_IDS.MONITORING_COMMENT]}
                        title={t('comment')}
                        rows={5}
                        onChange={event => setValues(prevValue => ({ ...prevValue, [FIELD_IDS.MONITORING_COMMENT]: event.target.value }))}
                    />
                </ValidatorForm>
                {errorMessage && (
                    <DialogContentText style={{ marginTop: 20 }} color="error">
                        {errorMessage}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onCancel}>
                    {t('cancel')}
                </Button>
                <Button disabled={loading} onClick={() => formRef.current.submit()} color="primary">
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 99999,
        left: 0,
    },
}));

export default MonitoringStatusEditor;
