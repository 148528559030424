import React, { useEffect, useState, Fragment, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Virtuoso } from 'react-virtuoso'
import FileSaver from 'file-saver'
import { List, ListItemText, ListSubheader, Grid, Typography, Divider, IconButton, Fab } from '@material-ui/core'
import { ItemContainer } from './ListContainer'
import { bytesToSize, mapErrorMessage } from '../../Utilities/ApiHelper'
import AlertDialog from './AlertDialog'
import Loading from './Loading'
import UploadFileDialog from './UploadFileDialog'

import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
  },
  listHeaderContainer: {
    '& p': {
      flex: 1,
    },
  },
  listItemContainer: {
    '& div': {
      flex: 1,
    },
  },
  listItem: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  smallColumn: {
    maxWidth: 130,
  },
  virtuoso: {
    listStyleType: 'none',
    height: '100vh',
  },
  virtuosoFooter: {
    height: 20,
    width: '100%',
  },
  creatorNickname: {
    flex: 0.5,
  },
  noFilesText: {
    marginLeft: 24,
    marginTop: 16,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
}))

const FileRow = ({ item, index, context, onLoading, onDeleteFile }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const fileId = item.fileId
  const extension = item.extension
  const size = bytesToSize(item.size)
  const type = item.type
  const typeInfo = item?.typeInfo
  const creatorNickname = item?.creator.getNickname()
  const [deleteMessage, setDeleteMessage] = useState(null)

  const getFile = async (fileId, extension) => {
    try {
      onLoading(true)
      const file = await context.orgService.getFile(fileId)
      FileSaver.saveAs(file, `${fileId}.${extension}`)
      onLoading(false)
    } catch (error) {
      setErrorMessage(mapErrorMessage(error))
      console.log('download file - error', 'originError: ', error.originError)
    }
  }

  return (
    <Grid key={index} container alignItems={'center'} className={classes.listItemContainer}>
      <ListItemText primary={<Typography variant={'body2'}>{type}</Typography>} />
      <ListItemText primary={<Typography variant={'body2'}>{typeInfo}</Typography>} />
      <ListItemText primary={<Typography variant={'body2'}>{fileId}</Typography>} />
      <ListItemText className={classes.smallColumn} primary={<Typography variant={'body2'}>{extension?.toUpperCase()}</Typography>} />
      <ListItemText className={classes.smallColumn} primary={<Typography variant={'body2'}>{size}</Typography>} />
      <ListItemText
        primary={
          <Grid container alignItems={'center'}>
            <Typography className={classes.creatorNickname} variant={'body2'}>
              {creatorNickname}
            </Typography>
            <IconButton size={'small'} onClick={async () => getFile(fileId, extension)} disabled={loading} className={classes.downloadIcon}>
              <GetAppIcon color={loading ? 'secondary' : 'inherit'} />
            </IconButton>
            <IconButton size={'small'} onClick={() => setDeleteMessage(t('you_want_to_delete_this_file'))} disabled={loading}>
              <DeleteIcon color={loading ? 'secondary' : 'inherit'} />
            </IconButton>
          </Grid>
        }
      />
      {errorMessage ||
        (deleteMessage && (
          <AlertDialog
            open={true}
            title={deleteMessage ? t('delete') : t('error')}
            message={errorMessage || deleteMessage}
            submitButtonTitle={t('ok')}
            cancelButtonTitle={deleteMessage && t('cancel')}
            onCancel={() => setDeleteMessage(null)}
            onSubmit={() => {
              setErrorMessage(null)
              setDeleteMessage(null)
              deleteMessage && onDeleteFile(fileId)
            }}
          />
        ))}
    </Grid>
  )
}

const VIRTUOSO_STYLE = {
  listStyleType: 'none',
  height: '100vh',
}
function Files({ api, org }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadFileDialogVisible, setUploadFileDialogVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const context = api

  useEffect(async () => {
    getFiles()
  }, [org && org.getId()])

  const getFiles = async () => {
    try {
      console.log('get files...')
      const _files = await context.orgService.getFiles(org.getId())
      for (const key in _files) {
        const item = _files[key]
        const creator = await context.userService.getPublicUser(item.creatorId)
        item['creator'] = creator
      }
      setFiles(_files)
    } catch (error) {
      setErrorMessage(mapErrorMessage(error))
      console.log('Error get files... ', error)
    }
  }

  const deleteFile = useCallback(async fileId => {
    try {
      if (fileId) {
        await context.orgService.deleteFile(fileId)
        await getFiles()
      }
    } catch (error) {
      setErrorMessage(mapErrorMessage(error))
      console.log('File delete error... ', error)
    }
  }, [])

  return (
    <Fragment>
      <List className={classes.root}>
        {loading && <Loading />}
        {files.length > 0 ? (
          <Fragment>
            <ListSubheader className={classes.listHeader}>
              <Grid container alignItems={'center'} className={classes.listHeaderContainer}>
                <Typography variant={'body1'}>{t('file_type')}</Typography>
                <Typography variant={'body1'}>{t('type_info')}</Typography>
                <Typography variant={'body1'}>{t('file')}</Typography>
                <Typography className={classes.smallColumn} variant={'body1'}>
                  {t('extension')}
                </Typography>
                <Typography className={classes.smallColumn} variant={'body1'}>
                  {t('size')}
                </Typography>
                <Typography variant={'body1'}>{t('creator')}</Typography>
              </Grid>
            </ListSubheader>
            <Divider />
            <Virtuoso
              data={files}
              style={VIRTUOSO_STYLE}
              totalCount={files.length}
              itemContent={(index, item) => <FileRow index={index} item={item} classes={classes} context={context} onLoading={loading => setLoading(loading)} onDeleteFile={deleteFile} />}
              components={{
                Item: props => <ItemContainer {...props} className={classes.listItem} />,
                Footer: () => <div className={classes.virtuosoFooter}></div>,
              }}></Virtuoso>
          </Fragment>
        ) : (
          <Typography className={classes.noFilesText} color={'textSecondary'} variant="body2">
            {t('no_files_available')}
          </Typography>
        )}
      </List>
      <Fab onClick={() => setUploadFileDialogVisible(true)} color="primary" aria-label="Upload-File" className={classes.fab}>
        <PublishIcon />
      </Fab>
      {uploadFileDialogVisible && (
        <UploadFileDialog
          open={true}
          onClose={async () => {
            setUploadFileDialogVisible(false)
            await getFiles()
          }}
          api={api}
          org={org}
        />
      )}
      {errorMessage && <AlertDialog open={true} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
    </Fragment>
  )
}

export default Files
