import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import MaterialTable from '../Common/MaterialTable';

import AlertDialog from '../Common/AlertDialog';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import { PageContext } from '../../App';
import moment from 'moment';
import { PARAM_NICKNAME } from '../../m2m-cloud-api/Api/UserService/Models/User';

const DeviceActivationsDialog = ({ open, device, onClose }) => {
    const { t } = useTranslation();
    const context = useContext(PageContext);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await context.deviceService.getDeviceActivationsData(device.getId());
            setTableData(await handleActivationsData(data));
        } catch (error) {
            console.log('DeviceActivationsDialog error... ', error);
            setErrorMessage(mapErrorMessage(error));
        }
        setLoading(false);
    }, []);

    const handleActivationsData = useCallback(async entries => {
        const handledData = [];
        const orgs = await context.orgService.searchOrg({ from: 0, size: 10000 });

        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            const org = orgs.find(item => item.getId() === entry.ownerId);

            let activator = entry.activator?.ts ? `${moment(entry.activator.ts).format('YYYY-MM-DD HH:mm:ss.SSS')} ` : '-';
            if (entry.activator?.type === 'user') {
                const user = await context.userService.getPublicUser(entry.activator.id);
                activator += `(${user.getParam(PARAM_NICKNAME)})`;
            } else {
                activator += `(${entry.activator.type}: ${entry.activator.id})`;
            }

            let deactivator = entry.deactivator?.ts ? `${moment(entry.deactivator.ts).format('YYYY-MM-DD HH:mm:ss.SSS')} ` : '-';
            if (entry.deactivator?.type === 'user') {
                const user = await context.userService.getPublicUser(entry.deactivator.id);
                deactivator += `(${user.getParam(PARAM_NICKNAME)})`;
            } else {
                deactivator += entry.deactivator?.type ? `(${entry.deactivator.type}: ${entry.deactivator.id})` : ''
            }

            const tableData = {
                org: org?.getName() || entry.ownerId,
                activator,
                deactivator,
            };
            handledData.push(tableData);
        }
        return handledData;
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'} aria-labelledby="form-dialog-title">
            {loading && <LinearProgress className={classes.progress} />}
            <DialogTitle id="form-dialog-title">
                <Typography>{t('device_activation_data_title', { id: device?.getPhysicalId() || '' })}</Typography>
            </DialogTitle>
            <DialogContent>
                <MaterialTable
                    localization={{
                        body: { emptyDataSourceMessage: t('no_data_available') },
                    }}
                    columns={[
                        {
                            title: t('org'),
                            field: 'org',
                        },
                        {
                            title: t('activator'),
                            field: 'activator',
                        },
                        {
                            title: t('deactivator'),
                            field: 'deactivator',
                        },
                    ]}
                    data={tableData}
                />
            </DialogContent>

            <DialogActions>
                <Button disabled={loading} onClick={onClose} type="submit">
                    {t('close')}
                </Button>
            </DialogActions>

            {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
        </Dialog>
    );
};

const useStyles = makeStyles(theme => ({
    eventDate: {
        fontSize: theme.font.small,
    },
    progress: {
        position: 'absolute',
        width: '100%',
    },
    tableHeader: {
        backgroundColor: 'transparent',
    },
}));

export default memo(DeviceActivationsDialog);
