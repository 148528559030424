import React from 'react'
import { PageContext } from '../../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ROOT_NODE_ID } from './OrgListView'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import OrgListViewDialog from './OrgListViewDialog'

class OrgMoveDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      orgItems: null,
      errorMessage: null,
    }
  }

  componentWillMount() {
    this.queryAllOrgs()
  }

  queryAllOrgs() {
    this.context.orgService.searchOrg({ from: 0, size: 10000 }).then(items => {
      this.setState({ orgItems: items })
    })
  }

  handleSubmit(newOrg) {
    const { t } = this.props

    this.setState({ loading: true, errorMessage: null })

    const orgId = this.props.org.getId()
    const newParentId = newOrg === ROOT_NODE_ID ? null : newOrg.getId()
    const promises = [this.context.orgService.move(orgId, newParentId)]

    this.context.orgService
      .updateOrg(orgId, promises)
      .then(results => {
        this.props.onSuccess()
      })
      .catch(error => {
        this.setState({
          errorMessage: mapErrorMessage(error),
        })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { loading, errorMessage, orgItems } = this.state
    const { t, org, classes, open, onCancel } = this.props

    let deactivatedOrgs = []
    if (org) {
      deactivatedOrgs.push(org.getId())
    }

    return (
      <OrgListViewDialog
        title={t('move_org')}
        orgItems={orgItems}
        open={open}
        onCancel={onCancel}
        onSelect={this.handleSubmit.bind(this)}
        onSelectLabel={t('move')}
        renderRootNode={true}
        deactivatedOrgs={deactivatedOrgs}
      />
    )
  }
}

OrgMoveDialog.contextType = PageContext

OrgMoveDialog.propTypes = {
  open: PropTypes.bool,
  org: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

export default withTranslation()(withStyles(styles)(OrgMoveDialog))
