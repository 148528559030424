import XLSX from 'xlsx';

/**
 * export data to excel
 * @param {Array} fields
 * @param {Array} exportData
 * @returns {File}
 */
export const exportDataToExcel = (fields, exportData, fileName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(exportData, { header: fields });
  XLSX.utils.book_append_sheet(wb, ws, 'Export');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
