import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { PageContext } from '../../Context/PageProvider'
import { FormField, FIELD_TYPE } from '../Form/FormFieldHelper'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { v4 as uuidv4 } from 'uuid'

const FIELD_ID_DEVICE_ORG_ID = `deviceOrgId`
const FIELD_ID_DEVICE_ID = `deviceId`
const FIELD_ID_ACTION_ORG_ID = `actionOrgId`
const FIELD_ID_ACTION_ID = `actionId`
const FIELD_ID_TEMPLATE_ORG_ID = `templateOrgId`
const FIELD_ID_TEMPLATE_ID = `templateId`
const FIELD_ID_EVENT_ID = `events`
const FIELD_ID_PARAMS = `params`

const ALL_FIELDS = [FIELD_ID_DEVICE_ORG_ID, FIELD_ID_DEVICE_ID, FIELD_ID_ACTION_ORG_ID, FIELD_ID_ACTION_ID, FIELD_ID_TEMPLATE_ORG_ID, FIELD_ID_TEMPLATE_ID, FIELD_ID_EVENT_ID, FIELD_ID_PARAMS]

class DefinitionDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      values: {},
      loading: false,
      errorMessage: null,
      orgItems: null,
      deviceItems: null,
      templateItems: null,
      actionItems: null,
      eventItems: null,
    }
  }

  componentDidMount() {
    const orgItems = this.context.orgService.getOrgListFromCache()

    const loadData = () => {
      const deviceOrgId = this.state.values[FIELD_ID_DEVICE_ORG_ID]
      const templateOrgId = this.state.values[FIELD_ID_TEMPLATE_ORG_ID]
      const actionOrgId = this.state.values[FIELD_ID_ACTION_ORG_ID]

      const promisses = [this.loadUnusedDevicesFromOrg(deviceOrgId), this.context.appMessageService.getTemplates(templateOrgId), this.context.appMessageService.getActions(actionOrgId)]

      Promise.all(promisses)
        .then(results => {
          const deviceItems = results[0]
          const templateItems = results[1]
          const actionItems = results[2]

          console.warn('deviceItems', deviceItems, templateItems, actionItems)

          this.setState(
            {
              deviceItems,
              templateItems,
              actionItems,
              loading: false,
            },
            () => {
              if (this.isEditMode()) {
                this.getEventsFromSelectedDevice()
              }
            },
          )
        })
        .catch(error => {
          console.error('deviceItems', error)
          this.setState({
            deviceItems: null,
            templateItems: null,
            actionItems: null,
            eventItems: null,
            loading: false,
            errorMessage: mapErrorMessage(error),
          })
        })
    }

    this.setState({ errorMessage: null, orgItems, loading: true, values: this.getDefaultValues() }, () => {
      if (this.isEditMode()) {
        const { definition } = this.props

        Promise.all([
          this.context.deviceService.getDevice(definition.getDeviceId()),
          this.context.appMessageService.getAction(definition.getActionId()),
          this.context.appMessageService.getTemplate(definition.getTemplateId()),
        ])
          .then(results => {
            const device = results[0]
            const action = results[1]
            const template = results[2]

            //const device = devices && devices.items && devices.items.length > 0 ? devices.items[0] : null
            let values = this.state.values
            values[FIELD_ID_DEVICE_ORG_ID] = device.getAssignedOrg()
            values[FIELD_ID_TEMPLATE_ORG_ID] = template.getOrgId()
            values[FIELD_ID_ACTION_ORG_ID] = action.getOrgId()

            this.setState({ values, loading: false }, () => loadData())
          })
          .catch(error => {
            console.error('deviceItems', error)
            this.setState({
              deviceItems: null,
              templateItems: null,
              actionItems: null,
              eventItems: null,
              loading: false,
              errorMessage: mapErrorMessage(error),
            })
          })
      } else {
        loadData()
      }
    })
  }

  loadUnusedDevicesFromOrg(orgId) {
    const { values } = this.state

    return this.context.deviceService.searchDevice(orgId).then(deviceItems => {
      const deviceItemUids = []
      deviceItems &&
        deviceItems.map(device => {
          if (device.getId() && device.getAssignedOrg() === orgId) {
            deviceItemUids.push(device.getId())
          }
        })
      if (deviceItemUids) {
        return this.context.appMessageService.getUnusedDevices(deviceItemUids).then(unusedDeviceIds => {
          return deviceItems
            .filter(device => unusedDeviceIds.indexOf(device.getId()) >= 0 || (this.isEditMode() && device.getId() === values[FIELD_ID_DEVICE_ID]))
            .sort((a, b) => {
              const aTitle = a.getDriver().toUpperCase() + ' - ' + a.getPhysicalId()
              const bTitle = b.getDriver().toUpperCase() + ' - ' + b.getPhysicalId()
              return aTitle.localeCompare(bTitle)
            })
        })
      } else {
        return []
      }
    })
  }

  loadDevicesFromSelectedOrg() {
    const orgId = this.state.values[FIELD_ID_DEVICE_ORG_ID]
    this.loadUnusedDevicesFromOrg(orgId).then(deviceItems => {
      this.setState({ deviceItems })
    })
  }

  loadTemplatesFromSelectedOrg() {
    const orgId = this.state.values[FIELD_ID_TEMPLATE_ORG_ID]
    this.context.appMessageService.getTemplates(orgId).then(templateItems => {
      this.setState({
        templateItems,
      })
    })
  }

  loadActionsFromSelectedOrg() {
    const orgId = this.state.values[FIELD_ID_ACTION_ORG_ID]
    this.context.appMessageService.getActions(orgId).then(actionItems => {
      this.setState({
        actionItems,
      })
    })
  }

  isEditMode() {
    const { definition } = this.props
    return definition ? true : false
  }

  getDefaultValues() {
    const { org, definition } = this.props
    let values = {}
    if (!this.isEditMode() && this.context.sessionStorage) {
      const lastUsedDeviceOrg = this.context.sessionStorage.getLastUsedDefinitionDeviceOrg()
      if (lastUsedDeviceOrg) {
        values[FIELD_ID_DEVICE_ORG_ID] = lastUsedDeviceOrg
      }
      const lastUsedTemplate = this.context.sessionStorage.getLastUsedDefinitionTemplate()
      if (lastUsedTemplate) {
        values[FIELD_ID_TEMPLATE_ORG_ID] = lastUsedTemplate.orgUid
        values[FIELD_ID_TEMPLATE_ID] = lastUsedTemplate.templateUid
      }
      const lastUsedAction = this.context.sessionStorage.getLastUsedDefinitionAction()
      if (lastUsedAction) {
        values[FIELD_ID_ACTION_ORG_ID] = lastUsedAction.orgUid
        values[FIELD_ID_ACTION_ID] = lastUsedAction.actionUid
      }
    }
    ALL_FIELDS.map(fieldId => {
      if (this.isEditMode()) {
        values[fieldId] = definition[fieldId]
      } else {
        if (fieldId === FIELD_ID_DEVICE_ORG_ID || fieldId === FIELD_ID_TEMPLATE_ORG_ID || fieldId === FIELD_ID_ACTION_ORG_ID) {
          if (!values[fieldId]) {
            values[fieldId] = org.getId()
          }
        } else if (fieldId === FIELD_ID_EVENT_ID) {
          values[fieldId] = []
        } else if (fieldId === FIELD_ID_PARAMS) {
          values[fieldId] = {}
        } else {
          if (!values[fieldId]) {
            values[fieldId] = ''
          }
        }
      }
    })
    return values
  }

  getEventsFromSelectedDevice() {
    const { values } = this.state
    const deviceId = values[FIELD_ID_DEVICE_ID]

    this.setState({ loading: true, errorMessage: null, eventItems: null })

    return this.context.deviceService
      .readEventDescs(deviceId)
      .then(eventItems => {
        this.setState({ eventItems, loading: false })
        return eventItems
      })
      .catch(error => {
        this.setState({ eventItems: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  handleSubmit() {
    const { org, definition } = this.props
    const { values } = this.state

    this.setState({ loading: true, errorMessage: null })

    const orgId = org.getId()
    const deviceId = values[FIELD_ID_DEVICE_ID]
    const actionId = values[FIELD_ID_ACTION_ID]
    const templateId = values[FIELD_ID_TEMPLATE_ID]
    const events = values[FIELD_ID_EVENT_ID]
    const params = values[FIELD_ID_PARAMS] || {}

    let promise = null
    if (this.isEditMode()) {
      const definitionId = definition.getId()
      promise = this.context.appMessageService.updateDefinition(definitionId, actionId, params, orgId, templateId)
    } else {
      const definitionId = uuidv4()
      promise = this.context.appMessageService.registerDefinition(definitionId, actionId, deviceId, events, params, orgId, templateId)
    }

    promise
      .then(result => {
        const deviceOrgId = values[FIELD_ID_DEVICE_ORG_ID]
        const templateOrgId = values[FIELD_ID_TEMPLATE_ORG_ID]
        const actionOrgId = values[FIELD_ID_ACTION_ORG_ID]

        this.context.sessionStorage.setLastUsedDefinitionDeviceOrg(deviceOrgId)
        this.context.sessionStorage.setLastUsedDefinitionTemplate({ orgUid: templateOrgId, templateUid: templateId })
        this.context.sessionStorage.setLastUsedDefinitionAction({ orgUid: actionOrgId, actionUid: actionId })

        this.props.onSuccess()
      })
      .catch(error => {
        console.warn('add definition, error: ', error)
        this.setState({
          errorMessage: mapErrorMessage(error),
        })
      })
      .finally(() => this.setState({ loading: false }))
  }

  renderDeviceFields() {
    const { values, deviceItems, eventItems, orgItems } = this.state
    const { t, classes } = this.props

    //build device options
    const deviceOptions = []
    deviceItems &&
      deviceItems.map(device => {
        deviceOptions.push({
          value: device.getId(),
          title: device.getDriver().toUpperCase() + ' - ' + device.getPhysicalId(),
        })
      })

    // build event options
    const eventOptions = []
    eventItems &&
      eventItems.map(event => {
        eventOptions.push({
          value: event.getName(),
          title: event.getName(),
        })
      })

    return (
      <Fragment>
        <Typography className={classes.sectionHeader} variant="subtitle1" gutterBottom>
          {t('device')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormField
              id={FIELD_ID_DEVICE_ORG_ID}
              disabled={this.isEditMode()}
              fieldType={FIELD_TYPE.ORG_SELECT}
              value={values[FIELD_ID_DEVICE_ORG_ID]}
              renderRootNode={false}
              options={orgItems}
              title={t('org')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={value => {
                let _values = values
                _values[FIELD_ID_DEVICE_ORG_ID] = value
                _values[FIELD_ID_DEVICE_ID] = ''
                _values[FIELD_ID_EVENT_ID] = []
                this.setState({ values: _values, eventItems: null, errorMessage: null }, () => this.loadDevicesFromSelectedOrg())
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormField
              id={FIELD_ID_DEVICE_ID}
              disabled={this.isEditMode()}
              fieldType={FIELD_TYPE.SELECT}
              value={values[FIELD_ID_DEVICE_ID]}
              options={deviceOptions}
              title={t('device')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={event => {
                let _values = values
                _values[FIELD_ID_DEVICE_ID] = event.target.value
                _values[FIELD_ID_EVENT_ID] = []
                this.setState({ values: _values, errorMessage: null }, () => this.getEventsFromSelectedDevice())
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormField
              id={FIELD_ID_EVENT_ID}
              fieldType={FIELD_TYPE.SELECT_MULTIFIELD}
              value={values[FIELD_ID_EVENT_ID]}
              disabled={this.isEditMode() || eventItems === null}
              options={eventOptions}
              title={t('events')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={event => {
                let _values = values
                _values[FIELD_ID_EVENT_ID] = event.target.value
                this.setState({ values: _values, errorMessage: null })
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  renderTemplateFields() {
    const { values, templateItems, orgItems } = this.state
    const { t, classes } = this.props

    //build template options
    const templateOptions = []
    templateItems &&
      templateItems.map(template => {
        templateOptions.push({
          value: template.getId(),
          title: template.getName(),
        })
      })

    return (
      <Fragment>
        <Typography className={classes.sectionHeader} variant="subtitle1" gutterBottom>
          {t('template')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              id={FIELD_ID_TEMPLATE_ORG_ID}
              fieldType={FIELD_TYPE.ORG_SELECT}
              value={values[FIELD_ID_TEMPLATE_ORG_ID]}
              renderRootNode={false}
              options={orgItems}
              title={t('org')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={value => {
                let _values = values
                _values[FIELD_ID_TEMPLATE_ORG_ID] = value
                _values[FIELD_ID_TEMPLATE_ID] = ''
                this.setState({ values: _values, errorMessage: null }, () => this.loadTemplatesFromSelectedOrg())
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              id={FIELD_ID_TEMPLATE_ID}
              fieldType={FIELD_TYPE.SELECT}
              value={values[FIELD_ID_TEMPLATE_ID]}
              options={templateOptions}
              title={t('template')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={event => {
                let _values = values
                _values[FIELD_ID_TEMPLATE_ID] = event.target.value
                this.setState({ values: _values, errorMessage: null })
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  renderActionFields() {
    const { values, actionItems, orgItems } = this.state
    const { t, classes } = this.props

    //build action options
    const actionOptions = []
    actionItems &&
      actionItems.map(action => {
        actionOptions.push({
          value: action.getId(),
          title: action.getName(),
        })
      })

    return (
      <Fragment>
        <Typography className={classes.sectionHeader} variant="subtitle1" gutterBottom>
          {t('action')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              id={FIELD_ID_ACTION_ORG_ID}
              fieldType={FIELD_TYPE.ORG_SELECT}
              value={values[FIELD_ID_ACTION_ORG_ID]}
              renderRootNode={false}
              options={orgItems}
              title={t('org')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={value => {
                let _values = values
                _values[FIELD_ID_ACTION_ORG_ID] = value
                _values[FIELD_ID_ACTION_ID] = ''
                this.setState({ values: _values, errorMessage: null }, () => this.loadActionsFromSelectedOrg())
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              id={FIELD_ID_ACTION_ID}
              fieldType={FIELD_TYPE.SELECT}
              value={values[FIELD_ID_ACTION_ID]}
              options={actionOptions}
              title={t('action')}
              validators={['required']}
              errorMessages={['this_field_is_required']}
              onChange={event => {
                let _values = values
                _values[FIELD_ID_ACTION_ID] = event.target.value
                this.setState({ values: _values, errorMessage: null })
              }}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  renderTemplateVariableFields() {
    const { values, templateItems } = this.state
    const { t, classes } = this.props

    const templateId = values[FIELD_ID_TEMPLATE_ID]
    if (templateId) {
      const template = templateItems.find(template => template.getId() === templateId)
      const headerAndBody = template ? `${template.getHeader()} ${template.getBody()}` : ''
      const variables = []
      const matchedVariables = headerAndBody.match(/(\$def:[a-zA-ZÀ-ÿ0-9-_]+)/g)
      if (matchedVariables && matchedVariables.length > 0) {
        matchedVariables.map(variable => {
          const variableName = variable.substr(5)
          if (variables.indexOf(variableName) === -1) {
            variables.push(variableName)
          }
        })

        if (variables && variables.length > 0) {
          const params = values[FIELD_ID_PARAMS] || {}
          return (
            <Fragment>
              <Typography className={classes.sectionHeader} variant="subtitle1" gutterBottom>
                {t('template_replacements')}
              </Typography>
              <Grid container spacing={3}>
                {variables.map(variable => (
                  <Grid item xs={6} key={FIELD_ID_PARAMS + '-' + variable}>
                    <FormField
                      id={FIELD_ID_PARAMS + '-' + variable}
                      title={variable}
                      fieldType={FIELD_TYPE.TEXT_FIELD}
                      value={params[variable] || ''}
                      validators={[]}
                      errorMessages={[]}
                      onChange={event => {
                        let _values = values
                        let params = values[FIELD_ID_PARAMS] || {}
                        params[variable] = event.target.value
                        _values[FIELD_ID_PARAMS] = params
                        console.warn('_values', _values)
                        this.setState({ values: _values, errorMessage: null })
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          )
        }
      }
    }
    return null
  }

  render() {
    const { values, orgItems, deviceItems, templateItems, actionItems, eventItems, loading, errorMessage } = this.state
    const { t, classes } = this.props

    if (orgItems === null) return null
    if (deviceItems === null) return null
    if (templateItems === null) return null
    if (actionItems === null) return null

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.isEditMode() ? t('edit_definition') : t('add_definition')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              {this.renderDeviceFields()}
              {this.renderTemplateFields()}
              {this.renderTemplateVariableFields()}
              {this.renderActionFields()}
              {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel}>
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {this.isEditMode() ? t('edit_definition') : t('add_definition')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

DefinitionDialog.contextType = PageContext

DefinitionDialog.propTypes = {
  open: PropTypes.bool,
  org: PropTypes.any.isRequired,
  definition: PropTypes.any,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
  sectionHeader: {
    marginTop: 30,
  },
})

export default withTranslation()(withStyles(styles)(DefinitionDialog))
