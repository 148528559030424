import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import history from '../History'
import { Api, UserService, OrgService, AuthzService, DeviceService, AppMessageService, MemoryCache, ERROR } from '../m2m-cloud-api'
import i18n from '../i18n'
import WebStorage from '../m2m-cloud-api/Storage/WebStorage'
import SessionStore from './SessionStorage'
import ReactSVG from 'react-svg'
import { getPath } from '../App'
import { service } from '../index'
import DrawerMenu from './DrawerMenu'

import OrgIcon from '@material-ui/icons/Work'
import PersonIcon from '@material-ui/icons/Person'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import DvrIcon from '@material-ui/icons/Dvr'

export const PageContext = React.createContext()

class PageProvider extends Component {
  constructor(props) {
    super(props)

    this.sessionStorage = null
    this.orgCache = new MemoryCache()

    this.initSessionStorage = () => {
      const user = this.userService.getActiveUser()
      if (user) {
        this.sessionStorage = new SessionStore(user.getUserId())
      }
    }

    this.api = new Api(service.api.domain, new WebStorage(), false)
    this.userService = new UserService(
      this.api,
      () => {
        // login
        this.initSessionStorage()
      },
      () => {
        // logout
        this.sessionStorage = null
        history.push(`/auth`)
        //window.location.reload(true)
      },
    )
    this.orgService = new OrgService(this.api)
    this.authzService = new AuthzService(this.api)
    this.deviceService = new DeviceService(this.api)
    this.appMessageService = new AppMessageService(this.api)

    this.state = {
      ready: false,
      drawerMenuopen: false,
    }

    this.getDrawerMenuItems = () => [
      {
        title: this.props.t('org_page_title'),
        icon: OrgIcon,
        path: getPath(),
      },
      {
        title: this.props.t('device_management'),
        icon: () => <ReactSVG src="/assets/device.svg" />,
        path: getPath('device_management'),
      },
      {
        title: this.props.t('monitoring'),
        icon: DvrIcon,
        path: getPath('monitoring'),
      },
      {
        title: this.props.t('user_page_title'),
        icon: PersonIcon,
        path: getPath('user'),
      },
      {
        title: this.props.t('access_management'),
        icon: VpnKeyIcon,
        path: getPath('access'),
      },
    ]

    this.userService
      .initSession()
      .then(() => {
        this.setUserLanguageIfExists()
        this.initSessionStorage()
        this.setState({ ready: true })
      })
      .catch(error => {
        console.log('error', error)
        this.setState({ ready: true }, () => {
          const errorType = error && error.getErrorType && error.getErrorType()
          if (errorType === ERROR.FORBIDDEN || errorType === ERROR.UNAUTHORIZED) {
            console.log('session expired')
            this.userService.logoutUser()
            window.location.reload(true)
          } else {
            console.log('error', error, JSON.stringify(error))
          }
        })
      })
  }

  /**
   * fetch orgs
   * @param {Array<UID>} orgIds
   * @param {Boolean} useOrgCache
   */
  async fetchOrgs(orgIds, useOrgCache = true) {
    if (!useOrgCache) this.orgCache.resetCache()
    const orgs = []
    const orgIdsToLoad = []
    for (let i = 0; i < orgIds.length; i++) {
      const orgId = orgIds[i]
      if (!orgId) continue
      const hasOrgId = orgs.find(org => org.getId() === orgId)
      if (!hasOrgId) {
        let org = this.orgCache.getItem(orgId)
        if (org) {
          orgs.push(org)
          continue
        }
        if (orgIdsToLoad.indexOf(orgId) === -1) {
          orgIdsToLoad.push(orgId)
        }
      }
    }

    if (orgIdsToLoad.length > 0) {
      let loadedOrgs = []
      try {
        loadedOrgs = await this.orgService.readMultiple(orgIdsToLoad)
        loadedOrgs.map(org => {
          this.orgCache.upsertItem(org)
          if (org) orgs.push(org)
        })
      } catch (error) {
        console.warn('readMultiple error', error)
      }
    }

    return orgs
  }

  /**
   * set user language if exists
   */
  setUserLanguageIfExists() {
    const user = this.userService.getActiveUser()
    const userLanguage = user && user.getUserLanguage()
    if (userLanguage) {
      i18n.changeLanguage(userLanguage)
    }
  }

  render() {
    return (
      <PageContext.Provider
        value={{
          userService: this.userService,
          orgService: this.orgService,
          authzService: this.authzService,
          appMessageService: this.appMessageService,
          deviceService: this.deviceService,
          sessionStorage: this.sessionStorage,
          fetchOrgs: this.fetchOrgs.bind(this),
          openDrawerMenu: () => this.setState({ drawerMenuopen: true }),
        }}>
        {this.state.ready && (
          <Fragment>
            <DrawerMenu
              open={this.state.drawerMenuopen}
              items={this.getDrawerMenuItems()}
              onOpen={() => this.setState({ drawerMenuopen: true })}
              onClose={() => this.setState({ drawerMenuopen: false })}
            />
            {this.props.children}
          </Fragment>
        )}
      </PageContext.Provider>
    )
  }
}

const PageConsumer = PageContext.Consumer

const styles = theme => ({})

export default withTranslation()(withStyles(styles)(PageProvider))
export { PageConsumer }
