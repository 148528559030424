import React, { useContext, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Typography, IconButton, Menu, MenuItem, ListItemIcon, Table, TableBody, TableHead, TableRow, TableCell, Paper, AppBar, Toolbar } from '@material-ui/core'
import { PARAM_FIRST_NAME, PARAM_LAST_NAME, PARAM_NAME, PARAM_DESCRIPTION, PARAM_NICKNAME } from '../../m2m-cloud-api/Api/UserService/Models/User'
import { PageContext } from '../../Context/PageProvider'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import PasswordIcon from '@material-ui/icons/VpnKey'
import ParamsIcons from '@material-ui/icons/Reorder'
import LockOpenIcon from '@material-ui/icons/LockOpen'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
  },
  appBar: {
    boxShadow: 'none',
  },
  title: {
    flex: 1,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tablePaper: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  tableWrapper: {},
  tableRow: {},
  tableCell: {
    borderColor: 'rgba(255,255,255,0.03)',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: 0,
  },
  tableCellRight: {
    paddingRight: '0 !important',
  },
  chipContainer: {
    marginBottom: theme.spacing(3),
  },
  itemHeader: {
    marginTop: theme.spacing(3),
  },
}))

function UserView({ onEditClick, onEditParamsClick, onPasswordChangeClick, onLogoutClick, onMultiFactorClick }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  let context = useContext(PageContext)
  const user = context.userService.getActiveUser()
  const params = user.getParams()

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} color="transparent">
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h5" noWrap>
              {user.isTechnicalUser() ? user.getParam(PARAM_NAME) : `${user.getParam(PARAM_FIRST_NAME)} ${user.getParam(PARAM_LAST_NAME)}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user.getUserId()}
            </Typography>
          </div>
          <IconButton onClick={event => setMenuAnchorEl(event.currentTarget)} edge="end">
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Typography variant="h6" className={classes.itemHeader} component="h6">
          {user.getEmail()}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {t('email')}
        </Typography>
        <Typography variant="h6" className={classes.itemHeader} component="h6">
          {user.isTechnicalUser() ? user.getParam(PARAM_DESCRIPTION) : user.getParam(PARAM_NICKNAME)}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {user.isTechnicalUser() ? t('description') : t('nickname')}
        </Typography>
        {params && Object.keys(params).length > 0 && (
          <Fragment>
            <Typography variant="h6" className={classes.itemHeader} component="h6">
              {t('parameters')}
            </Typography>
            <Paper className={classes.tablePaper}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('key')}</TableCell>
                      <TableCell align="right" className={[classes.tableCell, classes.tableCellRight]}>
                        {t('value')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(params)
                      .sort()
                      .map(key => (
                        <TableRow key={key} className={classes.tableRow}>
                          <TableCell component="th" scope="row" className={classes.tableCell}>
                            {key}
                          </TableCell>
                          <TableCell align="right" className={[classes.tableCell, classes.tableCellRight]}>
                            {params[key]}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </Paper>
          </Fragment>
        )}
      </div>
      {menuAnchorEl && (
        <Menu id="menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)}>
          <MenuItem
            onClick={event => {
              setMenuAnchorEl(null)
              onEditClick()
            }}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit"> {t('edit')}</Typography>
          </MenuItem>
          <MenuItem
            onClick={event => {
              setMenuAnchorEl(null)
              onEditParamsClick()
            }}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <ParamsIcons fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit"> {t('edit_parameters')}</Typography>
          </MenuItem>
          <MenuItem
            onClick={event => {
              setMenuAnchorEl(null)
              onPasswordChangeClick()
            }}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <PasswordIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit"> {t('change_password')}</Typography>
          </MenuItem>

          <MenuItem
            onClick={event => {
              setMenuAnchorEl(null)
              onMultiFactorClick()
            }}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <LockOpenIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit"> {t('multi_factor_authentication')}</Typography>
          </MenuItem>

          <MenuItem
            onClick={event => {
              setMenuAnchorEl(null)
              onLogoutClick()
            }}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit"> {t('logout')}</Typography>
          </MenuItem>
        </Menu>
      )}
    </div>
  )
}

export default UserView
