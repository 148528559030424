export function getURLParameters(searchStr = null) {
    var params = {};
    const search = searchStr || window.location.search;
    if (search) {
        var parts = search.substring(1).split('&');

        for (var i = 0; i < parts.length; i++) {
            var nv = parts[i].split('=');
            if (!nv[0]) continue;
            params[nv[0]] = nv[1] || true;
        }
    }
    return params;
}


export const calcProgressPercentage = (progress = 0, length = 0) => (progress / length) * 100