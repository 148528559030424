import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import OrgListViewDialog from '../Org/OrgListViewDialog'
import AlertDialog from '../Common/AlertDialog'
import { mapErrorMessage } from '../../Utilities/ApiHelper'

const DeviceOrgSelection = ({ open, title, sumitButtonTitle, onCancel, onSelect, deactivatedOrgs = [] }) => {
    const { t } = useTranslation()
    const context = useContext(PageContext)

    const [loading, setLoading] = useState(false)
    const [orgs, setOrgs] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const searchOrg = async () => {
            setLoading(true)
            try {
                const _orgs = await context.orgService.searchOrg({ from: 0, size: 10000 })
                setOrgs(_orgs)
            } catch (error) {
                setError(mapErrorMessage(error))
            }
            setLoading(false)
        }

        searchOrg()
    }, [])

    return (
        <>
            {
                <OrgListViewDialog
                    title={title}
                    loading={loading}
                    orgItems={orgs || []}
                    activeOrgId={null}
                    open={!error && open}
                    onCancel={onCancel}
                    onSelect={onSelect}
                    onSelectLabel={sumitButtonTitle || t('assign')}
                    renderRootNode={false}
                    deactivatedOrgs={deactivatedOrgs}
                />
            }
            <AlertDialog open={error ? true : false} title={t('error')} message={error} onSubmit={onCancel} />
        </>
    )
}

export default DeviceOrgSelection
