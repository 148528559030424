import React, { useEffect, useMemo, useState, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { TextField, Paper } from '@material-ui/core'
import { PageContext } from '../../Context/PageProvider'
import { PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES } from '../../m2m-cloud-api/Api/OrgService/Models/Org'

function MonitoringOrgSelect({ defaultReportOrgId, onChange, className }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const context = useContext(PageContext)
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState(null)

  const userFavourites = (context.userService.getActiveUser().getParam(PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES)?.split(',') || []).filter(orgId => orgId && orgId.trim() !== '')
  useEffect(async () => {
    const orgs = await context.fetchOrgs([...userFavourites, defaultReportOrgId])
    const orgOptions = orgs.map(org => ({ title: org.getName(), id: org.getId() })).sort((a, b) => a.title.localeCompare(b.title))
    setOptions(orgOptions)
    setSelectedOption(orgOptions.find(option => option.id === defaultReportOrgId))
  }, [userFavourites.join(','), defaultReportOrgId])

  if (options === null || !selectedOption) return null

  return (
    <Paper className={className ? [classes.paper, className].join(' ') : classes.paper}>
      <Autocomplete
        id="combo-box-report-org"
        options={options}
        blurOnSelect
        disableClearable
        value={selectedOption}
        onChange={(event, option) => {
          setSelectedOption(option)
          onChange(option.id)
        }}
        getOptionSelected={option => option.id === selectedOption.id}
        getOptionLabel={option => option.title}
        style={{ width: 250 }}
        renderInput={params => <TextField className={classes.textField} {...params} />}
      />
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: 'none',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  textField: {
    '& .MuiInput-underline:before': {
      border: 'none',
    },
  },
}))

export default MonitoringOrgSelect
