import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { isNotFoundError } from '../../m2m-cloud-api/Api/Helper'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { Autocomplete } from '@material-ui/lab'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    LinearProgress,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import PropList from '../Common/PropList'
import { useTranslation } from 'react-i18next'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { calcProgressPercentage } from '../../Helper'

function DevicesSendCommandDialog({ api, open, devices, sendCommandsFilter, onCancel, onSuccess }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [commandName, setCommandName] = useState('')
    const [commandValue1, setCommandValue1] = useState('')
    const [commandValue2, setCommandValue2] = useState('')
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [params, setParams] = useState([])
    const [availableCommands, setAvailableCommands] = useState(null)
    const [progressValue, setProgressValue] = useState(0)

    useEffect(() => {
        const readCommandDescs = async () => {
            let commands = []
            if (sendCommandsFilter) {
                commands = sendCommandsFilter?.map(cmd => ({ name: cmd })) ?? []
            } else {
                if (devices?.length === 1) {
                    try {
                        commands = await api.deviceService.readCommandDescs(devices[0].getId())
                    } catch (error) {
                        setErrorMessage(error)
                        setLoading(false)
                        return
                    }
                }
            }
            setAvailableCommands(commands)
            setCommandName(commands?.[0]?.name ?? '')
            setLoading(false)
        }
        readCommandDescs()
    }, [])

    const handleSubmit = useCallback(async () => {
        setErrorMessage(null)
        setLoading(true)
        try {
            const cleanedParams = {}
            params.forEach(item => {
                const key = item?.key?.trim()
                const value = item?.value?.trim()
                if (key && value) {
                    cleanedParams[key] = value
                }
            })

            const data0 = commandValue1?.trim() ?? null
            const data1 = commandValue2?.trim() ?? null
            const fields = Object.keys(cleanedParams).length > 0 ? cleanedParams : null

            for (let i = 0; i < devices.length; i++) {
                const device = devices[i]
                await api.deviceService.sendSta2DeviceCommand(device.getId(), commandName, data0, data1, fields)
                setProgressValue(calcProgressPercentage(i + 1, devices.length))
            }

            onSuccess()
            setLoading(false)
        } catch (error) {
            let message = mapErrorMessage(error)
            if (isNotFoundError(error)) {
                message = t('error_device_not_found', { device: error.originError.detail })
            }
            setErrorMessage(message)
            setLoading(false)
        }
    }, [commandName, commandValue1, commandValue2, params, devices, onSuccess])

    const availableCommandNames = useMemo(() => availableCommands?.map(command => command.name) ?? [], [availableCommands])

    return (
        <div>
            <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
                {loading && <LinearProgress className={classes.progress} variant={'determinate'} value={progressValue} />}
                <DialogTitle id="form-dialog-title">
                    {devices?.length > 1 ? t('send_command_dialog_title_multiple', { count: devices.length }) : t('send_command_dialog_title_single', { id: devices?.[0]?.getPhysicalId?.() ?? '' })}
                </DialogTitle>
                <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log('form error:', errors)}>
                    <DialogContent>
                        <Autocomplete
                            options={availableCommandNames}
                            blurOnSelect
                            value={commandName}
                            onChange={(event, option) => setCommandName(option)}
                            getOptionSelected={option => option === commandName}
                            getOptionLabel={option => option}
                            renderInput={params => (
                                <TextValidator
                                    disabled={loading}
                                    className={classes.field}
                                    label={t('command_name')}
                                    value={commandName}
                                    onChange={event => setCommandName(event.target.value)}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={[t('this_field_is_required')]}
                                    fullWidth
                                    {...params}></TextValidator>
                            )}
                        />

                        <Accordion className={classes.formAccordion} onChange={(value, expanded) => null}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{t('advanced')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextValidator disabled={loading} label={t('value_data_0')} value={commandValue1} onChange={event => setCommandValue1(event.target.value)} margin="dense" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidator disabled={loading} label={t('value_data_1')} value={commandValue2} onChange={event => setCommandValue2(event.target.value)} margin="dense" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.fieldsTitle}>{t('title_fields')}</Typography>
                                        <PropList disabled={loading} params={params} onChange={setParams} minHeight={'auto'} />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={onCancel}>
                            {t('cancel')}
                        </Button>
                        {
                            <Button disabled={loading} type="submit" color="primary">
                                {t('send')}
                            </Button>
                        }
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </div>
    )
}

DevicesSendCommandDialog.propTypes = {
    api: PropTypes.any.isRequired,
    open: PropTypes.bool,
    devices: PropTypes.array,
    sendCommandsFilter: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

export default DevicesSendCommandDialog

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20,
    },
    field: {
        marginTop: 10,
        marginBottom: 10,
    },
    formAccordion: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(255,255,255,0.05)',
    },
    fieldsTitle: {
        marginTop: 10,
        fontWeight: 'bold',
    },
}))
