import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import OrgListViewDialog from '../Org/OrgListViewDialog'
import { mapErrorMessage } from '../../Utilities/ApiHelper'

class DeviceAssignDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      orgItems: null,
      errorMessage: null,
      defaultSelectedOrgid: null,
    }
  }

  componentDidMount() {
    this.setState(
      {
        defaultSelectedOrgid: this.props.api && this.props.api.sessionStorage ? this.props.api.sessionStorage.getLastUsedDeviceAssignOrgId() : null,
      },
      () => this.queryAllOrgs(),
    )
  }

  queryAllOrgs() {
    this.props.api.orgService
      .searchOrg({ from: 0, size: 10000, ...(this.props.searchOrgProps ? this.props.searchOrgProps : {}) })
      .then(items => {
        this.setState({ orgItems: items })
      })
      .catch(error => {
        this.setState({ orgItems: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  handleSubmit(org) {
    this.setState({ loading: true, errorMessage: null })

    const promisses = [this.props.api.deviceService.assignDevice(this.props.device.getId(), org.getId())]
    this.props.api.deviceService
      .updateDevice(this.props.device.getId(), promisses)
      .then(result => {
        if (this.props.api.sessionStorage) {
          this.props.api.sessionStorage.setLastUsedDeviceAssignOrgId(org.getId())
        }
        this.props.onSuccess()
      })
      .catch(error => {
        console.log('assignDevice, error', error)
        this.setState({ errorMessage: mapErrorMessage(error) })
      })
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    const { loading, errorMessage, orgItems, defaultSelectedOrgid } = this.state
    const { t, open, onCancel } = this.props

    return (
      orgItems && (
        <OrgListViewDialog
          title={t('device_assign')}
          orgItems={orgItems}
          activeOrgId={defaultSelectedOrgid}
          open={open}
          onCancel={onCancel}
          onSelect={this.handleSubmit.bind(this)}
          onSelectLabel={t('assign')}
          renderRootNode={false}
          deactivatedOrgs={[]}
        />
      )
    )
  }
}

DeviceAssignDialog.propTypes = {
  api: PropTypes.any.isRequired,
  searchOrgProps: PropTypes.object,
  open: PropTypes.bool,
  device: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

export default withTranslation()(withStyles(styles)(DeviceAssignDialog))
