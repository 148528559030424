import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import AlertDialog from '../Common/AlertDialog'
import { mapErrorMessage } from '../../Utilities/ApiHelper'

const styles = theme => ({
  errorText: {
    color: theme.palette.error.main,
    marginTop: 20,
  },
})

export class InviteUserDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errorMessage: null,
      mail: null,
    }
  }

  render() {
    const { loading, errorMessage } = this.state
    const { t } = this.props
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('invite_user')}</DialogTitle>
          <DialogContent>
            <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
              <TextValidator
                label={t('email')}
                value={this.state.mail}
                onChange={event => {
                  this.setState({ mail: event.target.value })
                }}
                margin="dense"
                validators={['required', 'isEmail']}
                errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
                fullWidth
              />
            </ValidatorForm>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={this.props.onCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
              {t('invite_user')}
            </Button>
          </DialogActions>
        </Dialog>
        {errorMessage && (
          <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
        )}
      </div>
    )
  }

  handleSubmit() {
    const { groupId, api } = this.props
    const { mail } = this.state
    api.userService
      .inviteToGroup(groupId, mail)
      .then(success => {
        this.setState({ mail: null, loading: false })
        this.props.onSuccess()
      })
      .catch(error => {
        this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }
}

InviteUserDialog.contextType = PageContext

InviteUserDialog.propTypes = {
  api: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
}

export default withTranslation()(withStyles(styles)(InviteUserDialog))
