import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import toCsv from 'react-csv-downloader/dist/lib/csv';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertDialog from '../Common/AlertDialog';

class UnClaimDeviceDialog extends React.Component {
  state = {
    confirmed: false,
    loading: false,
    results: null,
  };

  unclaimDevice = async () => {
    const { devices, onSuccess } = this.props;
    this.setState({ loading: true, confirmed: true });
    const results = [];
    for (let index = 0; index < devices.length; index++) {
      const device = devices[index];
      try {
        const releaseResult = await this.props.api.deviceService.unclaimDevice(device.getId());
        results.push({ deviceId: releaseResult.getDeviceId(), claimKey: releaseResult.getClaimKey(), error: null });
      } catch (error) {
        results.push({ deviceId: device.getId(), claimKey: null, error });
      }
    }
    this.setState({ results, loading: false });
    const anclaimedDeviceIds = [];
    results.map(result => {
      if (result.claimKey) {
        anclaimedDeviceIds.push(result.deviceId);
      }
    });
    console.log('anclaimedDeviceIds', anclaimedDeviceIds);
    onSuccess(anclaimedDeviceIds);
  };

  buildExportCsv = () => {
    const { results } = this.state;

    let columns = [
      {
        id: 'deviceId',
        displayName: 'DeviceID',
      },
      {
        id: 'claimKey',
        displayName: 'ClaimKey',
      },
    ];

    let datas = results ? results.filter(result => (result.claimKey ? true : false)) : [];
    return toCsv(columns, datas, ';', true);
  };

  render() {
    const { results, loading, confirmed } = this.state;
    const { t, classes, devices, onClose } = this.props;

    const _devices = results ? results : devices.map(device => ({ deviceId: device.getId() }));
    const _devices_with_claimKey = results ? results.filter(result => (result.claimKey ? true : false)) : [];

    if (!confirmed) {
      return (
        <AlertDialog
          open={true}
          title={t('unclaim_confirmation_dialog_title')}
          message={t('unclaim_confirmation_dialog_description')}
          onCancel={onClose}
          submitButtonTitle={t('unclaim_device')}
          onSubmit={this.unclaimDevice}
        />
      );
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'md'} aria-labelledby="form-dialog-title">
          {loading && <LinearProgress className={classes.progress} />}
          <DialogTitle id="form-dialog-title">{_devices.length === 1 ? t('unclaim_device') : t('unclaim_devices')}</DialogTitle>
          <DialogContent>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('device')}</TableCell>
                  <TableCell>{t('result')}</TableCell>
                  <TableCell align="right">{t('claim_key')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_devices &&
                  _devices.map(device => (
                    <TableRow key={device.deviceId}>
                      <TableCell>{device.deviceId}</TableCell>
                      <TableCell className={device.error ? classes.error : null}>{device.claimKey ? t('successful') : device.error ? mapErrorMessage(device.error) : ''}</TableCell>
                      <TableCell align="right">{device.claimKey ? device.claimKey : '-'}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <CopyToClipboard className={classes.button} text={this.buildExportCsv()}>
              <Button disabled={loading || _devices_with_claimKey.length === 0} color="primary">
                {t('copy_to_clipboard')}
              </Button>
            </CopyToClipboard>
            <Button disabled={loading} onClick={() => onClose()}>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UnClaimDeviceDialog.propTypes = {
  api: PropTypes.any.isRequired,
  open: PropTypes.bool,
  devices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    maxHeight: '100%',
    overflow: 'auto',
    paddingBottom: 80,
  },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  error: {
    color: theme.palette.error.main,
  },
});

export default withTranslation()(withStyles(styles)(UnClaimDeviceDialog));
