import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import MaterialTable from '../Common/MaterialTable';

import AlertDialog from '../Common/AlertDialog';
import { mapErrorMessage } from '../../Utilities/ApiHelper';
import { PageContext } from '../../App';
import moment from 'moment';
import { PARAM_NICKNAME } from '../../m2m-cloud-api/Api/UserService/Models/User';

const DeviceAssignmentsDialog = ({ open, device, onClose }) => {
  const { t } = useTranslation();
  const context = useContext(PageContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await context.deviceService.getDeviceAssignmentsData(device.getId());
      setTableData(await handleAssignmentsData(data));
    } catch (error) {
      console.log('DeviceAssignmentsDialog error... ', error);
      setErrorMessage(mapErrorMessage(error));
    }
    setLoading(false);
  }, []);

  const handleAssignmentsData = useCallback(async entries => {
    const handledData = [];
    const orgs = await context.orgService.searchOrg({ from: 0, size: 10000 });

    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const org = orgs.find(item => item.getId() === entry.orgId);

      let assignment = entry.assignment?.ts ? `${moment(entry.assignment.ts).format('YYYY-MM-DD HH:mm:ss.SSS')} ` : '-';
      if (entry.assignment?.type === 'user') {
        const user = await context.userService.getPublicUser(entry.assignment.id);
        assignment += `(${user.getParam(PARAM_NICKNAME)})`;
      } else {
        assignment += `(${entry.assignment.type}: ${entry.assignment.id})`;
      }

      let unassignment = entry.unassignment?.ts ? `${moment(entry.unassignment.ts).format('YYYY-MM-DD HH:mm:ss.SSS')} ` : '-';
      if (entry.unassignment?.type) {
        unassignment += `(${entry.unassignment.type}: ${entry.unassignment.id})`;
      }

      const tableData = {
        org: org?.getName() || entry.orgId,
        assignment,
        unassignment,
      };
      handledData.push(tableData);
    }
    return handledData;
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'} aria-labelledby="form-dialog-title">
      {loading && <LinearProgress className={classes.progress} />}
      <DialogTitle id="form-dialog-title">
        <Typography>{t('device_assignment_data_title', { id: device?.getPhysicalId() || '' })}</Typography>
      </DialogTitle>
      <DialogContent>
        <MaterialTable
          localization={{
            body: { emptyDataSourceMessage: t('no_data_available') },
          }}
          columns={[
            {
              title: t('org'),
              field: 'org',
            },
            {
              title: t('assignment'),
              field: 'assignment',
            },
            {
              title: t('unassignment'),
              field: 'unassignment',
            },
          ]}
          data={tableData}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={onClose} type="submit">
          {t('close')}
        </Button>
      </DialogActions>

      {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  eventDate: {
    fontSize: theme.font.small,
  },
  progress: {
    position: 'absolute',
    width: '100%',
  },
  tableHeader: {
    backgroundColor: 'transparent',
  },
}));

export default memo(DeviceAssignmentsDialog);
