import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import { DefaultStyles } from '../../theme';
import { AppBar, Typography, IconButton, Tabs, Tab } from '@material-ui/core';
import Toolbar from '../../Components/Common/Toolbar';

import Group from '../../Components/AccessManagement/Group/Group';
import Role from '../../Components/AccessManagement/Role/Role';

const drawerWidth = 400;

const styles = theme => ({
  root: {
    ...DefaultStyles.AbsoluteFill,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const TAB_TYPE = {
  GROUP: 0,
  ROLE: 1,
};

class Org extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      tabIndex: TAB_TYPE.GROUP,
    };
  }

  onTabChange(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} color="secondary">
          <Toolbar title={t('access_management')} />
        </AppBar>

        <main className={classes.content} height="100%">
          <div className={classes.toolbar} />
          <Fragment>
            <Tabs className={classes.tabbar} value={this.state.tabIndex} indicatorColor="primary" textColor="primary" onChange={this.onTabChange.bind(this)} aria-label={t('org')}>
              <Tab label={t('Group')} />
              <Tab label={t('Role')} />
            </Tabs>
            {this.state.tabIndex === TAB_TYPE.GROUP && <Group />}
            {this.state.tabIndex === TAB_TYPE.ROLE && <Role />}
          </Fragment>
        </main>
      </div>
    );
  }
}

Org.contextType = PageContext;

export default withTranslation()(withStyles(styles)(Org));
