import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n'
import PropTypes from 'prop-types'
import { PARAM_FORM_USER_FIELDS, PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_NICKNAME_FIELD, PARAM_FORM_LANGUAGE_FIELD, PARAM_LANGUAGE, PARAM_FORM_AUTO_COLLAPSE, PARAM_AUTO_COLLAPSE } from '../../m2m-cloud-api/Api/UserService/Models/User'
import { mapErrorMessage } from '../../Utilities/ApiHelper'

class EditProfile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            user: null,
            params: {},
            loading: true,
            errorMessage: null,
        }
    }

    async componentDidMount() {
        const { t, userService, userId } = this.props

        const user = userId ? await userService.loadUser(userId) : userService.getActiveUser()

        const me = this
        this.setState({ user }, () => {
            const defaultValues = this.getDefaultValues()
            this.setState({
                params: defaultValues,
                loading: false,
            })
        })
    }

    getFormFields() {
        const { user } = this.state

        let fields = []
        if (user.isTechnicalUser()) {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD, PARAM_FORM_AUTO_COLLAPSE,]
        } else {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD, PARAM_FORM_AUTO_COLLAPSE,]
        }
        return fields
    }

    getDefaultValues() {
        const { user } = this.state
        let params = {}
        this.getFormFields().map(field => {
            params[field.id] = user.getParam(field.id)
        })
        return params
    }

    buildApiUpdateRequests() {
        const { user } = this.state
        const { params } = this.state
        const promisses = []
        this.getFormFields().map(field => {
            const fieldValue = params[field.id]
            if (!fieldValue || fieldValue.trim() === '') {
                promisses.push(this.props.userService.deleteParam(user.getUserId(), field.id))
            } else {
                promisses.push(this.props.userService.putParam(user.getUserId(), field.id, params[field.id]))
            }
        })
        return promisses
    }

    handleSubmit() {
        this.setState({ loading: true, errorMessage: null })

        return Promise.all(this.buildApiUpdateRequests())
            .then(async results => {
                const lang = this.state.params[PARAM_LANGUAGE]
                if (lang && lang.trim() !== '') {
                    i18n.changeLanguage(lang)
                }
                this.props.onSuccess()
            })
            .catch(error => {
                this.setState({
                    errorMessage: mapErrorMessage(error),
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    onChangeDefaultSelectField(event, field) {
        const { params } = this.state
        let _params = params
        _params[field.id] = event.target.value
        this.setState({ params: _params, errorMessage: null })
    }

    render() {
        const { params, loading, errorMessage, user } = this.state
        const { t, classes } = this.props

        if (!user) return null

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('edit_profile')}</DialogTitle>
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit.bind(this)} onError={errors => console.log('form error:', errors)}>
                        <DialogContent>
                            {this.getFormFields().map(field => {
                                let value = params[field.id]
                                if (!value && field.defaultValue) {
                                    value = field.defaultValue
                                }
                                if (field.type === 'select') {
                                    return (
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor="user-lang">{t(field.title)}</InputLabel>
                                            <Grid container className={classes.selectFieldContainer}>
                                                <Select
                                                    value={value}
                                                    className={classes.field}
                                                    onChange={event => this.onChangeDefaultSelectField(event, field)}
                                                    defaultValue={0}
                                                    inputProps={{
                                                        name: field.id,
                                                        id: 'user-lang',
                                                    }}>
                                                    {field.options.map(option => (
                                                        <MenuItem key={option} value={option}>
                                                            {field.id === PARAM_AUTO_COLLAPSE ? t(`auto_collapse_${option}`) : t(option)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </FormControl>
                                    )
                                } else {
                                    return (
                                        <TextValidator
                                            className={classes.field}
                                            key={field.id}
                                            value={value}
                                            onChange={event => {
                                                let _params = params
                                                _params[field.id] = event.target.value
                                                this.setState({ params: _params, errorMessage: null })
                                            }}
                                            margin="dense"
                                            id={field.id}
                                            multiline={field.type === 'Text' ? true : false}
                                            label={t(field.title)}
                                            validators={field.validators}
                                            errorMessages={field.errorMessages.map(message => t(message))}
                                            fullWidth
                                        />
                                    )
                                }
                            })}

                            {errorMessage && <DialogContentText className={classes.errorText}>{errorMessage}</DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}

EditProfile.propTypes = {
    userService: PropTypes.any.isRequired,
    userId: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}

const styles = theme => ({
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20,
    },
    field: {
        marginTop: 10,
        marginBottom: 10,
    },
    selectFieldContainer: {
        alignItems: 'center',
        '& div': {
            flex: 1,
        },
    },
})

export default withTranslation()(withStyles(styles)(EditProfile))
