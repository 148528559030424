import { Route, Redirect } from 'react-router-dom';
import React, { useContext } from 'react';
import { PageContext } from './Context/PageProvider';

const ProtectedRoute = ({ component: Component, title, ...rest }) => {
  const context = useContext(PageContext);
  return <Route {...rest} render={props => (context.userService.getActiveUser() ? <Component {...props} /> : <Redirect to="/auth" />)} />;
};

export default ProtectedRoute;
