import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../Context/PageProvider'
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, IconButton } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { DatePicker } from '../Common/DatePicker'
import moment from 'moment'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import CopyIcon from '@material-ui/icons/FileCopy'
import CopyToClipboard from 'react-copy-to-clipboard'

const AddAccessTokenDialog = ({ open, onClose }) => {
  const context = useContext(PageContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const [values, setValues] = useState({ name: '', validityDate: new Date() })
  const form = useRef()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [addedToken, setAddedToken] = useState(null)

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const now = moment()
      const selectedDate = moment(values.validityDate).endOf('day')
      const validityDate = moment.duration(selectedDate.diff(now))
      const accessToken = await context.userService.addAccessToken(values.name, validityDate.asMilliseconds())
      if (accessToken?.getKey()) {
        setAddedToken(accessToken.getKey())
      }
    } catch (error) {
      setErrorMessage(t(mapErrorMessage(error)))
    }
    setLoading(false)
  }, [values])

  const onCloseDialog = useCallback(() => {
    if (addedToken) {
      onClose(true)
    }
    onClose(false)
  }, [addedToken])

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(null)
    }
  }, [values])

  return (
    <Dialog open={open} onClose={null} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{addedToken ? t('new_token_created_success_title') : t('create_new_access_token')}</DialogTitle>
      <DialogContent>
        <ValidatorForm ref={form} onSubmit={() => handleSubmit()} onError={errors => console.log('form error:', errors)}>
          {!addedToken && (
            <Grid container spacing={3} direction={'column'}>
              <Grid item>
                <TextValidator
                  className={clsx(classes.formItem, classes.formItemText)}
                  fullWidth
                  label={t('name')}
                  value={values?.name}
                  validators={['required']}
                  errorMessages={[t('this_field_is_required')]}
                  onChange={event => setValues(prevValues => ({ ...prevValues, name: event.target.value }))}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  clearable={false}
                  fullWidth
                  disablePast
                  label={t('valid_until')}
                  value={values?.validityDate}
                  className={clsx(classes.formItem)}
                  maxDate={moment().add(2, 'years')}
                  onChange={date => {
                    if (date && date.isValid()) {
                      setValues(prevValues => ({ ...prevValues, validityDate: date }))
                    }
                  }}
                />
              </Grid>
              {errorMessage && (
                <Grid item>
                  <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">
                    {errorMessage}
                  </DialogContentText>
                </Grid>
              )}
            </Grid>
          )}
          {addedToken && (
            <Grid container>
              <Grid item>
                <DialogContentText className={classes.description}>{t('new_token_created_success_description')}</DialogContentText>
              </Grid>
              <Grid container alignItems={'center'} className={classes.marginMedium}>
                <DialogContentText className={classes.tokenString}>{addedToken}</DialogContentText>
                <CopyToClipboard className={classes.copyButton} text={addedToken}>
                  <IconButton>
                    <CopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Grid>
              <Grid item>
                <Alert variant="filled" className={classes.warningText} severity="warning">
                  {t('new_token_created_success_warning')}
                </Alert>
              </Grid>
            </Grid>
          )}
        </ValidatorForm>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCloseDialog} disabled={loading} color={addedToken ? 'primary' : 'default'}>
          {addedToken ? t('close') : t('cancel')}
        </Button>
        {!addedToken && (
          <Button onClick={() => form?.current.submit()} color="primary" variant="contained" disabled={loading}>
            {t('create')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  formItem: {
    flex: 1,
    marginBottom: theme.spacing(2),
  },
  formItemText: {
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  tokenString: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-all',
    flex: 1,
    marginBottom: 0,
    fontSize: theme.font.medium,
  },
  description: {
    marginBottom: theme.spacing(3),
    color: theme.palette.common.white,
  },
  warningText: {
    fontSize: theme.font.small,
  },
  marginMedium: {
    marginBottom: theme.spacing(3),
  },
}))

export default AddAccessTokenDialog
