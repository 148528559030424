import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../../../Context/PageProvider'
import { IconButton, Divider, Fab, Drawer, List, ListItem, ListSubheader, Typography } from '@material-ui/core'
import AlertDialog from '../../../Components/Common/AlertDialog'
import RoleView from './RoleView'
import RoleDialog from './RoleDialog'
import PermissionDialog from '../Permission/PermissionDialog'
import { mapErrorMessage } from '../../../Utilities/ApiHelper'
import Searchbox from '../../Common/Searchbox'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const drawerWidth = 400

const styles = theme => ({
  root: {
    display: 'flex',
    height: 'calc(100% - 110px)',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    height: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'transparent',
  },
  list: {
    height: '100%',
    overflow: 'auto',
    paddingBottom: 70,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  activeListItem: {
    backgroundColor: theme.palette.primary.main,
  },
  groupHeaderContainer: {
    display: 'flex',
  },
  groupHeaderColumn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  groupHeaderText: {
    flex: 1,
  },
  groupHeaderIcons: {
    justifyContent: 'center',
  },
  searchBox: {
    marginTop: theme.spacing(1),
  },
})

class Role extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      errorMessage: null,
      addRoleDialogVisible: false,
      editRoleDialogVisible: false,
      deactivateRoleDialogVisible: false,
      dialogVisible: false,
      permissionDialogVisible: false,
      roles: null,
      selectedRole: null,
      searchTerm: '',
    }
  }

  componentDidMount() {
    this.loadRoles()
  }

  isPrivateRole(role) {
    const user = this.context.userService.getActiveUser()
    if (role && user && user.userRoleMeta) {
      return user.userRoleMeta.getPrivateRole() === role.getId()
    }
    return false
  }

  render() {
    const { classes, t } = this.props
    const { loading, selectedRole, errorMessage, roles, searchTerm } = this.state

    let filteredRoles =
      roles && roles.length > 0
        ? roles.filter(role => {
            const name = this.isPrivateRole(role) ? t('private_role') : role.getName()
            return !searchTerm || searchTerm.trim() === '' || name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
          })
        : []

    filteredRoles = filteredRoles.sort((a, b) => {
      var nameA = a.getName().toUpperCase()
      var nameB = b.getName().toUpperCase()

      if (this.isPrivateRole(a) || nameA < nameB) {
        return -1
      }
      if (this.isPrivateRole(b) || nameA > nameB) {
        return 1
      }
      return 0
    })

    return (
      <div className={classes.root}>
        <Drawer
          PaperProps={{ style: { position: 'relative' } }}
          BackdropProps={{ style: { position: 'relative' } }}
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left">
          <Searchbox className={classes.searchBox} value={searchTerm} onChange={value => this.setState({ searchTerm: value })} />
          <List className={classes.list}>
            {filteredRoles &&
              filteredRoles.map((role, index) => {
                const name = this.isPrivateRole(role) ? t('private_role') : role.getName()
                return (
                  <ListItem className={selectedRole && selectedRole.id === role.id ? classes.activeListItem : ''} onClick={() => this.setState({ selectedRole: role })} key={'roleListItem' + index}>
                    {name}
                  </ListItem>
                )
              })}
            {!loading && filteredRoles && filteredRoles.length === 0 && <ListItem key={'emptyListItem'}>{roles && roles.length > 0 ? t('no_match_found') : t('no_entries_available')}</ListItem>}
          </List>
          <Fab onClick={() => this.setState({ addRoleDialogVisible: true })} size={'small'} color="primary" aria-label="Add" className={classes.fab}>
            <AddIcon />
          </Fab>
        </Drawer>
        <main className={classes.content}>
          {selectedRole && (
            <Fragment>
              <div className={classes.groupHeaderColumn}>
                <div className={classes.groupHeaderContainer}>
                  <div className={classes.groupHeaderText}>
                    <h3 style={{ marginBottom: 5 }}>{this.isPrivateRole(selectedRole) ? t('private_role') : selectedRole.getName()}</h3>
                    <Typography variant={'subtitle2'}>{selectedRole.getId()}</Typography>
                  </div>
                  {!this.isPrivateRole(selectedRole) && (
                    <div className={classes.groupHeaderIcons}>
                      <IconButton size="small" onClick={() => this.setState({ editRoleDialogVisible: true })} edge="end">
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" onClick={() => this.setState({ deactivateRoleDialogVisible: true })} edge="end">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
                <Divider />
              </div>
              <RoleView role={selectedRole} onRoleChange={() => this.loadRoleAndSetSelected(selectedRole.getId())} />
            </Fragment>
          )}
          {this.state.permissionDialogVisible && (
            <PermissionDialog
              open={this.state.permissionDialogVisible}
              role={selectedRole}
              onSuccess={() => {
                this.setState({ permissionDialogVisible: false }, () => {
                  this.loadRoleAndSetSelected(selectedRole.getId())
                })
              }}
              onCancel={() => {
                this.setState({ permissionDialogVisible: false })
              }}
            />
          )}
          {selectedRole && !this.isPrivateRole(selectedRole) && (
            <Fab onClick={() => this.setState({ permissionDialogVisible: true })} size={'small'} color="primary" aria-label="Add" className={classes.fab}>
              <AddIcon />
            </Fab>
          )}
        </main>

        {(this.state.addRoleDialogVisible || this.state.editRoleDialogVisible) && (
          <RoleDialog
            open={this.state.addRoleDialogVisible || this.state.editRoleDialogVisible}
            role={this.state.editRoleDialogVisible ? this.state.selectedRole : null}
            onSuccess={role => {
              this.setState({ addRoleDialogVisible: false, editRoleDialogVisible: false }, () => {
                const roleIndex = roles.findIndex(r => r.getId() === role.getId())
                let _roles = this.state.roles
                if (roleIndex >= 0) {
                  _roles[roleIndex] = role
                } else {
                  _roles.push(role)
                }
                this.setState({ roles: _roles, selectedRole: role })
              })
            }}
            onCancel={() => {
              this.setState({ addRoleDialogVisible: false, editRoleDialogVisible: false })
            }}
          />
        )}
        {this.state.deactivateRoleDialogVisible && (
          <AlertDialog
            open={this.state.deactivateRoleDialogVisible}
            title={t('role_delete_confirmation_title')}
            message={t('role_delete_comfirmation_description')}
            onCancel={() => this.setState({ deactivateRoleDialogVisible: false })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.deactivateRole(this.state.selectedRole.id)}
          />
        )}
        {errorMessage && (
          <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ errorMessage: null })} />
        )}
      </div>
    )
  }

  loadRoleAndSetSelected(roleId) {
    this.setState({ selectedRole: null })
    this.context.authzService.readRole(roleId).then(role => {
      let roles = this.state.roles
      const roleIndex = roles.findIndex(r => r.getId() === role.getId())
      if (roleIndex >= 0) {
        roles[roleIndex] = role
      }
      this.setState({ roles, selectedRole: role })
    })
  }

  loadRoles() {
    this.context.authzService
      .readAccessableRolesOfCurrentUser()
      .then(roles => {
        let selectedRole = null
        if (roles && roles.length > 0) {
          if (!this.state.selectedRole || !roles.find(role => role.getId() === this.state.selectedRole.getId())) {
            selectedRole = roles[0]
          }
        }
        this.setState({ roles, selectedRole, loading: false })
      })
      .catch(error => {
        console.log('readAccessableRolesOfCurrentUser error', error)
        this.setState({ roles: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  getGroupItems() {
    this.context.authzService
      .listAccessableGroupsOfCurrentUser()
      .then(groupItems => {
        this.setState({ groupItems })
      })
      .catch(error => {
        console.log('listAccessableGroupsOfCurrentUser error', error)
        this.setState({ groupItems: null, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }

  deactivateRole(roleId) {
    this.context.authzService
      .deactivateRole(roleId)
      .then(success => {
        const roles = this.state.roles.filter(r => r.getId() !== roleId)
        this.setState({ deactivateRoleDialogVisible: false, roles, selectedRole: roles.length > 0 ? roles[0] : null })
      })
      .catch(error => {
        console.log('deactivateGroup error', error)
        this.setState({ deactivateRoleDialogVisible: false, loading: false, errorMessage: mapErrorMessage(error) })
      })
  }
}

Role.contextType = PageContext

Role.propTypes = {}

export default withTranslation()(withStyles(styles)(Role))
