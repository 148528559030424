import React, { useState, useCallback } from 'react';
import { Collapse } from '@material-ui/core';
import ListItem from './Listitem';

const ListGroupItem = ({ item, onGotoDetailReport, level = 0 }) => {
  const [open, setOpen] = useState(false);

  const onExpandClick = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <>
      <ListItem item={item} onGotoDetailReport={onGotoDetailReport} level={level} expanded={open} onExpandClick={onExpandClick} />
      {open && (
        <>
          {item.children?.map(children => (
            <ListGroupItem item={children} onGotoDetailReport={onGotoDetailReport} level={level + 1} />
          ))}
          {item.data?.map(item => (
            <ListItem item={item} onGotoDetailReport={onGotoDetailReport} level={level + 1} />
          ))}
        </>
      )}
    </>
  );
};

export default ListGroupItem;
