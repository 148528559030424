import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { PageContext } from '../../../Context/PageProvider'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core'
import AlertDialog from '../..//Common/AlertDialog'
import { mapPermissionActionTypeTranslationKey, mapPermissionEntityGroupTypeTranslationKey, ENTITY_GROUP_TYPE } from '../../../m2m-cloud-api/Api/AuthzService/Models/Permission'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  root: {
    height: 'calc(100vh - (220px))',
    widht: '100%',
    overflow: 'auto',
  },
  table: {},
})

class RoleView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errorMessage: null,
      permissionToRemove: false,
      orgItems: null,
      groupItems: null,
      roleItems: null,
    }
  }

  isPrivateGroup(groupId) {
    const user = this.context.userService.getActiveUser()
    if (groupId && user && user.userRoleMeta) {
      return user.userRoleMeta.getPrivateGroup() === groupId
    }
    return false
  }

  isPrivateRole(roleId) {
    const user = this.context.userService.getActiveUser()
    if (roleId && user && user.userRoleMeta) {
      return user.userRoleMeta.getPrivateRole() === roleId
    }
    return false
  }

  componentWillMount() {
    this.readResources()
  }

  mapResourceName(perm) {
    const { t } = this.props

    let itemsToSearch = null
    switch (perm.getEntityGroup()) {
      case ENTITY_GROUP_TYPE.ORG:
        itemsToSearch = this.state.orgItems
        break
      case ENTITY_GROUP_TYPE.GROUP:
        if (this.isPrivateGroup(perm.getResource())) return t('private_group')
        itemsToSearch = this.state.groupItems
        break
      case ENTITY_GROUP_TYPE.ROLE:
        if (this.isPrivateRole(perm.getResource())) return t('private_role')
        itemsToSearch = this.state.roleItems
        break
    }

    if (itemsToSearch) {
      const item = itemsToSearch.find(item => item && item.getId() === perm.getResource())
      if (item) {
        if (perm.getEntityGroup() === ENTITY_GROUP_TYPE.ORG && item.isDeactivated()) {
          return item.getName() + ' ( ' + t('deactivated') + ' )'
        }
        return item.getName()
      }
    }
    return perm.getResource()
  }

  renderRolesTable() {
    const { role, t, classes } = this.props

    const items = []
    role.getPermissions().map((perm, index) => {
      items.push({
        perm: perm,
        id: index,
        entityGroup: t(mapPermissionEntityGroupTypeTranslationKey(perm.getEntityGroup())),
        access: t(mapPermissionActionTypeTranslationKey(perm.getAction())),
        name: this.mapResourceName(perm),
      })
    })
    items.sort((a, b) => {
      return a.entityGroup.localeCompare(b.entityGroup) || a.access.localeCompare(b.access) || a.name.localeCompare(b.name)
    })

    return (
      <Fragment>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('entity_group')}</TableCell>
              <TableCell>{t('access')}</TableCell>
              <TableCell>{t('resource')}</TableCell>
              {!this.isPrivateRole(role.getId()) && <TableCell align="right">{t('row_actions')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={'perm-' + item.id}>
                <TableCell>{item.entityGroup}</TableCell>
                <TableCell>{item.access}</TableCell>
                <TableCell>{item.name}</TableCell>
                {!this.isPrivateRole(role.getId()) && (
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => this.setState({ permissionToRemove: item.perm })} edge="end">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Fragment>
    )
  }

  render() {
    const { errorMessage } = this.state
    const { classes, t } = this.props

    return (
      <Fragment>
        <Grid container spacing={3} className={classes.root}>
          <Grid item md={12}>
            {this.renderRolesTable()}
          </Grid>
        </Grid>
        {this.state.permissionToRemove && (
          <AlertDialog
            open={this.state.permissionToRemove ? true : false}
            title={t('permission_delete_confirmation_title')}
            message={t('permission_delete_confirmation_description')}
            onCancel={() => this.setState({ permissionToRemove: null })}
            submitButtonTitle={t('delete')}
            onSubmit={() => this.removePerm()}
          />
        )}
        {errorMessage && <AlertDialog open={errorMessage ? true : false} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => this.setState({ error: null })} />}
      </Fragment>
    )
  }

  readResources() {
    const { role } = this.props

    Promise.all([
      this.context.orgService.searchOrg({ from: 0, size: 10000 }),
      this.context.authzService.readAccessableGroupsOfCurrentUser(false),
      this.context.authzService.readAccessableRolesOfCurrentUser(false),
    ]).then(results => {
      let orgItems = results[0]

      const setItems = () => {
        this.setState({
          orgItems,
          groupItems: results[1],
          roleItems: results[2],
        })
      }

      // Load all orgs that are no longer returned via search-api ( deactivated ... )
      const orgIremIds = orgItems.map(org => org.getId())
      const orgItemsNotInsideSearchList = role.getPermissions().filter(perm => perm.getEntityGroup() === ENTITY_GROUP_TYPE.ORG && orgIremIds.indexOf(perm.getResource()) === -1)
      const orgIdsToQuery = orgItemsNotInsideSearchList.map(perm => perm.getResource())

      if (orgIdsToQuery.length > 0) {
        this.context.orgService.readMultiple(orgIdsToQuery).then(orgs => {
          orgItems = [...orgItems, ...orgs]
          setItems()
        })
      } else {
        setItems()
      }
    })
  }

  removePerm() {
    const { role } = this.props
    const { permissionToRemove } = this.state
    const roleId = role.getId()

    this.context.authzService
      .removePerm(roleId, permissionToRemove.getEntityGroup(), permissionToRemove.getAction(), permissionToRemove.getResource())
      .then(permissions => {
        this.setState({ permissionToRemove: null })
        this.props.onRoleChange()
      })
      .catch(error => {
        console.log('removePerm error', error)
      })
  }
}

RoleView.contextType = PageContext

RoleView.propTypes = {
  onRoleChange: PropTypes.func.isRequired,
}

export default withTranslation()(withStyles(styles)(RoleView))
