import React, { Fragment, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Fab, Table, TableBody, TableFooter, TableCell, TableHead, TableRow, TablePagination, Menu, MenuItem, TableContainer, Typography } from '@material-ui/core';
import { PARAM_CODE_NAME_SPACE } from '../../../m2m-cloud-api/Api/OrgService/Models/Org';
import { DEVICE_TYPE } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device';

import AddIcon from '@material-ui/icons/Add';
import DeviceDynamicListCell from './DeviceDynamicListCell';
import DeviceDynamicListMenu from './DeviceDynamicListMenu';
import AlertDialog from '../../Common/AlertDialog';

function DeviceDynamicListView({
    data,
    org,
    onAdd,
    onMonitoringStatusEdit,
    onActivateDevice,
    onDeactivateDevice,
    onAssign,
    onUnassign,
    onSendCommand,
    onTriggerEvent,
    onShowEventData,
    onEditSettings,
    onUnclaim,
    currentPage = 0,
    pageLimit = 100,
    onChangePage,
    onShowAssignmentsData,
    onShowActivationsData,
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [deviceToActivate, setDeviceToActivate] = useState(null);
    const [deviceToDeactivate, setDeviceToDeactivate] = useState(null);

    const entries = data.entry;
    const totalCount = data.total;
    const nextOffset = data.nextOffset;
    const filtered = data.filtered;

    const namespace = org && org.getParam(PARAM_CODE_NAME_SPACE);

    return (
        <>
            <TableContainer component={'div'} className={classes.root} id={'table-dynamic-devices'}>
                <Table stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {data?.headers?.map((header, index) => (
                                <TableCell key={index}>{header.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entries &&
                            entries.map(entry => (
                                <TableRow key={entry.deviceId}>
                                    {entry?.fields.map((field, index) => (
                                        <DeviceDynamicListCell
                                            data={data}
                                            entry={entry}
                                            field={field}
                                            index={index}
                                            key={index}
                                            onSendCommand={onSendCommand}
                                            onShowEventData={onShowEventData}
                                            onMonitoringStatusEdit={onMonitoringStatusEdit}
                                            onSelectedDevice={setSelectedDevice}
                                            onSetMenuAnchorEl={setMenuAnchorEl}
                                            onActivateDevice={setDeviceToActivate}
                                            onDeactivateDevice={setDeviceToDeactivate}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                    {entries?.length > 0 && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[pageLimit]}
                                    component="td"
                                    classes={{ spacer: classes.paginationSpacer }}
                                    colSpan={data?.headers?.length}
                                    count={totalCount}
                                    nextIconButtonProps={nextOffset === totalCount ? { disabled: true } : undefined}
                                    rowsPerPage={pageLimit}
                                    page={currentPage}
                                    onPageChange={onChangePage}
                                    labelDisplayedRows={({ from, to, count }) => (
                                        <Typography variant={'body2'}>
                                            {data.relation === 'gte' ? t('table_pagination_count_label_more_than', { from, to, count }) : t('table_pagination_count_label', { from, to, count })}
                                            {` ( ${filtered} filtered )`}
                                        </Typography>
                                    )}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
                {onAdd && (
                    <Fragment>
                        <Fab onClick={event => setAddMenuAnchorEl(event.currentTarget)} size={'small'} color="secondary" aria-label="Add" className={classes.fab}>
                            <AddIcon />
                        </Fab>
                        <Menu anchorEl={addMenuAnchorEl} keepMounted open={Boolean(addMenuAnchorEl)} onClose={() => setAddMenuAnchorEl(null)}>
                            <MenuItem
                                disabled={namespace ? false : true}
                                onClick={() => {
                                    setAddMenuAnchorEl(null);
                                    onAdd(DEVICE_TYPE.VIRTUAL);
                                }}>
                                {t('add_virtual_device')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setAddMenuAnchorEl(null);
                                    onAdd(DEVICE_TYPE.TIMER);
                                }}>
                                {t('add_timer_device')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setAddMenuAnchorEl(null);
                                    onAdd(DEVICE_TYPE.CLAIM_DEVICE);
                                }}>
                                {t('claim_device')}
                            </MenuItem>
                        </Menu>
                    </Fragment>
                )}
                {menuAnchorEl && selectedDevice && (
                    <DeviceDynamicListMenu
                        onActivate={setDeviceToActivate}
                        onDeactivateDevice={setDeviceToDeactivate}
                        onAssign={onAssign}
                        onUnassign={onUnassign}
                        onSendCommand={onSendCommand}
                        onTriggerEvent={onTriggerEvent}
                        onShowEventData={onShowEventData}
                        onEditSettings={onEditSettings}
                        onUnclaim={onUnclaim}
                        onShowAssignmentsData={onShowAssignmentsData}
                        selectedDevice={selectedDevice}
                        onSelectedDevice={setSelectedDevice}
                        menuAnchorEl={menuAnchorEl}
                        onSetMenuAnchorEl={setMenuAnchorEl}
                        onShowActivationsData={onShowActivationsData}
                    />
                )}
            </TableContainer>
            {deviceToActivate && (
                <AlertDialog
                    open={true}
                    title={t('device_set_activation_status_title')}
                    message={t('device_set_active_status_message')}
                    onCancel={() => setDeviceToActivate(null)}
                    submitButtonTitle={t('ok')}
                    onSubmit={() => onActivateDevice(deviceToActivate)}
                />
            )}
            {deviceToDeactivate && (
                <AlertDialog
                    open={true}
                    title={t('device_set_deactivation_status_title')}
                    message={t('device_set_deactive_status_message')}
                    onCancel={() => setDeviceToDeactivate(null)}
                    submitButtonTitle={t('ok')}
                    onSubmit={() => onDeactivateDevice(deviceToDeactivate)}
                />
            )}
            {errorMessage && <AlertDialog open={true} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
        </>
    );
}

export default DeviceDynamicListView;

const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: '100%',
        overflow: 'auto',
        paddingBottom: 20,
    },
    table: {
        maxHeight: '100%',
        overflow: 'auto',
    },
    paginationSpacer: {
        flex: 0,
    },
    fab: {
        position: 'fixed',
        top: 'auto',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    driver: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        borderRadius: 2,
        minWidth: 60,
        textAlign: 'center',
        display: 'inline-block',
        marginRight: theme.spacing(1),
        textTransform: 'uppercase',
    },
}));
