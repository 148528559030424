import React, { useState, Fragment } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Input, Select, Checkbox, FormControlLabel, FormControl, InputLabel, InputAdornment } from '@material-ui/core';
import OrgListViewDialog, { ROOT_NODE_ID } from '../Org/OrgListViewDialog';
import ListSelectViewDialog from '../Common/ListSelectViewDialog';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const FIELD_TYPE = {
  TEXT_FIELD: 'textField',
  TEXT_FIELD_MULTILINE: 'textField-multiline',
  SELECT: 'select',
  SELECT_LIST: 'select-list',
  SELECT_MULTIFIELD: 'select-multiField',
  CHECKBOX_FIELD: 'checkbox-field',
  ORG_SELECT: 'org_select',
};

export const FormField = props => {
  const { fieldType } = props;
  switch (fieldType) {
    case FIELD_TYPE.TEXT_FIELD:
      return CustomTextField(props);
    case FIELD_TYPE.TEXT_FIELD_MULTILINE:
      return CustomTextField({ ...props, multiLine: true });
    case FIELD_TYPE.SELECT:
      return SelectField({ ...props });
    case FIELD_TYPE.SELECT_LIST:
      return ListSelectField({ ...props });
    case FIELD_TYPE.SELECT_MULTIFIELD:
      return SelectMultiField({ ...props });
    case FIELD_TYPE.CHECKBOX_FIELD:
      return CheckboxField({ ...props });
    case FIELD_TYPE.ORG_SELECT:
      return SelectOrg({ ...props });
    default:
      return null;
  }
};

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
  multiSelectFormControl: {
    marginTop: 10,
  },
  orgMenuList: {
    maxWidth: '100%',
    paddingBottom: 0,
  },
  orgMenuPaper: {
    height: '50%',
  },
}));

const CustomTextField = ({ id, title, className, value, onChange, multiLine = false, validators = [], errorMessages = [], disabled = false, rows = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <TextValidator
      className={[classes.field, className].join(' ')}
      key={id}
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
      margin="dense"
      multiline={multiLine}
      label={title}
      validators={validators}
      errorMessages={errorMessages.map(message => t(message))}
      fullWidth
      rows={rows}
    />
  );
};

const SelectField = ({ id, title, className, options, value, onChange, validators = [], errorMessages = [], disabled = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!options) {
    console.warn('invalid options: ', options, ' for id: ', id);
    return null;
  }

  return (
    <TextValidator
      className={[classes.field, className].join(' ')}
      disabled={disabled}
      select
      key={id}
      id={id}
      value={value}
      onChange={onChange}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      margin="dense"
      label={title}
      validators={validators}
      errorMessages={errorMessages.map(message => t(message))}
      fullWidth>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
    </TextValidator>
  );
};

const SelectMultiField = ({ id, title, className, options, value, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!options) {
    console.warn('invalid options: ', options, ' for id: ', id);
    return null;
  }

  return (
    <FormControl className={classes.multiSelectFormControl} fullWidth>
      <InputLabel htmlFor={id}>{title}</InputLabel>
      <Select
        className={[classes.field, className].join(' ')}
        disabled={disabled}
        select
        multiple
        key={id}
        id={id}
        value={value}
        onChange={onChange}
        selectprops={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        input={<Input id={id} />}
        renderValue={selected => selected.join(', ')}
        margin="dense"
        label={title}
        fullWidth>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox color="primary" checked={value.indexOf(option.value) > -1} />
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CheckboxField = ({ id, title, className, onChange, value, validators = [], errorMessages = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={[classes.field, className].join(' ')}
          id={id}
          key={id}
          checked={value}
          onChange={event => onChange({ target: { value: event.target.checked } })}
          label={title}
          margin="dense"
          validators={validators}
          errorMessages={errorMessages.map(message => t(message))}
          color="primary"
        />
      }
      label={t('ackable')}
    />
  );
};

export const SelectOrg = ({ id, title, className, options, value, onChange, disabled = false, validators = [], errorMessages = [], renderRootNode = true, defaultValue, resetEnabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  //const orgExists = options && options.find( option => option.getId )
  //if (!orgExists) return (null)

  let org = null;
  let orgName = null;
  if (value === ROOT_NODE_ID) {
    org = null;
    orgName = t('root_org_node');
  } else {
    org = options && options.length > 0 && value ? options.find(org => org.getId() === value) : null;
    if (org) {
      orgName = org.getName();
    }
  }

  return (
    <Fragment>
      <TextValidator
        className={[classes.field, className].join(' ')}
        disabled={disabled}
        inputProps={{ readOnly: 'readonly' }}
        key={id}
        id={id}
        defaultValue={defaultValue}
        value={orgName || defaultValue}
        onClick={disabled ? null : () => setOpen(true)}
        margin="dense"
        label={title}
        validators={validators}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
        errorMessages={errorMessages.map(message => t(message))}
        fullWidth></TextValidator>
      <OrgListViewDialog
        open={open}
        orgItems={options}
        title={title}
        activeOrgId={value}
        renderRootNode={renderRootNode}
        onSelect={item => {
          if (item === ROOT_NODE_ID) {
            onChange(ROOT_NODE_ID);
          } else {
            onChange(item.getId());
          }
          setOpen(false);
        }}
        onSuccessLabel={t('select')}
        onReset={
          resetEnabled
            ? () => {
                onChange(null);
                setOpen(false);
              }
            : null
        }
        onCancel={() => setOpen(false)}
      />
    </Fragment>
  );
};

export const ListSelectField = ({ id, title, className, options, value, onChange, disabled = false, validators = [], errorMessages = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const itemExists = options && options.find(option => option.value);
  if (!itemExists) return null;

  let item = options && options.length > 0 && value ? options.find(a => a.value === value) : null;

  return (
    <Fragment>
      <TextValidator
        className={[classes.field, className].join(' ')}
        disabled={disabled}
        inputProps={{ readOnly: 'readonly' }}
        key={id}
        id={id}
        value={(item && item.title) || ''}
        onClick={disabled ? null : () => setOpen(true)}
        margin="dense"
        label={title}
        validators={validators}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
        errorMessages={errorMessages.map(message => t(message))}
        fullWidth></TextValidator>
      <ListSelectViewDialog
        open={open}
        items={options}
        title={title}
        value={value}
        onSelect={value => {
          onChange(value);
          setOpen(false);
        }}
        onSuccessLabel={t('select')}
        onCancel={() => setOpen(false)}
      />
    </Fragment>
  );
};
