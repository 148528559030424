import React, { useCallback, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Divider } from '@material-ui/core'
import { PARAM_MFA } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import AlertDialog from '../../Common/AlertDialog'
import RecoveryCodesDialog from './RecoveryCodesDialog'

const EnableTwoFactorAuthDialog = ({ open, onClose, userService }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const user = userService?.getActiveUser()
  const isEnabled = user.isMfaEnabled()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'} aria-labelledby="tfa-enable-dialog">
      <DialogTitle id="tfa-title">{t('multi_factor_authentication')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!isEnabled && <DialogContentText color={'textPrimary'}>{t('multi_factor_auth_enable_message')}</DialogContentText>}
        {isEnabled && <DisableContentTwoFactorAuth t={t} userService={userService} onClose={onClose} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        {!isEnabled && (
          <Button onClick={() => onClose(true)} type="submit" className={classes.actionButton}>
            {t('enable_multi_factor_auth')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const DisableContentTwoFactorAuth = ({ t, userService, onClose }) => {
  const classes = useStyles()
  const user = userService?.getActiveUser()
  const [errorMessage, setErrorMessage] = useState(null)
  const [recoveryCodesDialog, setRecoveryCodesDialogVisible] = useState(false)

  const disableTwoFactorAuth = useCallback(async () => {
    try {
      const deletedTotp = await userService.deleteAuthFactor('totp')
      const deletedRecovery = await userService.deleteAuthFactor('recovery')
      if (deletedTotp && deletedRecovery) {
        userService.putParam(user.getUserId(), PARAM_MFA, false)
        onClose(false)
      } else {
        setErrorMessage(t('cant_disabled_multi_factor_auth'))
      }
    } catch (error) {
      setErrorMessage(t('cant_disabled_multi_factor_auth'))
      console.log('DisableContentTwoFactorAuth error... ', error)
    }
  }, [])

  return (
    <Fragment>
      <Box marginTop={2}>
        <Box display={'flex'} alignItems={'center'}>
          <DialogContentText variant={'subtitle2'} className={classes.disableContentText}>
            {t('multi_factor_authentication_is_currently_on')}
          </DialogContentText>
          <Button onClick={disableTwoFactorAuth} type="submit" className={classes.actionButton}>
            {t('disable')}
          </Button>
        </Box>
        <Box marginTop={3} marginBottom={3}>
          <Divider />
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <DialogContentText variant={'subtitle2'} className={classes.disableContentText}>
            {t('multi_factor_recovery_codes')}
          </DialogContentText>
          <Button onClick={() => setRecoveryCodesDialogVisible(true)} type="submit" className={classes.actionButton}>
            {t('reset')}
          </Button>
        </Box>
      </Box>
      {errorMessage && <AlertDialog open={true} title={t('error')} message={errorMessage} submitButtonTitle={t('ok')} onSubmit={() => setErrorMessage(null)} />}
      {recoveryCodesDialog && <RecoveryCodesDialog open={true} userService={userService} onClose={() => setRecoveryCodesDialogVisible(false)} />}
    </Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  dialogContent: {
    paddingBottom: theme.spacing(10),
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
  },
  disableContentText: {
    flex: 1,
    color: 'rgba(255, 255, 255, 0.87)',
  },
}))

export default EnableTwoFactorAuthDialog
