import React, { Fragment, useEffect, useState, useRef, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { get, set } from 'local-storage';
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Collapse, Fab, AppBar } from '@material-ui/core';
import Searchbox from '../Common/Searchbox';

import OrgIcon from '@material-ui/icons/Work';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { PageContext } from '../../App';
import { PARAM_AUTO_COLLAPSE } from '../../m2m-cloud-api/Api/UserService/Models/User';

const TREE_LEVEL_PADDING = 30;
export const ROOT_NODE_ID = 'root-node';

function OrgListView({ orgItems: _orgItems, onClickItem, activeOrgId, onAdd, disabledOrgIds, renderRootNode, className, listButtonEnabled = true, onChangeRootItems, openIdsSaveKey = null }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const context = useContext(PageContext)
    const user = context.userService.getActiveUser()
    const [searchTerm, setSearchTerm] = useState(null);
    const [openIds, setOpenIds] = useState(openIdsSaveKey ? get(openIdsSaveKey) || [] : []);
    const onClickRef = useRef();
    const userParamAutoCollapse = user?.getParam(PARAM_AUTO_COLLAPSE)

    const orgItems = useMemo(() => {
        if (!searchTerm) {
            return _orgItems;
        }
        const foundItems = _orgItems.filter(org => {
            const searchString = `${org.getName() ?? ''} ${org.getId() ?? ''}`;
            return searchString.toLowerCase().includes(searchTerm.toLowerCase());
        });

        return addUniqueParentItemsToChildrenItems(_orgItems, foundItems);
    }, [_orgItems, searchTerm]);

    useEffect(() => {
        if (openIdsSaveKey) {
            set(openIdsSaveKey, openIds);
        }
    }, [openIds]);

    useEffect(() => {
        let openedIds = [...openIds];
        if (activeOrgId) {
            const activeOrg = orgItems?.find(org => org.getId() === activeOrgId);
            if (activeOrg) {
                let rootOrg = fetchRootItemFromChildrenItem(orgItems, activeOrg);
                while (rootOrg) {
                    openedIds.push(rootOrg.getId());
                    rootOrg = fetchRootItemFromChildrenItem(orgItems, rootOrg);
                }
            }
        }
        setOpenIds(openedIds);
    }, [activeOrgId]);

    useEffect(() => {
        setTimeout(() => {
            if (activeOrgId && (!onClickRef.current || onClickRef.current !== activeOrgId)) {
                var scrollDiv = document.getElementById(activeOrgId);
                if (scrollDiv) {
                    try {
                        scrollDiv.scrollIntoView({ behavior: onClickRef?.current ? 'smooth' : 'auto', block: 'center', alignToTop: false });
                    } catch (error) { }
                }
                onClickRef.current = activeOrgId;
            }
        }, 500);
    }, [activeOrgId, openIds]);

    useEffect(() => {
        const rootItems = fetchRootItems(orgItems);
        onChangeRootItems && onChangeRootItems(rootItems);
    }, [orgItems]);

    const onClickHandler = org => {
        onClickRef.current = org ? org.getId() : null;
        onClickItem(org);
    };

    const triggerExpand = org => {
        const id = org.getId();
        const isOpen = openIds.find(openId => openId === id) ?? false;

        let _openIds = []

        if (isOpen) {
            _openIds = openIds.filter(openId => openId !== id)
        } else {
            _openIds = [...openIds, id]

            if (userParamAutoCollapse === undefined || userParamAutoCollapse === null || userParamAutoCollapse === "1") {
                const sameLevelOrgs = fetchSameLevelOrgItems(orgItems, org);
                const filteredSameLevelIds = _openIds.filter(id => sameLevelOrgs.some(_org => _org.getId() === id))
                _openIds = [..._openIds.filter(id => !filteredSameLevelIds.includes(id)), id]
            }
        }
        setOpenIds(_openIds)
    };

    const renderOrg = (org, level) => {
        const id = org.getId();
        const name = org.getName();

        const childItems = fetchChildItemsFromRootItem(orgItems, org);
        const isActive = id === activeOrgId;
        const canOpen = childItems?.length > 0;
        const isOpen = searchTerm || (openIds?.find(openId => openId === id) ?? false);

        return (
            <Fragment key={id}>
                <ListItem
                    className={clsx(classes.listItem, isActive ? (listButtonEnabled ? classes.activeListItemButton : classes.activeListItem) : null)}
                    id={id}
                    style={{ paddingLeft: TREE_LEVEL_PADDING * (level + (renderRootNode ? 2 : 1)), cursor: 'pointer' }}
                    button={listButtonEnabled}
                    disabled={isOrgDisabled(orgItems, disabledOrgIds, org)}
                    key={id}
                    onClick={() => onClickHandler(org)}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <OrgIcon />
                    </ListItemIcon>
                    <ListItemText primary={name || id} />
                    <ListItemSecondaryAction>
                        {onAdd && (
                            <Fab onClick={() => onAdd(org)} size={'small'} color="secondary" aria-label="Add" className={classes.fab}>
                                <AddIcon />
                            </Fab>
                        )}
                        {canOpen && (
                            <IconButton size="small" onClick={() => triggerExpand(org)} edge="end" aria-label="Expand">
                                {isOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        )}
                    </ListItemSecondaryAction>
                </ListItem>
                {isOpen &&
                    childItems.map(org => (
                        <Collapse key={org.getId()} in={true} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {renderOrg(org, level + 1)}
                            </List>
                        </Collapse>
                    ))}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <AppBar position="sticky" color="transparent" className={classes.appBar}>
                <Searchbox value={searchTerm} onChange={setSearchTerm} className={classes.search} />
            </AppBar>
            <List component="nav" aria-labelledby={t('org_list_title')} subheader={null} classes={{}} className={[classes.root, className].join(' ')}>
                {renderRootNode === true && (
                    <Fragment>
                        <ListItem
                            className={activeOrgId === ROOT_NODE_ID ? (listButtonEnabled ? classes.activeListItemButton : classes.activeListItem) : null}
                            style={{ paddingLeft: TREE_LEVEL_PADDING, cursor: 'pointer' }}
                            button={listButtonEnabled}
                            key={ROOT_NODE_ID}
                            onClick={() => onClickItem(ROOT_NODE_ID)}>
                            <ListItemIcon>
                                <OrgIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('root_org_node')} />
                        </ListItem>
                        <Collapse key={'root'} in={true} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {fetchRootItems(orgItems).map(org => renderOrg(org, 0))}
                            </List>
                        </Collapse>
                    </Fragment>
                )}
                {!renderRootNode && fetchRootItems(orgItems).map(org => renderOrg(org, 0))}
            </List>
        </Fragment>
    );
}

const fetchRootItemFromChildrenItem = (allOrgItems, childrenOrg) => {
    if (allOrgItems?.length > 0) {
        for (const currentOrg of allOrgItems) {
            if (childrenOrg?.getId?.() !== currentOrg.getId() && childrenOrg?.getParentId?.() === currentOrg.getId()) {
                return currentOrg;
            }
        }
    }
    return null;
};

const fetchRootItemsFromChildrenItem = (allOrgItems, childrenOrg) => {
    const rootOrgs = [];
    let currentOrg = childrenOrg;

    while (currentOrg) {
        const parentOrg = fetchRootItemFromChildrenItem(allOrgItems, currentOrg);
        if (parentOrg && parentOrg.getId() !== currentOrg.getId()) {
            rootOrgs.push(parentOrg);
            currentOrg = parentOrg;
        } else {
            currentOrg = null;
        }
    }
    return rootOrgs;
};

const fetchRootItems = allOrgItems => {
    const items = allOrgItems?.filter(item => fetchRootItemFromChildrenItem(allOrgItems, item) === null) || [];
    return items.sort((a, b) => {
        return a.getName().localeCompare(b.getName());
    });
};

const isOrgDisabled = (allOrgItems, disabledOrgIds, org) => {
    if (disabledOrgIds?.length > 0) {
        if (disabledOrgIds.includes(org.getId())) {
            return true;
        }

        let rootOrg = fetchRootItemFromChildrenItem(allOrgItems, org);
        let isDisabled = false;

        while (isDisabled === false && rootOrg) {
            if (disabledOrgIds.includes(rootOrg.getId())) {
                isDisabled = true;
            }
            rootOrg = fetchRootItemFromChildrenItem(allOrgItems, rootOrg);
        }
        return isDisabled;
    }
    return false;
};

const fetchChildItemsFromRootItem = (allOrgItems, rootItem) => {
    return allOrgItems
        ?.filter(item => item.getParentId() === rootItem.getId())
        .sort((a, b) => {
            return a.getName().localeCompare(b.getName());
        });
};

const fetchSameLevelOrgItems = (allOrgItems, org) => {
    if (org.getParentId() === null) {
        return fetchRootItems(allOrgItems)
    }
    return allOrgItems?.filter(item => item.getParentId() === org.getParentId())
};

const addUniqueParentItemsToChildrenItems = (allOrgItems, childrenOrgs) => {
    const parentOrgs = [...childrenOrgs];
    for (const childrenOrg of childrenOrgs) {
        const _parentOrgs = fetchRootItemsFromChildrenItem(allOrgItems, childrenOrg);
        _parentOrgs.forEach(parentOrg => {
            if (!parentOrgs.find(p => p.getId() === parentOrg.getId())) {
                parentOrgs.push(parentOrg);
            }
        });
    }
    return parentOrgs;
};

export default OrgListView;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingBottom: 80,
    },
    activeListItemButton: {
        backgroundColor: theme.palette.primary.main,
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    activeListItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        cursor: 'pointer',
    },
    fab: {
        marginRight: 5,
    },
    appBar: {},
    search: {
        flex: 1,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
    },
}));
