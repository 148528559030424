import React from 'react';
import { Typography, Link, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { paramValueToString } from './mapItemParams';

import LinkIcon from '@material-ui/icons/Link';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OrgIcon from '@material-ui/icons/Work';

const ListItem = ({ item, expanded, onExpandClick, onGotoDetailReport, level = 0 }) => {
  const classes = useStyles();
  const paramKeys = Object.keys(item.params);

  const Title =
    !onGotoDetailReport || item.org === item.orgId || onExpandClick ? (
      <Typography variant={'body2'}>{item.org}</Typography>
    ) : (
      <Link href="#" onClick={() => onGotoDetailReport(item.orgId)} color="inherit">
        <LinkIcon className={classes.linkIcon} />
        {item.org}
      </Link>
    );

  const timeRange = `${moment(item.tsMin).format('YYYY-MM-DD HH:mm:ss')} / ${moment(item.tsMax).format('YYYY-MM-DD HH:mm:ss')}`;

  return (
    <>
      <TableRow className={onExpandClick && classes.listItemContainer} onClick={onExpandClick}>
        <TableCell style={{ paddingLeft: (level + 1) * 15 }}>
          <div className={classes.titleCol}>
            {onExpandClick && <OrgIcon className={classes.orgIcon} />}
            {Title}
          </div>
        </TableCell>
        <TableCell>{item.count}</TableCell>
        <TableCell>{timeRange}</TableCell>
        <TableCell>{item.events.join(', ')}</TableCell>
        <TableCell>
          {Object.keys(item.params).map((paramKey, ii) => (
            <Typography key={`${item.orgId}-${ii}`} variant={'body2'}>
              {paramKeys.length === 1 ? paramValueToString(item.params[paramKey]) : `${paramKey}: ${paramValueToString(item.params[paramKey])}`}
            </Typography>
          ))}
        </TableCell>
        <TableCell>{onExpandClick ? <>{expanded ? <ExpandLess /> : <ExpandMore />}</> : <span className={classes.arrowIcon}></span>}</TableCell>
      </TableRow>
    </>
  );
};

export default ListItem;

const useStyles = makeStyles(theme => ({
  listItemContainer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  titleCol: {
    display: 'flex',
    flexDirection: 'row',
  },
  orgIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    marginTop: 2,
  },
  linkIcon: {
    marginBottom: -7,
    width: 16,
    marginRight: 10,
  },
  arrowIcon: {
    width: 24,
    height: 24,
    display: 'inline-block',
  },
}));
